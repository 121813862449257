import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import SearchInput from '../../../../../shared/components/SearchInput';
import { ReactComponent as Trash } from '../../../../../shared/assets/bin.svg';
import { ReactComponent as CheckIcon } from '../../../../../shared/assets/checkSquare.svg';
import { useIsMobile, useModal } from '../../../../../shared/helpers/hooks';
import { SMALL_MOBILE_WIDTH } from '../../../helpers/constants';
import DeleteProfileModal from '../DeleteProfileModal';

const BusinessCardSettingsActionsWrapper = ({
  businessProfiles,
  readOnlyBtn,
  onDetachSelected,
  selectAllowed,
  selectAllowedHandler,
  searchHandler,
  selectedBusinessProfiles,
  filters
}) => {
  const { t } = useTranslation();
  const isSmallMobile = useIsMobile(SMALL_MOBILE_WIDTH);
  const [showModal, setShowModal, modalHandler] = useModal();
  const selectIcon = !isSmallMobile && CheckIcon;
  const trashIcon = !isSmallMobile && Trash;
  const deleteLabel = !isSmallMobile ? t('settings.deleteSelected') : t('settings.delete');

  if (!businessProfiles.length) return null;

  return (
    <div className={classes.actionsWrapper}>
      {showModal && (
        <DeleteProfileModal
          onCancel={modalHandler}
          onConfirm={onDetachSelected}
          selectedBusinessProfiles={selectedBusinessProfiles}
        />
      )}
      <div className={classes.filters}>
        <SearchInput
          onSearch={searchHandler}
          showList={false}
          className={classes.searchInput}
          label={t('settings.typeBusinessName')}
          list={businessProfiles}
          value={filters.name}
        />
      </div>
      {!selectAllowed ? (
        <Button
          Icon={selectIcon}
          onClick={selectAllowedHandler}
          className={classes.deleteCardBtn}
          label={t('settings.selectFew')}
        />
      ) : (
        <Button
          iconStyle={classes.trashIconStyle}
          Icon={trashIcon}
          label={deleteLabel}
          onClick={modalHandler}
          className={classes.deleteCardBtn}
          readOnly={readOnlyBtn}
        />
      )}
    </div>
  );
};
export default BusinessCardSettingsActionsWrapper;
