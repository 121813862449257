import i18next from 'i18next';
import { ReactComponent as ContentIcon } from '../../../../shared/assets/contentManagement.svg';
import { ReactComponent as UsersIcon } from '../../../../shared/assets/usersGroup.svg';
import { ReactComponent as SubscriptionsIcon } from '../../../../shared/assets/users.svg';
import { ReactComponent as PaymentsIcon } from '../../../../shared/assets/payments.svg';
import { ReactComponent as CardsIcon } from '../../../../shared/assets/cards.svg';
import { ReactComponent as Statistics } from '../../../../shared/assets/statistic.svg';
import { ReactComponent as AdministratorsIcon } from '../../../../shared/assets/administrators.svg';
import { ReactComponent as CouponCodeIcon } from '../../../../shared/assets/couponCode.svg';
import { ROLE_ENUM } from '../../views/AdministratorDetails/utils';

export const getTranslatedSections = (t, role) => {
  // TODO
  const sections = [
    // {
    //   title: t('adminControlPanel.statistics'),
    //   path: '/admin/',
    //   Icon: Statistics,
    // },
    ...(role === ROLE_ENUM.superAdmin
      ? [
        {
          title: t('adminControlPanel.administrators'),
          path: '/admin/administrators',
          Icon: AdministratorsIcon,
        },
      ]
      : []),
    {
      title: t('adminControlPanel.users'),
      path: '/admin/users',
      Icon: UsersIcon,
    },
    {
      title: t('adminControlPanel.opinieo'),
      path: '/admin/cards',
      Icon: CardsIcon,
    },
    // {
    //   title: t('adminControlPanel.payments'),
    //   path: '/admin/payments',
    //   Icon: PaymentsIcon,
    // },
    // {
    //   title: t('adminControlPanel.subscriptions'),
    //   path: '/admin/payments',
    //   Icon: SubscriptionsIcon,
    // },
    // {
    //   title: t('adminControlPanel.couponCodes'),
    //   path: '/admin/coupon-codes',
    //   Icon: CouponCodeIcon,
    // },
    // {
    //   title: t('adminControlPanel.contentManagement'),
    //   path: '/admin/content_management',
    //   Icon: ContentIcon,
    // },
  ];

  return sections;
};

export const isCurrentPath = (sectionPath, locationPath) => {
  if (sectionPath === '/admin/' && locationPath === '/admin/') {
    return true;
  }
  return sectionPath !== '/admin/' && locationPath.startsWith(sectionPath);
};
