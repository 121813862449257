import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../shared/helpers/auth';
import { useApi } from '../../../../shared/helpers/api';
import { useNotification } from '../../../../shared/helpers/notification';
import classes from './styles.module.css';
import ProfileList from '../../../../shared/components/ProfileList';
import withLoading from '../../../../shared/components/WithLoading';
import { handleApiError } from '../../modules/Settings/ChangePassword/utils';
import { getUniqueArray } from '../../modules/Media/AddMediaModal/utils';
import { handleProfileImages } from '../../views/Login/utils';
import { OFFSET, mergeProfilesWithImages } from './utils';
import { ReactComponent as LogoIcon } from '../../../../shared/assets/logoIcon.svg';
import { ReactComponent as PlusIcon } from '../../../../shared/assets/plus.svg';
import { useDashboard } from '../../helpers/dashboard';
import BetaSign from '../../../../shared/components/BetaSign';

const ProfileListWithLoading = withLoading({ Component: ProfileList });

const BusinessProfileSidebar = () => {
  const navigate = useNavigate();
  const { api } = useApi();
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const { selectBusinessProfile, businessProfile } = useDashboard();
  const { setAuth, businessProfiles, ...authArgs } = useAuth();

  const [offset, setOffset] = useState(OFFSET);
  const [displayList, setDisplayList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const remainingProfiles = businessProfiles.length - displayList.length;
  const shouldRenderAddButton = Boolean(remainingProfiles && remainingProfiles > 0);
  const handleBusinessProfile = (profileId) => {
    selectBusinessProfile(profileId);
    localStorage.setItem('selectedProfile', profileId);
  };

  const handleAddBusinessProfile = () => {
    navigate('/dashboard/createBusinessProfile');
  };

  const navigateToDashboardHandler = () => {
    navigate('/dashboard');
  };

  const changeOffsetHandler = () => {
    setOffset((prevOffset) => prevOffset + OFFSET);
  };

  useEffect(() => {
    const controller = new AbortController();
    const fetchMissingImages = async () => {
      try {
        setIsLoading(true);
        const newDataSet = businessProfiles.slice(0, offset);
        if (newDataSet.every((profile) => profile.image)) {
          setDisplayList(newDataSet);
          return;
        }
        const newDataSetWithImages = await handleProfileImages(newDataSet, api, controller);
        const businessProfilesWithImages = mergeProfilesWithImages(businessProfiles, newDataSetWithImages);
        setAuth({ ...authArgs, businessProfiles: businessProfilesWithImages });
        setDisplayList(newDataSetWithImages);
      } catch (err) {
        handleApiError({ err, t, showNotification });
      } finally {
        setIsLoading(false);
      }
    };
    fetchMissingImages();

    return () => controller.abort();
  }, [offset, businessProfiles?.length]);

  useEffect(() => {
    const selectedProfileNotFound = !displayList.some((profile) => profile.id === businessProfile.id);
    if (selectedProfileNotFound && displayList.length) {
      const shouldFetchProfileImage = !businessProfile.image && businessProfile.fileName;
      if (shouldFetchProfileImage) {
        handleProfileImages([businessProfile], api, new AbortController()).then((res) => {
          setDisplayList((prev) => getUniqueArray(prev, res));
        });
      } else {
        setDisplayList((prev) => [...prev, businessProfile]);
      }
    }
  }, [businessProfile]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.logoWrapper}>
        <LogoIcon onClick={navigateToDashboardHandler} className={classes.businessProfileIcon} />
        <BetaSign className={classes.logoSign} />
      </div>
      <div onClick={handleAddBusinessProfile} className={classes.add}>
        <PlusIcon className={classes.plusIcon} />
      </div>
      <div className={classes.list}>
        <ProfileListWithLoading
          isLoading={isLoading}
          handleBusinessProfile={handleBusinessProfile}
          profileListToDisplay={displayList}
        />
        {shouldRenderAddButton && (
          <div onClick={changeOffsetHandler} className={classes.add}>
            {`+${remainingProfiles}`}
          </div>
        )}
      </div>
    </div>
  );
};

export default BusinessProfileSidebar;
