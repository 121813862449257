import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';

const BusinessBasicDataSettings = ({ title, address }) => {
  const { t } = useTranslation();
  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>{title}</div>
      <div className={classes.address}>{address}</div>
    </div>
  );
};

export default BusinessBasicDataSettings;
