import {
  postalCodeTransformer,
  stringCharactersTransformer,
  capitalizeFirstLetter,
} from '../../../../../shared/components/Input/transformers';
import {
  makeRequired,
  postalCodeValidator,
  streetAndNumberValidator,
  validateCityName,
} from '../../../../../shared/components/Input/validators';
import { debounce } from '../../../../../shared/helpers/debounce';
import { handleApiError } from '../../Settings/ChangePassword/utils';

export const getBusinessLocationInputs = (t) => [
  {
    label: t('businessProfileEdit.streetAndNumber'),
    name: 'addressLines',
    validator: makeRequired(streetAndNumberValidator),
    showErrorAfterBlur: true,
    transformer: capitalizeFirstLetter,
  },
  {
    label: t('businessProfileEdit.postalCode'),
    name: 'postalCode',
    validator: makeRequired(postalCodeValidator),
    showErrorAfterBlur: true,
    transformer: postalCodeTransformer,
  },
  {
    label: t('businessProfileEdit.city'),
    name: 'locality',
    validator: makeRequired(validateCityName),
    showErrorAfterBlur: true,
    transformer: stringCharactersTransformer,
  },
];

export const getLatLong = debounce(async (location, setIsLoading) => {
  const { postalCode, addressLines, locality } = location;
  const isVillage = addressLines.some((line) => line.includes(locality));
  const payloadAddress = `${addressLines.join(' ')} ${postalCode} ${isVillage ? '' : locality}`;
  setIsLoading(true);
  try {
    const locationData = await fetch(
      `https://nominatim.openstreetmap.org/search?format=json&q=${encodeURIComponent(payloadAddress)}`,
    )
      .then((response) => response.json())
      .then((data) => data)
      .catch((err) => handleApiError({ err }));

    return locationData;
  } catch (error) {
    handleApiError({ error });
    return null;
  } finally {
    setIsLoading(false);
  }
}, 500);
