import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import Modal from '../../../../../shared/components/Modal';
import classes from './styles.module.css';
import PlansContent from '../PlansContent';
import { getNavigationText, getPreviousStep, purchaseStepConfig } from './utils';
import { SMALL_MOBILE_WIDTH } from '../../../helpers/constants';
import { useIsMobile } from '../../../../../shared/helpers/hooks';
import PurchaseSummary from '../PurchaseSummary';

const PurchaseSubscriptionForm = ({ onClose }) => {
  const { t } = useTranslation();
  const isSmallMobile = useIsMobile(SMALL_MOBILE_WIDTH);

  const { selectPlan, summary } = purchaseStepConfig;
  const [selectedOffer, setSelectedOffer] = useState(null);
  const [purchaseFormStep, setPurchaseFormStep] = useState(selectPlan);

  const navigationText = getNavigationText(isSmallMobile, purchaseFormStep, t);
  const purchaseFormSteps = Object.values(purchaseStepConfig);
  const currentStepNumber = purchaseFormSteps.indexOf(purchaseFormStep) + 1;

  const modalTitle = purchaseFormStep === summary ? t('subscription.purchaseSummary') : t('subscription.buySubscription');
  const stepValue = t('settings.step', { currentStepNumber, totalSteps: purchaseFormSteps.length });

  const handleOfferSelect = (offer) => {
    setSelectedOffer(offer);
  };

  const handleStepChange = (value) => () => {
    if (!value) {
      onClose();
      return;
    }
    setPurchaseFormStep(value);
  };

  return (
    <Modal
      onCancel={onClose}
      modalTitle={modalTitle}
      contentStyle={clsx(classes.modalWrapper, purchaseFormStep === selectPlan && classes.modalWrapperSelectBP)}
      showArrowBack={purchaseFormStep !== selectPlan && !isSmallMobile}
      rwdBackTitle={navigationText}
      onBack={handleStepChange(getPreviousStep(purchaseFormStep, selectedOffer))}
      backdropLocked
    >
      <div className={classes.body}>
        <div className={classes.stepper}>{stepValue}</div>
        {purchaseFormStep === selectPlan && (
          <PlansContent
            selectedOffer={selectedOffer}
            onOfferSelect={handleOfferSelect}
            summaryClickHandler={() => setPurchaseFormStep(summary)}
          />
        )}

        {purchaseFormStep === summary && <PurchaseSummary selectedOffer={selectedOffer} onModalClose={onClose} />}
      </div>
    </Modal>
  );
};

export default PurchaseSubscriptionForm;
