import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import PurchaseFormBusinessProfileDetails from '../PurchaseFormBusinessProfileDetials';
import classes from './styles.module.css';
import Checkbox from '../../../../../shared/components/Checkbox';
import Button from '../../../../../shared/components/Button';
import BuyerData from '../BuyerData';
import PruchaseSummaryPaymentDetails from '../PurchaseSummaryPaymentDetails';
import { getOfferDetails, termsOfServiceLabel } from './utils';
import { subscriptionsConfig } from '../../../helpers/constants';
import { useNotification } from '../../../../../shared/helpers/notification';
import { useApi } from '../../../../../shared/helpers/api';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import Loader from '../../../components/Loader';
import { useDashboard } from '../../../helpers/dashboard';
import { paymentMethodEnum } from '../PaymentMethodItem/utils';

const PurchaseSummary = ({ selectedOffer, onModalClose }) => {
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const { api } = useApi();
  const {
    businessProfile: { id },
  } = useDashboard();

  const [buttonReadOnly, setButtonReadOnly] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentDetails, setPaymentDetails] = useState({ type: null });
  const [consentsChecked, setConsentChecked] = useState({
    termsOfService: false,
    consentToStartServiceBefore14Days: false,
  });

  const isTrial = selectedOffer.title.toLowerCase() === subscriptionsConfig.trial;
  const isCyclicMethodPicked = paymentDetails?.type === paymentMethodEnum.recurring;

  const btnLabel = t(`settings.${isTrial ? 'activateTrial' : 'goToPayment'}`);
  const isConsentsChecked = Object.values(consentsChecked).every((value) => value);

  const handleCheckboxClick = (name) => (value) => {
    setConsentChecked((prev) => ({ ...prev, [name]: value }));
  };
  const fetchTrialStatus = async () => {
    setIsLoading(true);
    try {
      const {
        data: { trialUsed },
      } = await api.post('/subscription/trialStatus', {});
      const userTrialAvailable = !trialUsed;
      return userTrialAvailable;
    } catch (err) {
      handleApiError({ err, showNotification, t });
      return false;
    } finally {
      setIsLoading(false);
    }
  };

  const submitPurchaseRequestHandler = async () => {
    if (!isConsentsChecked) {
      showNotification({ message: t('subscription.pleaseAcceptConsents'), type: 'error' });
      return;
    }
    setIsLoading(true);
    try {
      if (isTrial) {
        const trialAvailable = await fetchTrialStatus();
        if (!trialAvailable) {
          showNotification({ message: t('subscription.trialNotAvailable'), type: 'error' });
          setIsLoading(false);
          return;
        }
        await api.post('/subscription/startTrial', { businessProfileId: id });
        showNotification({ message: t('subscription.trialActivated'), type: 'success' });
      } else {
        const payload = {
          businessProfileId: id,
          subscription: selectedOffer.title.toLowerCase(),
          payment: selectedOffer.type,
          invoiceDetailsId: paymentDetails.invoiceId,
          consentPrivacyPolicy: consentsChecked.termsOfService,
          consentToProvideService: consentsChecked.consentToStartServiceBefore14Days,
        };

        await api.post('/subscription/buySubscription', payload);
        showNotification({ message: t('subscription.subscriptionBought'), type: 'success' });
      }
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
      onModalClose();
    }
  };

  const handleInvoiceChang = (invoiceId) => {
    setPaymentDetails((prev) => ({ ...prev, invoiceId }));
  };

  useEffect(() => {
    setButtonReadOnly(!isConsentsChecked || (isTrial ? false : !paymentDetails.invoiceId));
  }, [isConsentsChecked, isTrial, paymentDetails]);

  useEffect(() => {
    setPaymentDetails(getOfferDetails({ isTrial, selectedOffer }));
  }, [selectedOffer]);

  return (
    <div className={classes.wrapper}>
      {isLoading && (
        <div className={classes.loaderWrapper}>
          <Loader />
        </div>
      )}
      <PurchaseFormBusinessProfileDetails />
      <PruchaseSummaryPaymentDetails selectedPlan={selectedOffer} paymentDetails={paymentDetails} />
      {!isTrial && (
        <div className={classes.buyerDataWrapper}>
          <BuyerData onChange={handleInvoiceChang} />
        </div>
      )}
      <div className={classes.priceWrapper}>
        <div className={classes.price}>{t('subscription.priceToPay')}</div>
        <div className={classes.price}>
          {`${paymentDetails.price} PLN`}
          {!isTrial && (
            <>
              <span className={classes.pricePeriod}>{t(paymentDetails.priceTagShort)}</span>
              <div className={classes.taxIncluded}>{t('settings.taxIncluded')}</div>
            </>
          )}
        </div>
      </div>
      <div className={classes.consentWrapper}>
        {isCyclicMethodPicked && <p className={classes.cyclicSubInfo}>{t('subscription.cyclicPaymentSubinfo')}</p>}
        <Checkbox
          checkboxStyle={clsx(classes.checkboxStyle)}
          checkIconStyle={classes.checkIconStyle}
          className={clsx(classes.checkbox)}
          label={termsOfServiceLabel(t)}
          labelPosition="right"
          onClick={handleCheckboxClick('termsOfService')}
          value={consentsChecked.termsOfService}
        />
        <Checkbox
          checkboxStyle={clsx(classes.checkboxStyle)}
          checkIconStyle={classes.checkIconStyle}
          className={clsx(classes.checkbox)}
          label={t('settings.consentToStartServiceBefore14Days')}
          labelPosition="right"
          onClick={handleCheckboxClick('consentToStartServiceBefore14Days')}
          value={consentsChecked.consentToStartServiceBefore14Days}
        />
      </div>
      <div className={classes.actionsWrapper}>
        <Button
          readOnly={buttonReadOnly}
          label={btnLabel}
          className={classes.btn}
          onClick={submitPurchaseRequestHandler}
        />
      </div>
    </div>
  );
};

export default PurchaseSummary;
