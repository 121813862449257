import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import { translatedTabs } from '../../../views/Settings/utils';
import { useAuth } from '../../../../../shared/helpers/auth';

const SettingsHeader = ({ onTabSelect, activeTab }) => {
  const { t } = useTranslation();
  const { businessProfiles } = useAuth();

  const tabs = useMemo(() => translatedTabs(t, businessProfiles), [businessProfiles]);

  useEffect(() => {
    localStorage.setItem('activeTab', activeTab);
  }, [activeTab]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>{t('settings.title')}</div>
      <div className={classes.tabs}>
        {tabs.map(({ label, path }) => (
          <Button
            key={label}
            label={label}
            className={clsx(classes.tabBtn, { [classes.activeTabStyle]: activeTab === path })}
            onClick={() => onTabSelect(path)}
          />
        ))}
      </div>
    </div>
  );
};
export default SettingsHeader;
