import React, { useEffect } from 'react';
import clsx from 'clsx';
import classes from './styles.module.css';
import { ReactComponent as Arrow } from '../../assets/arrowAlternative.svg';
import Pill from '../Pill';
import VideoPlayer from '../VideoPlayer';
import { MEDIA_ENUM } from '../../../app/SEO/modules/Media/AddMediaModal/utils';
import ProgressiveImage from '../ProgressiveImage';

const Gallery = ({ media, onGalleryClose, currentImage, onNext, onPrev }) => {
  const {
    isImage,
    googleUrl,
    pillLabel,
    sourceUrl,
    thumbnailUrl,
    locationAssociation: { category },
  } = media[currentImage];
  const isPlanned = media[currentImage].publishDate;
  const { PROFILE } = MEDIA_ENUM;
  const imageSource = isPlanned ? sourceUrl : googleUrl;
  const crossOrigin = category !== PROFILE ? undefined : 'anonymous';

  useEffect(() => {
    const keyActions = {
      ArrowRight: onNext,
      ArrowLeft: onPrev,
      Escape: onGalleryClose,
    };

    const handleKeyDown = (event) => {
      const action = keyActions[event.key];
      if (action) {
        action(event);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onNext, onPrev, onGalleryClose]);

  return (
    <div onClick={onGalleryClose} className={classes.container}>
      <Arrow onClick={onPrev} className={clsx(classes.arrow, classes.arrowBack)} />
      <div className={classes.item}>
        {isImage ? (
          <ProgressiveImage lowQualitySrc={thumbnailUrl} highQualitySrc={sourceUrl} crossOrigin={crossOrigin} />
        ) : (
          <VideoPlayer crossAnonymous={isPlanned} videoUrl={sourceUrl} />
        )}
        <div className={classes.pillWrapper}>
          <Pill label={pillLabel} className={classes.galleryPill} />
        </div>
      </div>
      <Arrow onClick={onNext} className={clsx(classes.arrow, classes.arrowNext)} />
    </div>
  );
};

export default Gallery;
