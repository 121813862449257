import { phoneNumberTransformer, urlTransformer } from '../../../../../../shared/components/Input/transformers';
import { makeRequired, phoneNumberValidator, urlValidator } from '../../../../../../shared/components/Input/validators';

const requiredUrl = makeRequired(urlValidator);
const requiredPhoneNumber = makeRequired(phoneNumberValidator);
export const buttonActionsEnum = {
  ACTION_TYPE_UNSPECIFIED: 'ACTION_TYPE_UNSPECIFIED',
  BOOK: 'BOOK',
  ORDER: 'ORDER',
  SHOP: 'SHOP',
  LEARN_MORE: 'LEARN_MORE',
  SIGN_UP: 'SIGN_UP',
  CALL: 'CALL',
};

export const buttonActions = [
  {
    id: 0,
    name: 'posts.noButton',
    googleID: buttonActionsEnum.ACTION_TYPE_UNSPECIFIED,
    detailLabel: '',
    placeholder: '',
  },
  {
    id: 1,
    name: 'posts.makeAppointment',
    googleID: buttonActionsEnum.BOOK,
    detailLabel: 'posts.makeAppointmentLink',
    validator: requiredUrl,
    transformer: urlTransformer,
    subInformation: 'posts.makeAppointmentMoreInfo',
    type: 'url',
  },
  {
    id: 2,
    name: 'posts.order',
    googleID: buttonActionsEnum.ORDER,
    detailLabel: 'posts.addOrderLink',
    validator: requiredUrl,
    transformer: urlTransformer,
    subInformation: 'posts.orderMoreInfo',
    type: 'url',
  },
  {
    id: 3,
    name: 'posts.checkOffer',
    googleID: buttonActionsEnum.SHOP,
    detailLabel: 'posts.addOfferLink',
    validator: requiredUrl,
    transformer: urlTransformer,
    subInformation: 'posts.checkOfferMoreInfo',
    type: 'url',
  },
  {
    id: 4,
    name: 'posts.learnMore',
    googleID: buttonActionsEnum.LEARN_MORE,
    detailLabel: 'posts.addLink',
    validator: requiredUrl,
    transformer: urlTransformer,
    subInformation: 'posts.learnMoreMoreInfo',
    type: 'url',
  },
  {
    id: 5,
    name: 'posts.signUp',
    googleID: buttonActionsEnum.SIGN_UP,
    detailLabel: 'posts.addSignUpLink',
    validator: requiredUrl,
    transformer: urlTransformer,
    subInformation: 'posts.signUpMoreInfo',
    type: 'url',
  },
  {
    id: 6,
    name: 'posts.call',
    googleID: buttonActionsEnum.CALL,
    detailLabel: 'posts.addPhoneNumber',
    validator: requiredPhoneNumber,
    transformer: phoneNumberTransformer,
    subInformation: 'posts.phoneNumberMoreInfo',
    type: 'tel',
  },
];

export const modalFormFieldsEnum = {
  STANDARD: 'STANDARD',
  EVENT: 'EVENT',
  OFFER: 'OFFER',
  CALL_TO_ACTION: 'callToAction',
  URL: 'url',
  TITLE: 'title',
  START_DATE: 'startDate',
  END_DATE: 'endDate',
  SUMMARY: 'summary',
  TERMS_CONDITIONS: 'termsConditions',
  COUPON_CODE: 'couponCode',
  REDEEM_ONLINE_URL: 'redeemOnlineUrl',
  START_TIME: 'startTime',
  END_TIME: 'endTime',
  MEDIA: 'media',
};

export const updateLocalPost = (updateFn, setter) => {
  setter((prevState) => ({
    ...prevState,
    localPost: updateFn(prevState.localPost),
  }));
};
export const handleCallToAction = (value, setter) => {
  if (!value) {
    updateLocalPost(
      (localPost) => ({
        ...localPost,
        callToAction: { actionType: null, url: null },
      }),
      setter,
    );
    return;
  }
  if (value === buttonActionsEnum.CALL) {
    updateLocalPost(
      (localPost) => ({
        ...localPost,
        callToAction: { actionType: value },
      }),
      setter,
    );
    return;
  }
  updateLocalPost(
    (localPost) => ({
      ...localPost,
      callToAction:
        value === buttonActionsEnum.ACTION_TYPE_UNSPECIFIED
          ? undefined
          : {
            ...localPost.callToAction,
            actionType: value,
          },
    }),
    setter,
  );
};
export const handleUrl = (value, setter) => {
  updateLocalPost(
    (localPost) => ({
      ...localPost,
      callToAction: {
        ...localPost.callToAction,
        url: localPost.callToAction.actionType ? value.trim() : null,
      },
    }),
    setter,
  );
};
export const handleTitle = (value, setter) => {
  updateLocalPost(
    (localPost) => ({
      ...localPost,
      event: {
        ...localPost?.event,
        title: value.trim(),
      },
    }),
    setter,
  );
};
export const handleDate = (value, name, setter) => {
  const [year, month, day] = value.split('-').map((el) => Number(el));
  updateLocalPost(
    (localPost) => ({
      ...localPost,
      event: {
        ...localPost.event,
        schedule: {
          ...localPost.event.schedule,
          [name]: { year, month, day },
        },
      },
    }),
    setter,
  );
};

export const updateSchedule = (schedule) => {
  const { START_DATE, END_DATE } = modalFormFieldsEnum;

  const filteredSchedule = Object.fromEntries(
    Object.entries(schedule).filter(([key]) => ![START_DATE, END_DATE].includes(key)),
  );

  return filteredSchedule;
};
export const parseTime = (value) => {
  const [hours, minutes] = value.split(':').map(Number);
  return { hours, minutes };
};
export const handleTime = (value, name, setter) => {
  updateLocalPost(
    (localPost) => ({
      ...localPost,
      event: {
        ...localPost.event,
        schedule: {
          ...localPost.event.schedule,
          [name]: parseTime(value),
        },
      },
    }),
    setter,
  );
};
export const handleDefault = (name, value, setter) => {
  updateLocalPost(
    (localPost) => ({
      ...localPost,
      [name]: value.trim(),
    }),
    setter,
  );
};
export const handleOneDayOffer = (name, value, setter) => {
  if (value) {
    updateLocalPost(
      (localPost) => ({
        ...localPost,
        event: {
          ...localPost.event,
          schedule: {
            ...localPost.event.schedule,
            endDate: localPost.event.schedule.endDate,
            endTime: { hours: 23, minutes: 59 },
            startDate: localPost.event.schedule.startDate,
            startTime: { hours: 0, minutes: 0 },
          },
        },
      }),
      setter,
    );
  } else {
    updateLocalPost(
      (localPost) => ({
        ...localPost,
        event: {
          ...localPost.event,
          schedule: {
            endDate: localPost.event.schedule.endDate,
            startDate: localPost.event.schedule.startDate,
          },
        },
      }),
      setter,
    );
  }
};
