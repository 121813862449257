import { getUniqueArray } from '../../../app/SEO/modules/Media/AddMediaModal/utils';

const imageExtensions = ['png', 'jpg', 'jpeg'];
const videoExtension = ['mp4'];

export const getFileExtension = (fileName) => {
  const fileExtension = fileName.split('.').pop().toLowerCase();
  return fileExtension;
};

const maxImageSize = 5 * 1024 * 1024;
const maxImageHightOrWidth = 3000;
const compressQualityFactor = 1;
const compressImage = (file, maxWidth, maxHeight, outputFormat = 'image/jpeg') => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);

  reader.onload = (event) => {
    const img = new Image();
    img.src = event.target.result;

    img.onload = () => {
      const elem = document.createElement('canvas');
      let { width, height } = img;

      if (width > height) {
        if (width > maxWidth) {
          height *= maxWidth / width;
          width = maxWidth;
        }
      } else if (height > maxHeight) {
        width *= maxHeight / height;
        height = maxHeight;
      }

      elem.width = width;
      elem.height = height;
      const ctx = elem.getContext('2d');
      ctx.drawImage(img, 0, 0, width, height);

      const mimeType = outputFormat;
      ctx.canvas.toBlob(
        (blob) => {
          if (!blob) {
            reject(new Error('Blob creation failed'));
            return;
          }

          const compressedFile = new File([blob], file.name, {
            type: mimeType,
            lastModified: Date.now(),
          });
          resolve(compressedFile);
        },
        mimeType,
        outputFormat === 'image/jpeg' ? compressQualityFactor : undefined,
      );
    };

    img.onerror = () => reject(new Error('Image loading failed'));
  };

  reader.onerror = () => reject(new Error('File reading failed'));
});

const isValidFileExtension = (fileExtension) => imageExtensions.includes(fileExtension);
const isValidImageFileSize = (fileSize) => fileSize < maxImageSize;
const isValidVideoFileSize = (fileSize) => fileSize < 75 * 1024 * 1024;

const validateImageResolution = async (file) => {
  const image = new Image();
  image.src = file;
  const { width, height } = await new Promise((resolve) => {
    image.onload = () => resolve({ width: image.naturalWidth, height: image.naturalHeight });
  });
  return width <= maxImageHightOrWidth && height <= maxImageHightOrWidth && width >= 250 && height >= 250;
};

const getVideoDuration = (file) => new Promise((resolve, reject) => {
  const video = document.createElement('video');
  video.onloadedmetadata = () => resolve(video.duration);
  video.onerror = reject;
  video.src = URL.createObjectURL(file);
});

export const parseFileToBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onloadend = () => resolve(reader.result);
  reader.onerror = reject;
  reader.readAsDataURL(file);
});

export const validateVideo = async (data, t) => {
  const parsedFiles = await Promise.all(
    data.map(async (el) => {
      const base64 = await parseFileToBase64(el);
      const duration = await getVideoDuration(el);
      return {
        file: el,
        mediaSource: base64,
        id: Math.random(),
        filename: el.name,
        extension: getFileExtension(el.name),
        duration,
      };
    }),
  );
  const filteredFiles = await Promise.allSettled(
    parsedFiles.map(async (video) => {
      const { name, size } = video.file;
      const fileExtension = getFileExtension(name);
      const isValidExtension = videoExtension.includes(fileExtension);
      const isValidSize = isValidVideoFileSize(size);
      if (!isValidExtension) {
        throw new Error('posts.fileExtensionError');
      }
      if (!isValidSize) {
        throw new Error('posts.fileSizeError');
      }
      return video;
    }),
  );

  const invalidFiles = filteredFiles
    .filter((el) => el.status === 'rejected')
    .map((el) => String(el.reason).replace('Error: ', ''));
  const translatedInvalidFiles = invalidFiles.map((el) => t(el));
  const uniqueInvalidFiles = getUniqueArray([], translatedInvalidFiles);
  const validFiles = filteredFiles.filter((el) => el.status === 'fulfilled').map((el) => el.value);
  const files = { invalidFiles: uniqueInvalidFiles, validFiles };
  return files;
};

export const validateImages = async (data, t) => {
  const parsedFiles = await Promise.all(
    data.map(async (el) => {
      const base64 = await parseFileToBase64(el);
      return {
        file: el,
        mediaSource: base64,
        id: Math.random(),
        filename: el.name,
        extension: getFileExtension(el.name),
      };
    }),
  );
  const filteredFiles = await Promise.allSettled(
    parsedFiles.map(async (image) => {
      const {
        filename,
        file: { size },
      } = image;
      const fileExtension = getFileExtension(filename);
      const isValidExtension = isValidFileExtension(fileExtension);
      const isValidSize = isValidImageFileSize(size);
      const isValidResolution = await validateImageResolution(image.mediaSource);
      if (!isValidExtension) {
        throw new Error('posts.fileExtensionError');
      }
      if (!isValidSize || !isValidResolution) {
        const compressedImage = await compressImage(image.file, maxImageHightOrWidth, maxImageHightOrWidth);
        return {
          file: compressedImage,
          mediaSource: await parseFileToBase64(compressedImage),
          id: Math.random(),
          filename: compressedImage.name,
          extension: getFileExtension(compressedImage.name),
        };
      }

      return image;
    }),
  );

  const invalidFiles = filteredFiles.filter((el) => el.status === 'rejected').map((el) => String(el.reason).replace('Error: ', '')) || [];
  const translatedInvalidFiles = invalidFiles.map((el) => t(el));
  const uniqueInvalidFiles = getUniqueArray([], translatedInvalidFiles);

  const validFiles = filteredFiles.filter((el) => el.status === 'fulfilled').map((el) => el.value);
  const files = { invalidFiles: uniqueInvalidFiles, validFiles };
  return files;
};
export const validateFiles = async (data, t, withVideo) => {
  const files = { invalidFiles: [], validFiles: [] };
  const imageData = data.filter((el) => el.type.includes('image'));
  const imageDataValidated = await validateImages(imageData, t);
  files.invalidFiles = [...imageDataValidated.invalidFiles];
  files.validFiles = [...imageDataValidated.validFiles];

  if (withVideo) {
    const videoData = data.filter((el) => el.type.includes('video'));
    const videoDataValidated = await validateVideo(videoData, t);
    files.invalidFiles = [...files.invalidFiles, ...videoDataValidated.invalidFiles];
    files.validFiles = [...files.validFiles, ...videoDataValidated.validFiles];
  }

  return files;
};

export const moreInfoHandler = () => {
  window.open(
    'https://support.google.com/business/answer/6103862?hl=pl&visit_id=638252548321457836-3724407861&p=photo_guidelines&rd=1#photo-video-guidelines',
    '_blank',
  );
};

export const getUniqueFiles = (filesToUpload, fielsUploaded) => {
  const uniqueFiles = filesToUpload.filter(
    (file) => !fielsUploaded.some(
      (uploadedFile) => uploadedFile.file.filename === file.filename && uploadedFile.file.size === file.size,
    ),
  );
  return uniqueFiles;
};
