import React from 'react';
import i18next from 'i18next';
import classes from './styles.module.css';
import Input from '../../../../../shared/components/Input';
import {
  companyNipTransformer,
  capitalizeWordsAndPreserveDashesTransformer,
  nameTransformer,
  phoneNumberTransformer,
  postalCodeTransformer,
  capitalizeFirstLetter,
} from '../../../../../shared/components/Input/transformers';
import {
  alphabeticalValidator,
  companyNipValidator,
  makeRequired,
  phoneNumberValidator,
  postalCodeValidator,
  required as requiredValidator,
  streetAndNumberValidator,
  validateCityName,
} from '../../../../../shared/components/Input/validators';

const INPUT_MAX_LENGTH = 50;
const MIN_LENGTH_2 = 2;
const MIN_LENGTH_3 = 3;

export const formFields = (t) => [
  {
    key: 'lastName',
    required: true,
    label: t('adminControlPanel.lastName'),
    Component: Input,
    props: {
      admin: true,
      labelRised: true,
      showErrorAfterBlur: true,
      placeholder: t('adminControlPanel.passLastName'),
      transformer: capitalizeWordsAndPreserveDashesTransformer,
      validator: requiredValidator(MIN_LENGTH_3),
      maxLength: INPUT_MAX_LENGTH,
      minLength: MIN_LENGTH_2,
      autoCompleteName: 'lastName',
    },
  },
  {
    key: 'firstName',
    required: true,
    label: t('adminControlPanel.name'),
    Component: Input,
    props: {
      admin: true,
      labelRised: true,
      placeholder: t('adminControlPanel.passName'),
      transformer: nameTransformer,
      validator: makeRequired(alphabeticalValidator, MIN_LENGTH_3),
      showErrorAfterBlur: true,
      maxLength: INPUT_MAX_LENGTH,
      autoCompleteName: 'name',
    },
  },
  {
    key: 'country',
    required: true,
    label: t('adminControlPanel.country'),
    Component: Input,
    props: {
      admin: true,
      labelRised: true,
      placeholder: t('adminControlPanel.passCountry'),
      transformer: capitalizeWordsAndPreserveDashesTransformer,
      validator: makeRequired(alphabeticalValidator, MIN_LENGTH_2),
      showErrorAfterBlur: true,
      maxLength: INPUT_MAX_LENGTH,
      autoCompleteName: 'country',
    },
  },
  {
    key: 'address',
    required: true,
    label: t('adminControlPanel.street'),
    Component: Input,
    props: {
      admin: true,
      labelRised: true,
      placeholder: t('adminControlPanel.passStreet'),
      transform: capitalizeFirstLetter,
      validator: makeRequired(streetAndNumberValidator, MIN_LENGTH_3),
      showErrorAfterBlur: true,
      maxLength: INPUT_MAX_LENGTH,
      autoCompleteName: 'address',
    },
  },
  {
    key: 'city',
    required: true,
    label: t('adminControlPanel.city'),
    Component: Input,
    props: {
      admin: true,
      labelRised: true,
      placeholder: t('adminControlPanel.passCity'),
      transformer: capitalizeWordsAndPreserveDashesTransformer,
      validator: makeRequired(validateCityName, MIN_LENGTH_3),
      showErrorAfterBlur: true,
      autoCompleteName: 'city',
    },
  },
  {
    key: 'postalCode',
    required: true,
    label: t('adminControlPanel.postalCode'),
    Component: Input,
    props: {
      admin: true,
      labelRised: true,
      placeholder: t('adminControlPanel.passPostalCode'),
      transformer: postalCodeTransformer,
      validator: makeRequired(postalCodeValidator),
      showErrorAfterBlur: true,
      autoCompleteName: 'postalCode',
    },
  },
  {
    key: 'phone',
    required: true,
    label: t('adminControlPanel.fullPhoneNumber'),
    Component: Input,
    props: {
      admin: true,
      placeholder: t('adminControlPanel.passPhoneNumber'),
      labelRised: true,
      transformer: phoneNumberTransformer,
      validator: makeRequired(phoneNumberValidator),
      showErrorAfterBlur: true,
      autoCompleteName: 'phone',
    },
  },
  {
    key: 'nip',
    required: false,
    label: t('adminControlPanel.companyNip'),
    Component: Input,
    props: {
      admin: true,
      placeholder: t('adminControlPanel.passCompanyNip'),
      labelRised: true,
      validator: companyNipValidator,
      transformer: companyNipTransformer,
      showErrorAfterBlur: true,
    },
  },
];

export const renderInputComponent = ({
  field: {
    key,
    label,
    Component,
    required,
    props: {
      admin,
      placeholder,
      labelRised,
      transformer,
      validator,
      showErrorAfterBlur,
      maxLength,
      minLength,
      autoCompleteName,
    },
  },
  form,
  onInputChange,
}) => (
  <div className={classes.label} key={key}>
    {label}
    {required ? '*' : ''}
    <Component
      admin={admin}
      placeholder={placeholder}
      labelRised={labelRised}
      transformer={transformer}
      validator={validator}
      showErrorAfterBlur={showErrorAfterBlur}
      value={form[key]}
      onChange={onInputChange(key)}
      maxLength={maxLength}
      minLength={minLength}
      autoCompleteName={autoCompleteName}
    />
  </div>
);

export const autoCompleDataDisplayHandler = (value) => {
  if (!value) return '';

  return `${value.firstName} ${value.lastName} - NIP: ${value.nip || i18next.t('global.noData')}, ${i18next.t(
    'adminControlPanel.phoneNumberShort',
    { phone: value.phone, defaultValue: i18next.t('global.noData') },
  )}`;
};
