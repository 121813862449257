import dayjs from 'dayjs';
import {
  buttonActions,
  buttonActionsEnum,
  handleCallToAction,
  handleDate,
  handleDefault,
  handleOneDayOffer,
  handleTime,
  handleTitle,
  handleUrl,
  modalFormFieldsEnum,
  updateLocalPost,
} from './changeDataFieldHandles';
import { plannedPostValidator, required, urlValidator } from '../../../../../../shared/components/Input/validators';
import { getDateFromObject } from '../../../../../../shared/helpers/strings';

export const POST_STATE_ENUM = {
  LIVE: 'LIVE',
  REJECTED: 'REJECTED',
  PROCESSING: 'PROCESSING',
  PLANNED: 'PLANNED',
};

export const formEnum = {
  STANDARD: 'STANDARD',
  EVENT: 'EVENT',
  OFFER: 'OFFER',
  ALERT: 'ALERT',
  previewUpdate: 'previewUpdate',
  previewOffer: 'previewOffer',
  previewEvent: 'previewEvent',
};

const topicTypeEnum = {
  STANDARD: 'STANDARD',
  EVENT: 'EVENT',
  OFFER: 'OFFER',
};

export const initialDataUpdate = {
  type: topicTypeEnum.STANDARD,
  localPost: {
    summary: '',
    languageCode: 'pl',
    topicType: topicTypeEnum.STANDARD,
    media: [],
  },
};

export const initialDataOffer = {
  type: topicTypeEnum.OFFER,
  localPost: {
    languageCode: 'pl',
    topicType: topicTypeEnum.OFFER,
    event: {
      title: '',
      schedule: {
        startDate: { day: 0, month: 0, year: 0 },
        endDate: { day: 0, month: 0, year: 0 },
      },
    },
  },
};

export const initialDataEvent = {
  type: topicTypeEnum.EVENT,
  localPost: {
    languageCode: 'pl',
    summary: '',
    topicType: topicTypeEnum.EVENT,
    event: {
      title: '',
      schedule: {
        startDate: { day: 0, month: 0, year: 0 },
        endDate: { day: 0, month: 0, year: 0 },
      },
    },
  },
};

export const handleStandardForm = (name, value, setter) => {
  if (name === modalFormFieldsEnum.CALL_TO_ACTION) {
    handleCallToAction(value, setter);
  } else if (name === modalFormFieldsEnum.URL) {
    handleUrl(value, setter);
  } else {
    handleDefault(name, value, setter);
  }
};

export const handleOfferForm = (name, value, setter) => {
  const handleSpecialOfferFields = () => {
    updateLocalPost(
      (localPost) => ({
        ...localPost,
        offer: {
          ...localPost?.offer,
          [name]: value,
        },
      }),
      setter,
    );
  };

  if (name === modalFormFieldsEnum.TITLE) {
    handleTitle(value, setter);
  } else if ([modalFormFieldsEnum.START_DATE, modalFormFieldsEnum.END_DATE].includes(name)) {
    handleDate(value, name, setter);
  } else if (name === 'time') {
    handleOneDayOffer(name, value, setter);
  } else if (
    [
      modalFormFieldsEnum.COUPON_CODE,
      modalFormFieldsEnum.REDEEM_ONLINE_URL,
      modalFormFieldsEnum.TERMS_CONDITIONS,
    ].includes(name)
  ) {
    handleSpecialOfferFields();
  } else {
    handleDefault(name, value, setter);
  }
};

export const handleEventForm = (name, value, setter) => {
  if (name === modalFormFieldsEnum.TITLE) {
    handleTitle(value, setter);
    return;
  }
  if ([modalFormFieldsEnum.START_DATE, modalFormFieldsEnum.END_DATE].includes(name)) {
    handleDate(value, name, setter);
    return;
  }
  if (name === modalFormFieldsEnum.CALL_TO_ACTION) {
    handleCallToAction(value, setter);
  } else if (name === modalFormFieldsEnum.URL) {
    handleUrl(value, setter);
  } else if (name === 'oneDayWithNoTime') {
    handleOneDayOffer(name, value, setter);
  } else if ([modalFormFieldsEnum.START_TIME, modalFormFieldsEnum.END_TIME].includes(name)) {
    handleTime(value, name, setter);
  } else {
    handleDefault(name, value, setter);
  }
};

const createLocalPost = (post, topicType) => ({
  summary: post.summary ?? '',
  languageCode: post.languageCode ?? 'pl',
  topicType,
});

const addCallToAction = (data, callToActionType, post) => {
  if (callToActionType) {
    return {
      ...data,
      localPost: {
        ...data.localPost,
        callToAction: {
          actionType: callToActionType.googleID,
          url: post.callToAction?.url ?? '',
        },
      },
    };
  }
  return data;
};

const addPublishDate = (data, isPlanned, post) => {
  if (isPlanned) {
    return {
      ...data,
      publishDate: post.publishDate,
    };
  }
  return data;
};

const addMedia = (data, post) => {
  if (post.media && post.media.length > 0) {
    return {
      ...data,
      localPost: {
        ...data.localPost,
        media: [{ ...post.media[0], mediaSource: post.media[0].googleUrl || post.media[0].sourceUrl }],
      },
    };
  }
  return {
    ...data,
    localPost: {
      ...data.localPost,
      media: [],
    },
  };
};

const addEventDetails = (data, post) => ({
  ...data,
  localPost: {
    ...data.localPost,
    event: {
      title: post.event?.title ?? '',
      schedule: {
        startDate: post.event?.schedule?.startDate ?? { day: 0, month: 0, year: 0 },
        endDate: post.event?.schedule?.endDate ?? { day: 0, month: 0, year: 0 },
        startTime: post.event?.schedule?.startTime ?? { hours: 0, minutes: 0 },
        endTime: post.event?.schedule?.endTime ?? { hours: 0, minutes: 0 },
      },
    },
  },
});

const addOfferDetails = (data, post) => ({
  ...data,
  localPost: {
    ...data.localPost,
    offer: {
      couponCode: post.offer?.couponCode ?? '',
      redeemOnlineUrl: post.offer?.redeemOnlineUrl ?? '',
      termsConditions: post.offer?.termsConditions ?? '',
    },
    event: {
      title: post.event?.title ?? '',
      schedule: {
        startDate: post.event?.schedule?.startDate ?? { day: 0, month: 0, year: 0 },
        endDate: post.event?.schedule?.endDate ?? { day: 0, month: 0, year: 0 },
      },
    },
  },
});

export const initialFormDataWithEditedPostValuesParser = (post, t) => {
  const callToActionType = buttonActions.find((button) => t(button.googleID) === post.callToAction?.actionType);
  const isPlanned = post.state === POST_STATE_ENUM.PLANNED;
  let initialData = { type: '', localPost: createLocalPost(post, post.topicType) };
  switch (post.topicType) {
    case formEnum.STANDARD:
      initialData.type = topicTypeEnum.STANDARD;
      initialData = addCallToAction(initialData, callToActionType, post);
      initialData = addPublishDate(initialData, isPlanned, post);
      initialData = addMedia(initialData, post);
      break;
    case formEnum.OFFER:
      initialData.type = topicTypeEnum.OFFER;
      initialData = addMedia(initialData, post);
      initialData = addPublishDate(initialData, isPlanned, post);
      initialData = addOfferDetails(initialData, post);
      break;
    case formEnum.EVENT:
      initialData.type = topicTypeEnum.EVENT;
      initialData = addMedia(initialData, post);
      initialData = addCallToAction(initialData, callToActionType, post);
      initialData = addPublishDate(initialData, isPlanned, post);
      initialData = addEventDetails(initialData, post);
      break;
    default:
      break;
  }
  return initialData;
};

export const updateFormFieldsValidation = (data) => {
  const { localPost, publishDate } = data;
  const { summary, callToAction } = localPost;
  const isSummaryInvalid = !summary.length;
  const isCallToActionProvided = Boolean(callToAction) && callToAction?.actionType !== buttonActionsEnum.CALL;
  const isUrlValid = callToAction?.actionType !== 'CALL' && urlValidator(callToAction?.url);
  const isPlanned = Boolean(publishDate);
  const isBeforeToday = dayjs(publishDate).isBefore(dayjs());

  return (
    isSummaryInvalid
    || (isCallToActionProvided && isUrlValid)
    || (isCallToActionProvided && !callToAction?.url)
    || (isPlanned && isBeforeToday)
  );
};

export const offerFormFieldsValidation = (data) => {
  const { localPost, publishDate } = data;

  const isPlanned = Boolean(publishDate);
  const invalidTitle = Boolean(!localPost?.event?.title?.trim().length);
  const isOfferReedemOnlineInvalid = Boolean(urlValidator(localPost?.offer?.redeemOnlineUrl));
  const { startDate, endDate } = localPost?.event?.schedule ?? { startDate: null, endDate: null };
  const dayjsStartDate = dayjs(getDateFromObject(startDate));
  const dayjsEndDate = dayjs(getDateFromObject(endDate));
  const isBeforeOrEqualStartDate = dayjs(publishDate).isBefore(dayjs());
  const plannedDateIsBeforeEndDate = dayjs(publishDate).isBefore(dayjs(dayjsEndDate));
  const datesAreValid = dayjsStartDate.isValid() && dayjsEndDate.isValid();
  const endDateAfterOrEqualStartDate = dayjsEndDate.isAfter(dayjsStartDate) || dayjsEndDate.isSame(dayjsStartDate);
  const validOffer = datesAreValid && endDateAfterOrEqualStartDate;

  return (
    invalidTitle
    || isOfferReedemOnlineInvalid
    || !validOffer
    || (isPlanned && (isBeforeOrEqualStartDate || !plannedDateIsBeforeEndDate))
    || dayjs(publishDate).isSame(dayjs(dayjsEndDate))
  );
};
export const getModalTitle = (filteredOptions, showPreview, editPost, t) => {
  if (showPreview) {
    return t(`posts.preview${filteredOptions[0]?.type}`);
  }
  if (editPost) {
    return t(filteredOptions[0]?.type ? `posts.update${filteredOptions[0]?.type}` : 'posts.addPost');
  }
  return t(filteredOptions[0]?.title || 'posts.addPost');
};

export const eventFormFieldsValidation = (data) => {
  const { localPost, publishDate } = data;
  const invalidTitle = Boolean(!localPost?.event?.title?.trim().length);
  const isPlanned = Boolean(publishDate);

  const { startDate, endDate, startTime, endTime } = localPost?.event?.schedule ?? {
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
  };
  const isCallToActionProvided = Boolean(localPost.callToAction) && localPost.callToAction?.actionType !== buttonActionsEnum.CALL;
  const isUrlValid = urlValidator(localPost.callToAction?.url);
  const dayjsStartDate = dayjs(getDateFromObject(startDate));
  const dayjsEndDate = dayjs(getDateFromObject(endDate));
  const datesAreValid = dayjsStartDate.isValid() && dayjsEndDate.isValid();
  const endDateAfterOrEqualStartDate = dayjsEndDate.isAfter(dayjsStartDate) || dayjsEndDate.isSame(dayjsStartDate);
  const isBeforeOrEqualStartDate = dayjs(publishDate).isBefore(dayjs());
  const plannedDateIsBeforeEndDate = dayjs(publishDate).isBefore(dayjs(dayjsEndDate));
  const timesAreValid = (!startTime && !endTime) || (startTime && endTime);
  const validOffer = datesAreValid && endDateAfterOrEqualStartDate && timesAreValid;

  return (
    invalidTitle
    || !validOffer
    || (isCallToActionProvided && isUrlValid)
    || (isCallToActionProvided && !localPost.callToAction?.url)
    || (isPlanned && (isBeforeOrEqualStartDate || !plannedDateIsBeforeEndDate))
  );
};
