const TAB_ENUM = {
  dashboard: 'dashboard',
  reports: 'reports',
  businessProfileEdit: 'businessProfileEdit',
  businessProfileChangeHistory: 'businessProfileChangeHistory',
  localPosts: 'localPosts',
  reviews: 'reviews',
  templates: 'templates',
  media: 'media',
};

export const modalTabs = (t) => [
  { key: TAB_ENUM.dashboard, label: t('adminControlPanel.dashboard') },
  { key: TAB_ENUM.reports, label: t('adminControlPanel.reports') },
  {
    key: TAB_ENUM.businessProfileEdit,
    label: t('adminControlPanel.businessProfileEdit'),
    expandable: [
      { key: TAB_ENUM.businessProfileChangeHistory, label: t('adminControlPanel.businessProfileChangeHistory') },
    ],
  },
  { key: TAB_ENUM.localPosts, label: t('adminControlPanel.localPosts') },
  {
    key: TAB_ENUM.reviews,
    label: t('adminControlPanel.reviews'),
    expandable: [{ key: TAB_ENUM.templates, label: t('adminControlPanel.templates') }],
  },
  { key: TAB_ENUM.media, label: t('adminControlPanel.media') },
];
