import React from 'react';
import { useTranslation } from 'react-i18next';
import Input from '../../../../../shared/components/Input';
import Select from '../../../../../shared/components/Select';
import classes from './styles.module.css';
import { getMediaTypeList, MEDIA_ENUM } from '../AddMediaModal/utils';

const AddMediaDetails = ({
  selectedMediaTypes,
  mediaItem,
  index,
  editItem,
  onSelectHandler,
  categoryMediaItemToChange,
}) => {
  const { t } = useTranslation();
  const { filename } = mediaItem;

  const { COVER, PROFILE } = MEDIA_ENUM;
  const isReadOnlySelect = selectedMediaTypes[index] === MEDIA_ENUM.VIDEO || Boolean(editItem) || Boolean(categoryMediaItemToChange);

  const selectMediaList = getMediaTypeList(t, mediaItem).filter((els) => {
    if (categoryMediaItemToChange) return els.id === categoryMediaItemToChange;
    const isCoverSelected = Object.values(selectedMediaTypes).includes(COVER);
    const isProfileSelected = Object.values(selectedMediaTypes).includes(PROFILE);
    return !((isCoverSelected && els.id === COVER) || (isProfileSelected && els.id === PROFILE));
  });
  const showOnSelectListHandler = (value) => value?.label || selectMediaList.find((el) => el.id === value)?.label;

  return (
    <div className={classes.mediaDetailWrapper}>
      <Input className={classes.fileName} readOnly value={filename} />
      <Select
        className={classes.select}
        label={t('businessProfileEdit.photoType')}
        list={selectMediaList}
        onSelect={(selectedValue) => onSelectHandler(selectedValue, index)}
        showOnList={showOnSelectListHandler}
        value={selectedMediaTypes[index]}
        readOnly={isReadOnlySelect}
      />
    </div>
  );
};

export default AddMediaDetails;
