import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import CircleIconText from '../../../../../shared/components/CircleIconText';
import Button from '../../../../../shared/components/Button';
import { ReactComponent as Magnifier } from '../../../../../shared/assets/magnifier.svg';

const SubscriptionsEmptyState = ({ onPurchaseClick }) => {
  const { t } = useTranslation();

  return (
    <div className={classes.content}>
      <CircleIconText
        headingStyle={classes.headingStyle}
        heading={t('subscription.subEmptyState')}
        supportingText={t('subscription.subEmptyStateSubInfo')}
        supportingTextStyle={classes.supportingTextStyle}
        icon={Magnifier}
      />
      <Button className={classes.button} onClick={onPurchaseClick} label={t('subscription.buySubscription')} />
    </div>
  );
};

export default SubscriptionsEmptyState;
