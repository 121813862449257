import clsx from 'clsx';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import KeywordDistance from '../../../app/SEO/modules/GenerateReport/KeywordDistance';
import KeywordNet from '../../../app/SEO/modules/GenerateReport/KeywordNet';
import KeywordsArea from '../../../app/SEO/modules/GenerateReport/KeywordsArea';
import classes from './style.module.css';
import { ReactComponent as ChevronDown } from '../../assets/chevronDown.svg';
import IconWrapper from '../IconWrapper/index';
import { SelectEnum, areaMultiplierConfig, selectList } from './utils';

const FiltersDropDown = ({ filters, handleFiltersChange, focusedKey, setFocusedKey, subscription }) => {
  const { t } = useTranslation();
  const list = useRef();
  const { area, net, distance } = SelectEnum;
  const multiplier = areaMultiplierConfig[filters[net]];
  const areaValue = multiplier * filters[distance];

  const handleFocus = (key) => (e) => {
    if (key !== 'net') {
      setFocusedKey(key);
    }
  };

  const isIconFocused = (id) => focusedKey === id;

  const handleAreaChange = (value) => {
    const newValue = value > areaValue ? value : areaValue;
    handleFiltersChange('area')(newValue);
  };

  useEffect(() => {
    handleAreaChange(filters[area]);
  }, [areaValue]);

  useEffect(() => {
    const controller = new AbortController();
    if (focusedKey) {
      const hideModal = (event) => {
        if (!list.current.contains(event.target)) {
          setFocusedKey(null);
        }
      };
      document.addEventListener('click', hideModal);
      return () => {
        document.removeEventListener('click', hideModal);
        controller.abort();
      };
    }

    return () => {
      controller.abort();
    };
  }, [focusedKey]);

  return (
    <div ref={list} className={classes.fields}>
      {selectList.map(({ id, name }) => (
        <div
          aria-label={id}
          tabIndex={0}
          role="button"
          onFocus={handleFocus(id)}
          key={id}
          className={clsx(classes.field, id === 'net' && classes.disabled)}
        >
          <div className={classes.label}>
            {t(name)}
            <IconWrapper
              className={clsx(classes.icon, isIconFocused(id) && classes.invertedIcon)}
              Icon={ChevronDown}
              height={20}
              width={20}
              color={isIconFocused(id) ? 'var(--primary600)' : 'var(--grey600)'}
              hoverColor="var(--grey600)"
            />
          </div>

          {id === net && (
            <>
              <div className={clsx(classes.value, classes.readOnly)}>
                {filters[net] ? `${filters[net]} x ${filters[net]}` : ''}
              </div>
              {id === focusedKey && (
                <div className={classes.changeComponentWrapper}>
                  <KeywordNet onChange={handleFiltersChange(id)} value={filters[net]} subscription={subscription} />
                </div>
              )}
            </>
          )}
          {id === area && (
            <>
              <div className={classes.value}>{filters[area] ? `${filters[area]} km` : ''}</div>
              {id === focusedKey && (
                <div className={classes.changeComponentWrapper}>
                  <KeywordsArea minValue={areaValue} onChange={handleAreaChange} value={filters[area]} />
                </div>
              )}
            </>
          )}
          {id === distance && (
            <>
              <div className={classes.value}>{filters[distance] ? `${filters[distance]} km` : ''}</div>
              {id === focusedKey && (
                <div className={classes.changeComponentWrapper}>
                  <KeywordDistance onChange={handleFiltersChange(id)} value={filters[distance]} />
                </div>
              )}
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default FiltersDropDown;
