import React from 'react';
import BusinessCardsSettings from '../../modules/Settings/BusinessCardsSettings';
import ChangePassword from '../../modules/Settings/ChangePassword';
import MyAccount from '../../modules/Settings/MyAccount';
import InvoicesData from '../../modules/Settings/InvoicesData';

export const PATH_ENUM = {
  PROFILE: '/settings/profile',
  PASSWORD: '/settings/password',
  MY_BUSINESS_CARDS: '/settings/my_business_cards',
  SUBSCRIPTIONS: '/settings/subscriptions',
  INVOICES_DATA: '/settings/invoicesData',
};

export const translatedTabs = (t, businessProfiles) => {
  const tabs = [
    {
      label: t('settings.myAccount'),
      path: PATH_ENUM.PROFILE,
    },
    {
      label: t('settings.password'),
      path: PATH_ENUM.PASSWORD,
    },
  ];

  if (businessProfiles.length) {
    tabs.push(
      {
        label: t('settings.myBusinessCards'),
        path: PATH_ENUM.MY_BUSINESS_CARDS,
      },
      {
        label: t('settings.invoicesData'),
        path: PATH_ENUM.INVOICES_DATA,
      },
    );
  }

  return tabs;
};

export const TAB_COMPONENTS = {
  '/settings/my_business_cards': <BusinessCardsSettings />,
  '/settings/profile': <MyAccount />,
  '/settings/password': <ChangePassword />,
  '/settings/invoicesData': <InvoicesData />,
};
