import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { useDashboard } from '../../helpers/dashboard';
import Loader from '../../components/Loader';
import ProfileNotVerified from '../../modules/Subscription/ProfileNotVerified';
import SubscriptionsEmptyState from '../../modules/Subscription/SubscriptionEmptyState';
import { profileStatus } from '../../modules/Settings/BusinessItem/utils';
import { subscriptionsConfig } from '../../helpers/constants';
import { useGetVerificationStatus, useModal } from '../../../../shared/helpers/hooks';
import PurchaseSubscriptionForm from '../../modules/Subscription/PurchaseSubscriptionForm';
import SubscriptionDetails from '../../modules/Subscription/SubscriptionDetails';

const Subscription = () => {
  const { t } = useTranslation('');
  const { businessProfile } = useDashboard();

  const { subscription } = businessProfile;
  const {
    businessProfileVerificationStatus,
    requestStatus: { loading, error },
  } = useGetVerificationStatus(businessProfile);

  const [showForm, setShowForm, toggleForm] = useModal();

  const renderContent = () => {
    if (!businessProfileVerificationStatus) return null;

    if (businessProfileVerificationStatus === profileStatus.verified) {
      return subscription === subscriptionsConfig.free ? (
        <SubscriptionsEmptyState onPurchaseClick={toggleForm} />
      ) : (
        <SubscriptionDetails />
      );
    }

    return <ProfileNotVerified status={businessProfileVerificationStatus} />;
  };

  return (
    <div className={classes.wrapper}>
      {loading ? (
        <div className={classes.loaderWrapper}>
          <Loader />
        </div>
      ) : (
        <>
          <div className={classes.header}>{t('subscription.title')}</div>
          <div className={classes.content}>
            {renderContent()}
            {showForm && <PurchaseSubscriptionForm onClose={toggleForm} />}
            {error && <div className={classes.error}>{t('subscription.error')}</div>}
          </div>
        </>
      )}
    </div>
  );
};

export default Subscription;
