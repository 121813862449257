import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../shared/components/Modal';
import classes from './styles.module.css';
import { ReactComponent as Copy } from '../../../../../shared/assets/copy.svg';
import Input from '../../../../../shared/components/Input';
import PillWithIcon from '../../../../../shared/components/Pill/PillAlternative';
import { pillsArray } from '../../../views/Opinions/utils';
import { useNotification } from '../../../../../shared/helpers/notification';
import { useApi } from '../../../../../shared/helpers/api';
import { useDashboard } from '../../../helpers/dashboard';

const GetMoreOpinionsModal = ({ modalHandler, opinionLink, rwdModalBackTitle }) => {
  const { t } = useTranslation();
  const { businessProfile } = useDashboard();
  const { showNotification } = useNotification();
  const { api } = useApi();

  const [link, setLink] = useState(opinionLink);

  const fetchLink = async () => {
    const controler = new AbortController();
    const { data } = await api.post(
      'review/getReviewsLink',
      { businessProfileId: businessProfile.id },
      { signal: controler.signal },
    );
    setLink(data.newReviewUri);
    return () => controler.abort();
  };

  const copyHandler = () => {
    if (navigator.clipboard && navigator.clipboard.writeText) {
      showNotification({ label: t('global.success'), message: t('opinions.linkCopied'), type: 'success' });
      navigator.clipboard.writeText(link);
    } else {
      showNotification({ label: t('global.error'), message: t('opinions.copyError'), type: 'error' });
    }
  };

  useEffect(() => {
    fetchLink();
  }, []);

  return (
    <Modal
      contentStyle={classes.content}
      onCancel={modalHandler}
      modalTitle={t('opinions.getMoreOpinions')}
      rwdBackTitle={rwdModalBackTitle}
    >
      <div className={classes.modalContent}>
        <div className={classes.howItWorks}>{t('opinions.howItWorks')}</div>
        {t('opinions.shareLinkModal')}
        <div className={classes.subInfo}>{t('opinions.shareLinkModalSubInfo')}</div>
      </div>
      <div className={classes.askForOpinion}>
        {t('opinions.askForOpinionVia')}
        <div className={classes.askForOpinionIcons}>
          {pillsArray.map(({ Icon, ...pill }) => (
            <PillWithIcon onClick={() => pill.onClick(link)} className={classes.pill} key={pill.id}>
              {Icon && <Icon stroke={pill.strokeColor} width={pill.iconWidth} height={pill.iconHeight} />}
              {pill.label}
            </PillWithIcon>
          ))}
        </div>
        <div className={classes.row}>
          <Input className={classes.webInput} readOnly labelRised label={t('opinions.linkToOpinion')} value={link} />
          <Copy onClick={copyHandler} className={classes.copyIcon} />
        </div>
      </div>
    </Modal>
  );
};
export default GetMoreOpinionsModal;
