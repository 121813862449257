import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import NoAccessInformation from '../../../components/NoAccessInformation';
import CircleIconText from '../../../../../shared/components/CircleIconText';
import Button from '../../../../../shared/components/Button';
import { useDashboard } from '../../../helpers/dashboard';
import { readOnlySubList } from '../../../helpers/constants';
import { ReactComponent as Magnifier } from '../../../../../shared/assets/magnifier.svg';
import { ReactComponent as AddCircle } from '../../../../../shared/assets/addCircle.svg';
import { TAB_ENUM } from '../../../views/AddPost/utils';

const MediaEmptyState = ({ adminPreview, onAddMedia, selectedTab }) => {
  const { t } = useTranslation();
  const {
    businessProfile: { subscription },
  } = useDashboard();
  const handleAddMedia = () => onAddMedia();
  const isPlannedTab = selectedTab === TAB_ENUM.PLANNED;

  const isReadOnlySubPlan = readOnlySubList.includes(subscription);

  return (
    <div className={classes.wrapper}>
      {isReadOnlySubPlan ? (
        <NoAccessInformation />
      ) : (
        <>
          <CircleIconText
            icon={Magnifier}
            heading={isPlannedTab ? t('media.noPlannedMedia') : t('media.noMedia')}
            supportingText={isPlannedTab ? t('media.noPlannedMediaSubInfo') : t('media.addFirstMedia')}
            headingStyle={classes.headingStyle}
          />
          {!adminPreview && (
            <Button
              onClick={handleAddMedia}
              className={classes.button}
              Icon={AddCircle}
              iconStyle={classes.iconStyle}
              label={t('media.addMedia')}
            />
          )}
        </>
      )}
    </div>
  );
};

export default MediaEmptyState;
