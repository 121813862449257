import React from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import { useAuth } from '../../../../../shared/helpers/auth';

const FiltersTitle = ({
  adminPreview,
  editFilters,
  handleGenerateReport,
  readOnlyBtn,
  editFiltersHandler,
  isReadOnlySubPlan,
  selectedBusinessProfile,
}) => {
  const { t } = useTranslation();
  const { businessProfiles } = useAuth();
  const foundBpReportsLeft = businessProfiles.find((el) => el.name === selectedBusinessProfile)?.availableReports || 0;

  const renderButtons = (isTablet = false) => (
    <div className={clsx(classes.actionsWrapper, isTablet && classes.tabletActions)}>
      <Button
        className={clsx(classes.button, classes.generateReport, isTablet && classes.tabletBtn)}
        onClick={handleGenerateReport}
        label={t('generateReport.generateNewReport')}
        readOnly={readOnlyBtn}
      />
      <Button
        className={clsx(classes.button, classes.cancelButton, isTablet && classes.tabletBtn)}
        onClick={editFiltersHandler}
        label={t('global.cancel')}
      />
    </div>
  );

  const renderChangeFiltersButton = (isTablet = false) => !isReadOnlySubPlan
    && Boolean(foundBpReportsLeft) && (
      <Button
        className={clsx(
          classes.button,
          isTablet && classes.tabletBtn,
          adminPreview && !isTablet && classes.displayNone,
        )}
        onClick={editFiltersHandler}
        label={t('generateReport.changeFilters')}
      />
  );

  return (
    <div className={classes.filtersTitle}>
      {!adminPreview && t('generateReport.yourFilters')}
      {editFilters ? renderButtons() : renderChangeFiltersButton()}
      {editFilters ? renderButtons(true) : renderChangeFiltersButton(true)}
    </div>
  );
};

export default FiltersTitle;
