import React, { useEffect, useMemo, useRef, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import GenerateReportOptions from '../GenerateReportOptions';
import KeywordsArea from '../KeywordsArea';
import { parseAccountDetails, SelectEnum, selectList } from './utils';
import KeywordNet from '../KeywordNet';
import KeywordDistance from '../KeywordDistance';
import Button from '../../../../../shared/components/Button';
import AddressForm from '../../SetBusinessKeyWords/AddressForm';
import { areaMultiplierConfig } from '../../../../../shared/components/FiltersDropDown/utils';
import { useAuth } from '../../../../../shared/helpers/auth';
import { readOnlySubList } from '../../../helpers/constants';
import FiltersTitle from '../FiltersTitle';

const KEYWORD_MAX_LENGTH = 50;
const KeywordSelect = ({ className, onChange, accountDetails, values, onGenerateReport, adminPreview }) => {
  const { t } = useTranslation();
  const focusedElement = useRef();
  const { businessProfiles: businessProfileList } = useAuth();
  const [focusedKey, setFocusedKey] = useState(null);
  const [editFilters, setEditFilters] = useState(false);
  const [readOnlyBtn, setReadOnlyBtn] = useState(false);

  const { area, net, distance } = SelectEnum;
  const { addressLines, postalCode, locality } = values;

  const valuesIncludesAddress = Object.keys(values).includes('addressLines');
  const fullAddress = `${addressLines || ''}, ${postalCode || ''} ${locality || ''}`;
  const filterFields = !valuesIncludesAddress
    ? selectList.filter((el) => {
      if (adminPreview && el.id === 'businessProfileId') {
        return false;
      }
      return el.id !== SelectEnum.address;
    })
    : selectList;

  const multiplier = areaMultiplierConfig[values[net]];
  const areaValue = multiplier * values[distance];

  const { businessProfiles, selectedBusinessProfile } = useMemo(
    () => parseAccountDetails({ accountDetails, values }),
    [accountDetails, values],
  );
  const bpSubPlan = adminPreview
    ? null
    : businessProfileList.find((el) => el.id === values[SelectEnum.businessProfileId])?.subscription;
  const isReadOnlySubPlan = readOnlySubList.includes(bpSubPlan);

  const handleFocus = (key) => (e) => {
    setFocusedKey(key);
    focusedElement.current = e.target;
  };

  const handleValues = (name) => (value) => {
    onChange({ name, value });
  };

  const editFiltersHandler = () => setEditFilters((prev) => !prev);

  const handleKeywordChange = (e) => {
    handleValues(SelectEnum.keyword)(e.target.value);
  };

  const handleGenerateReport = () => {
    onGenerateReport();
    setEditFilters(false);
  };

  const changeAddressHandler = (addressValue) => {
    Object.entries(addressValue).forEach(([key, value]) => {
      handleValues(key)(value);
    });
  };
  const handleAreaChange = (value) => {
    const newValue = value > areaValue ? value : areaValue;
    onChange({ name: area, value: newValue });
  };

  useEffect(() => {
    handleAreaChange(values[area]);
  }, [areaValue]);

  useEffect(() => {
    if (focusedKey) {
      const controller = new AbortController();
      const hideModal = (event) => {
        if (!focusedElement.current.contains(event.target)) {
          controller.abort();
          setFocusedKey(null);
        }
      };
      document.addEventListener('click', hideModal, { signal: controller.signal });
      return () => {
        controller.abort();
      };
    }
    return () => {};
  }, [focusedKey]);

  return (
    <div className={clsx(classes.wrapper, className, adminPreview && classes.adminPreview)}>
      <FiltersTitle
        adminPreview={adminPreview}
        editFilters={editFilters}
        editFiltersHandler={editFiltersHandler}
        handleGenerateReport={handleGenerateReport}
        isReadOnlySubPlan={isReadOnlySubPlan}
        readOnlyBtn={readOnlyBtn}
        selectedBusinessProfile={selectedBusinessProfile}
      />
      <div
        className={clsx(
          classes.fields,
          editFilters && classes.editFilters,
          valuesIncludesAddress && classes.fieldsWithAddress,
        )}
      >
        {filterFields.map((el, index) => (
          <div
            tabIndex={0}
            role="button"
            onFocus={handleFocus(el.id)}
            key={el.id}
            className={clsx(
              classes.field,
              focusedKey === el.id && classes.focused,
              focusedKey === selectList[index - 1]?.id && classes.leftFocused,
              editFilters && classes.editFilters,
            )}
          >
            <div className={classes.label}>{t(el.name)}</div>
            {el.id === SelectEnum.businessProfileId && (
              <>
                <div className={classes.value}>{selectedBusinessProfile}</div>
                {el.id === focusedKey && (
                  <div className={classes.changeComponentWrapper}>
                    <GenerateReportOptions
                      label={t('generateReport.selectCompany')}
                      options={businessProfiles}
                      onChange={handleValues(el.id)}
                      value={values[SelectEnum.businessProfileId]}
                    />
                  </div>
                )}
              </>
            )}
            {el.id === SelectEnum.keyword && (
              <input
                maxLength={KEYWORD_MAX_LENGTH}
                onChange={handleKeywordChange}
                value={values[SelectEnum.keyword]}
                className={classes.keywordInput}
              />
            )}
            {el.id === SelectEnum.distance && (
              <>
                <div className={classes.value}>
                  {values[SelectEnum.distance] ? `${values[SelectEnum.distance]} km` : ''}
                </div>
                {el.id === focusedKey && (
                  <div className={classes.changeComponentWrapper}>
                    <KeywordDistance onChange={handleValues(el.id)} value={values[SelectEnum.distance]} />
                  </div>
                )}
              </>
            )}

            {el.id === SelectEnum.net && (
              <>
                <div className={clsx(classes.value, classes.readOnly)}>
                  {values[SelectEnum.net] ? `${values[SelectEnum.net]} x ${values[SelectEnum.net]}` : ''}
                </div>
                {el.id === focusedKey && (
                  <div className={classes.changeComponentWrapper}>
                    <KeywordNet onChange={handleValues(el.id)} value={values[SelectEnum.net]} />
                  </div>
                )}
              </>
            )}
            {el.id === SelectEnum.area && (
              <>
                <div className={classes.value}>{values[SelectEnum.area] ? `${values[SelectEnum.area]} km` : ''}</div>
                {el.id === focusedKey && (
                  <div className={classes.changeComponentWrapper}>
                    <KeywordsArea
                      minValue={areaValue}
                      onChange={(val) => handleAreaChange(val)}
                      value={values[SelectEnum.area]}
                    />
                  </div>
                )}
              </>
            )}
            {el.id === SelectEnum.address && (
              <>
                <div className={classes.value}>{valuesIncludesAddress && fullAddress}</div>
                {el.id === focusedKey && (
                  <div className={classes.changeComponentWrapper}>
                    <AddressForm
                      reportLocation={fullAddress}
                      keywordSelect
                      onLocationChange={changeAddressHandler}
                      onError={setReadOnlyBtn}
                    />
                  </div>
                )}
              </>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default KeywordSelect;
