import i18next from 'i18next';
import dayjs from 'dayjs';
import {
  EMAIL_REGEX_PATTERN,
  HOUR_REGEX_PATTERN,
  PASSWORD_REGEX_PATTERN,
  POSTAL_CODE_REGEX_PATTERN,
  URL_REGEX_PATTERN,
} from '../../../app/SEO/helpers/regex';

export const makeRequired = (validator, minLength = 0) => (value) => {
  if (!value) {
    return i18next.t('validators.requiredField');
  }
  if (value.length < minLength) {
    return i18next.t('validators.minLength', { minLength });
  }
  return validator(value);
};

export const required = (minLength = 0) => (el) => {
  if (!el || !el.length) {
    return i18next.t('validators.requiredField');
  }
  if (el.length < minLength) {
    return i18next.t('validators.minLength', { minLength });
  }
  return false;
};

export const postalCodeValidator = (value) => {
  if (!value) {
    return false;
  }

  const str = String(value);

  if (!POSTAL_CODE_REGEX_PATTERN.test(str)) {
    return i18next.t('validators.requiredZipCodeFormat');
  }

  return false;
};

export const priceValidator = (value) => {
  if (!value) {
    return false;
  }
  const valueAsNumber = Number(value);
  if (typeof valueAsNumber !== 'number' || Number.isNaN(valueAsNumber)) {
    return i18next.t('validators.incorrectAmount');
  }
  if (valueAsNumber < 0) {
    return i18next.t('validators.valueMustBePositive');
  }
  if (valueAsNumber > 999999999999.99) {
    return i18next.t('validators.valueTooBig');
  }
  return false;
};

export const phoneNumberValidator = (value) => {
  if (!value) {
    return false;
  }

  const str = String(value);
  const parsed = str.replace(/\s/g, '');

  if (!/^[0-9]{9}$/.test(parsed)) {
    return i18next.t('validators.incorrectFormat');
  }

  return false;
};

export const urlValidator = (value, protocolRequired = false) => {
  if (!value) {
    return false;
  }
  const webProtocolList = ['http://', 'https://'];
  if (protocolRequired && webProtocolList.every((protocol) => !value.startsWith(protocol))) {
    return i18next.t('validators.urlPrefixRequired');
  }
  if (!URL_REGEX_PATTERN.test(value)) {
    return i18next.t('validators.incorrectWebsiteFormat');
  }
  return false;
};

export const hourValidator = (value) => {
  if (!value) {
    return false;
  }
  if (!HOUR_REGEX_PATTERN.test(value)) {
    return i18next.t('validators.incorrectFormat');
  }
  return false;
};

export const passwordValidator = (value) => {
  if (!value) {
    return i18next.t('validators.passwordRequired');
  }
  if (value.length < 8) {
    return i18next.t('validators.passwordMustBeAtLeast8CharactersLong');
  }
  if (value.length > 20) {
    return i18next.t('validators.passwordMustBeLessThan20CharactersLong');
  }
  if (!/[A-Z]/.test(value)) {
    return i18next.t('validators.passwordMustContainOneCapitalLetter');
  }
  if (!PASSWORD_REGEX_PATTERN.test(value)) {
    return i18next.t('validators.passwordMustContainOneDigitAndSpecialCharacter');
  }
  return false;
};

export const validateCityName = (value) => {
  if (!value) {
    return false;
  }
  if (!/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]/.test(value)) {
    return i18next.t('validators.incorrectCityName');
  }
  return false;
};

export const yearValidator = (value) => {
  if (!value) {
    return false;
  }
  if (!Number(value)) {
    return i18next.t('validators.expectedNumber');
  }
  const str = String(value);
  if (!/^[0-9]{4}$/.test(str)) {
    return i18next.t('validators.expectedYearFormat');
  }
  return false;
};

export const emailValidator = (value) => {
  if (!value) {
    return false;
  }
  const str = String(value);
  if (!EMAIL_REGEX_PATTERN.test(str)) {
    return i18next.t('validators.incorrectEmailFormat');
  }
  return false;
};

export const streetAndNumberValidator = (value) => {
  if (!value) {
    return false;
  }
  const str = String(value);
  if (!/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ0-9]/.test(str)) {
    return i18next.t('validators.incorrectStreetFormat');
  }
  return false;
};

export const urlInDescriptionValidator = (value) => {
  if (!value) {
    return false;
  }
  if (URL_REGEX_PATTERN.test(value)) {
    return i18next.t('validators.urlWarning');
  }
  return false;
};

export const updateDescriptionValidator = (value) => {
  if (!value) {
    return i18next.t('validators.updateDescriptionRequired');
  }
  if (value.length > 1500) {
    return i18next.t('validators.updateDescriptionTooLong');
  }
  return false;
};

export const plannedPostValidator = (value) => {
  if (!value) {
    return false;
  }
  const date = dayjs(value);
  const today = dayjs();

  return date.isBefore(today) ? i18next.t('validators.plannedPostDateError') : false;
};

export const noFutureDateValidator = (value) => {
  if (!value) {
    return false;
  }
  const date = dayjs(value);
  const today = dayjs();
  return date.isAfter(today) ? i18next.t('validators.noFutureDate') : false;
};

export const alphabeticalValidator = (value) => {
  if (!value) {
    return false;
  }
  if (!/^[A-Za-zżźćńółęąśŻŹĆĄŚĘŁÓŃ\s]+$/.test(value)) {
    return i18next.t('validators.alphabeticalCharactersOnly');
  }
  return false;
};

const getControlDigit = (value) => {
  let controlSum = 0;
  const weights = [6, 5, 7, 2, 3, 4, 5, 6, 7];
  for (let i = 0; i < 9; i += 1) {
    controlSum += weights[i] * parseInt(value[i], 10);
  }
  return controlSum % 11;
};

export const companyNipValidator = (value) => {
  if (!value) {
    return false;
  }
  const parsedNip = value.replace(/[\s-]/g, '');
  const controlDigit = getControlDigit(parsedNip);

  if (parsedNip.length !== 10 || !/^\d{10}$/.test(parsedNip)) {
    return i18next.t('validators.incorrectNipFormat');
  }

  if (controlDigit !== parseInt(parsedNip[9], 10)) {
    return i18next.t('validators.incorrectNipFormat');
  }
  return false;
};

export const alphaNumericValidator = (dashAllowed = false) => (value) => {
  if (!value) {
    return false;
  }
  if (dashAllowed) {
    if (!/^[A-Za-z0-9-]+$/.test(value)) {
      return i18next.t('validators.alphanumericCharactersOnly');
    }
  } else if (!/^[A-Za-z0-9]+$/.test(value)) {
    return i18next.t('validators.alphanumericCharactersOnly');
  }

  return false;
};
