import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../shared/components/Modal';
import BuyerDataForm from '../../Subscription/BuyerDataForm';
import classes from './styles.module.css';

const InvoiceModalForm = ({ onClose, dataExist, onSuccess, editData }) => {
  const { t } = useTranslation();

  return (
    <Modal
      contentStyle={classes.modalContent}
      onCancel={onClose}
      modalTitle={editData ? t('invoices.editData') : t('invoices.addNewData')}
    >
      <div className={classes.wrapper}>
        <BuyerDataForm onCancel={onClose} dataExist={dataExist} onSuccess={onSuccess} editData={editData} />
      </div>
    </Modal>
  );
};

export default InvoiceModalForm;
