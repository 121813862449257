import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../shared/components/Modal';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { handleApiError } from '../ChangePassword/utils';
import Loader from '../../../components/Loader';

const ConfirmDefaultInvoice = ({ onClose, data, onSuccess }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const isCompanyInvoice = data.nip.length && data.companyName.length;

  const markAsDefaultHandler = async () => {
    setIsLoading(true);
    try {
      await api.post('/invoice/setDefaultInvoiceDetails', { id: data.id });
      onSuccess();
      onClose();
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal onCancel={onClose} modalTitle={t('invoices.setNewDefaultInvoiceData')} contentStyle={classes.modalContent}>
      {isLoading && (
        <div className={classes.loaderWrapper}>
          <Loader />
        </div>
      )}
      <div className={classes.modalBody}>
        <div className={classes.subinfo}>{t('invoices.setNewDefaultInvoiceDataSubInfo')}</div>
        <div className={classes.invoiceData}>
          {isCompanyInvoice ? (
            <div className={classes.basicData}>
              <div>{data.companyName}</div>
              <div>{data.nip}</div>
            </div>
          ) : (
            <div className={classes.basicData}>{`${data.name} ${data.lastName}`}</div>
          )}
          <div className={classes.addressData}>
            {`${data.address} ${data.postalCode} ${data.city}, ${data.country}`}
          </div>
        </div>
      </div>
      <div className={classes.actions}>
        <Button className={classes.cancelBtn} label={t('global.cancel')} onClick={onClose} />
        <Button label={t('invoices.saveAsDefault')} onClick={markAsDefaultHandler} />
      </div>
    </Modal>
  );
};

export default ConfirmDefaultInvoice;
