import { stringCharactersTransformer } from '../../../../../shared/components/Input/transformers';
import { alphabeticalValidator, emailValidator, makeRequired } from '../../../../../shared/components/Input/validators';

const requierdField = makeRequired(alphabeticalValidator);
const requiredMail = makeRequired(emailValidator);
export const INPUT_MAX_LENGTH = 50;
const MAIL_MAX_LENGTH = 75;
export const createAdminModalFields = (t) => [
  {
    label: t('adminControlPanel.name'),
    placeholder: t('adminControlPanel.passName'),
    value: 'name',
    validator: requierdField,
    transformer: stringCharactersTransformer,
    maxLength: INPUT_MAX_LENGTH,
    autoCompleteName: 'name',
  },
  {
    label: t('adminControlPanel.lastName'),
    placeholder: t('adminControlPanel.passLastName'),
    value: 'lastName',
    validator: requierdField,
    transformer: stringCharactersTransformer,
    maxLength: INPUT_MAX_LENGTH,
    autoCompleteName: 'lastName',
  },
  {
    label: t('adminControlPanel.email'),
    placeholder: t('adminControlPanel.passMail'),
    value: 'login',
    validator: requiredMail,
    transformer: (v) => String(v).toLowerCase().trim(),
    maxLength: MAIL_MAX_LENGTH,
    autoCompleteName: 'email',
  },
];
