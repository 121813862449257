import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import { useApi } from '../../../../shared/helpers/api';
import { emailValidator } from '../../../../shared/components/Input/validators';
import Button from '../../../../shared/components/Button';
import Input from '../../../../shared/components/Input';
import Loader from '../../components/Loader';
import IconWrapper from '../../../../shared/components/IconWrapper';
import { CODE_HASH, DEFAULT_GMAIL, DEFAULT_GMAIL_DOMAIN } from '../../helpers/constants';
import { ReactComponent as SuccessIcon } from '../../../../shared/assets/check.svg';
import { useKeyDown } from '../../../../shared/helpers/hooks';
import { KEYBOARD_KEYS_ENUM } from '../../helpers/enums';
import { AUTO_COMPLETE_TYPES } from '../../../../shared/helpers/const';

const RemindPassword = () => {
  const { api } = useApi();
  const { search } = useLocation();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState({ email: '' });
  const [error, setError] = useState(true);
  const resetHash = new URLSearchParams(search).get(CODE_HASH);
  const readOnly = Object.values(form).some((value) => !value) || error || loading;
  const shouldGmailBeAccessible = form.email.includes(DEFAULT_GMAIL_DOMAIN);
  const { enter, escape } = KEYBOARD_KEYS_ENUM;

  const handleRemindPassword = async () => {
    try {
      setLoading(true);
      await api.post('auth/resetPassword', { email: form.email });
      setSuccess(true);
    } catch (e) {
      setError(e);
    } finally {
      setLoading(false);
    }
  };

  const goBackHandler = () => {
    navigate(-1);
  };

  const loginChangeHandler = (value) => {
    setForm((prev) => ({ ...prev, email: value }));
  };

  const openMailBox = () => {
    if (shouldGmailBeAccessible) {
      window.open(DEFAULT_GMAIL, '_blank');
    }
  };

  useKeyDown({ key: escape, callback: goBackHandler });
  useKeyDown({ key: enter, callback: handleRemindPassword, canInvoke: !readOnly });

  useEffect(() => {
    if (resetHash && resetHash.length === 40) {
      navigate('/set_password', { state: { resetHash } });
    }
  }, [resetHash]);

  return (
    <div className={classes.wrapper}>
      {loading && (
        <div className={classes.loading}>
          <Loader />
        </div>
      )}
      <div className={classes.form}>
        {!success ? (
          <>
            <div className={classes.title}>{t('remindPassword.title')}</div>
            <div className={classes.description}>{t('remindPassword.subtitle')}</div>
            <Input
              onError={(e) => setError(e)}
              showErrorAfterBlur
              validator={emailValidator}
              onChange={loginChangeHandler}
              label="Login"
              value={form.email}
              autoCompleteName={AUTO_COMPLETE_TYPES.EMAIL}
            />
            <div className={classes.actions}>
              <Button onClick={goBackHandler} className={classes.backButton} label={t('global.goBack')} />
              <Button
                readOnly={readOnly}
                onClick={handleRemindPassword}
                className={classes.remindPasswordButton}
                label={t('global.next')}
              />
            </div>
          </>
        ) : (
          <div className={classes.successWrapper}>
            <IconWrapper Icon={SuccessIcon} className={classes.successIconWrapper} />
            <div className={classes.successTitle}>{t('remindPassword.successTitle')}</div>
            {shouldGmailBeAccessible && (
              <Button onClick={openMailBox} className={classes.successBtn} label={t('remindPassword.openMessage')} />
            )}
          </div>
        )}
      </div>
    </div>
  );
};
export default RemindPassword;
