import React, { useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import { useIsMobile } from '../../../../../shared/helpers/hooks';
import { ReactComponent as ArrowRightUp } from '../../../../../shared/assets/arrow-up-right.svg';
import { SMALL_MOBILE_WIDTH, subscriptionsConfig } from '../../../helpers/constants';
import { getButtonClass, getModelTitleClass, getModelWrapperClass } from './utils';
import PaymentMethods from '../PaymentMethods';

const PurchasePriceModel = ({ model, preview, purchaseMode, onButtonClick, onOfferSelect, selectedOffer }) => {
  const { t } = useTranslation();
  const isMobile = useIsMobile(SMALL_MOBILE_WIDTH);
  const { isComing, popular, title, subTitle, price } = model;
  const isTrialModel = title.toLowerCase() === subscriptionsConfig.trial;

  const buttonLabel = useMemo(
    () => (isTrialModel && purchaseMode ? t('subscription.activateTrial') : t('landingPage.pricingSection.selectPlan')),
    [isTrialModel, purchaseMode, t],
  );

  const navigateToRegister = () => {
    window.location.href = '/register';
  };
  const onBtnClick = () => {
    if (buttonLabel === t('subscription.activateTrial')) {
      onOfferSelect(model);
      onButtonClick();
    } else if (purchaseMode) {
      onButtonClick(model);
    } else {
      navigateToRegister();
    }
  };

  return (
    <div className={getModelWrapperClass({ isComing, isMobile, popular, preview })}>
      <div className={classes.row}>
        <div className={getModelTitleClass({ popular })}>{title}</div>
        {popular && <div className={classes.popularLabel}>{t('landingPage.pricingSection.popular')}</div>}
      </div>
      <div className={classes.subTitle}>{subTitle}</div>
      <div className={classes.price}>
        {`${price} PLN`}
        <span>{t('landingPage.pricingSection.monthly')}</span>
      </div>
      <a className={classes.showPlanDetails} href="/#pricing" target="_blank">
        {t('subscription.whatPlanIncludes')}
        <ArrowRightUp className={classes.icon} />
      </a>

      <PaymentMethods
        isTrial={isTrialModel}
        onOfferSelect={onOfferSelect}
        model={model}
        selectedOffer={selectedOffer}
      />

      <Button
        className={getButtonClass({ popular })}
        label={buttonLabel}
        onClick={onBtnClick}
        readOnly={model.title !== selectedOffer?.title && !isTrialModel}
      />
    </div>
  );
};

export default PurchasePriceModel;
