import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import { plans, priceModelBusinessCardTitles, priceModelSEOCardsMatrixConfig } from '../PriceModelsFeatureMatrix/utils';
import AvailabilityMobileItem from '../AvailabilityMobileItem';

const MobilePlanComparison = ({ preview = false }) => {
  const { t } = useTranslation();
  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>{t('landingPage.pricingSection.comparePlans')}</div>
      <div className={clsx(classes.matrixHeading, preview && classes.mobilePreviewHeading)}>
        {priceModelBusinessCardTitles(t).map(({ title }) => (
          <div className={classes.planName} key={title}>
            {title}
          </div>
        ))}
      </div>
      <div className={classes.featureMatrixWrapper}>
        <div className={classes.googleMatrix}>
          <div className={classes.googleBusienssCards}>{t('landingPage.pricingSection.googleBusienssCards')}</div>
          {plans(t).map((plan, index) => (
            <React.Fragment key={plan.featureTitle}>
              <div className={classes.gridItemTitle}>{plan.featureTitle}</div>
              {plan.subFeatures ? (
                plan.subFeatures.map((subFeature) => (
                  <React.Fragment key={subFeature.name}>
                    <div className={classes.gridItemSubFeature}>{subFeature.name}</div>
                    <div className={classes.wrapperMobile}>
                      <AvailabilityMobileItem availability={subFeature.availability} />
                    </div>
                  </React.Fragment>
                ))
              ) : (
                <div className={classes.wrapperMobile}>
                  <AvailabilityMobileItem availability={plan.availability} />
                </div>
              )}
            </React.Fragment>
          ))}
          <div className={classes.seoCards}>{t('landingPage.pricingSection.seoCards')}</div>
          {priceModelSEOCardsMatrixConfig(t).map((plan) => (
            <React.Fragment key={plan.featureTitle}>
              <div className={clsx(classes.gridItemTitle, classes.featureTitle, classes.cardTitle)}>
                {plan.featureTitle}
              </div>
              <div className={classes.wrapperMobile}>
                <AvailabilityMobileItem availability={plan.availability} extraClasses={classes.gridCardItem} />
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MobilePlanComparison;
