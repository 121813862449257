import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Pill from '../../../../../shared/components/Pill';
import { subscriptionsConfig } from '../../../helpers/constants';
import { getSubInfoText } from './utils';

const PruchaseSummaryPaymentDetails = ({ selectedPlan, paymentDetails }) => {
  const { t } = useTranslation();
  const isTrial = selectedPlan.title.toLowerCase() === subscriptionsConfig.trial;

  const selectPlanSubInfo = isTrial ? (
    <Trans i18nKey="subscription.trialSummarySubInfo" components={{ bold: <b /> }} />
  ) : (
    t('settings.paidSubPlanSubInfo')
  );
  return (
    <div className={classes.seletedPlanWrapper}>
      <div className={classes.row}>
        <Pill className={classes.subscriptionPill} label={t(`subscription.${selectedPlan.title.toLowerCase()}Plan`)} />
        <div className={classes.planPrice}>
          {paymentDetails?.price || 0}
          <span> PLN</span>
          <span className={classes.pricePerPeriod}>{t(paymentDetails?.priceTagShort)}</span>
        </div>
      </div>
      <div className={classes.planDetails}>
        {!isTrial && <span className={classes.subInfo}>{getSubInfoText(paymentDetails?.type)}</span>}
        <div className={classes.planSubInfo}>
          {selectPlanSubInfo}
          {!isTrial && <span className={classes.highlight}>{t('settings.afterPaymentVerification')}</span>}
        </div>
      </div>
    </div>
  );
};

export default PruchaseSummaryPaymentDetails;
