import React, { useEffect, useLayoutEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Loader from '../../../components/Loader';
import { handleProfileImages } from '../../../views/Login/utils';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { ReactComponent as ImagePlaceholder } from '../../../../../shared/assets/image.svg';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import { useDashboard } from '../../../helpers/dashboard';

const PurchaseFormBusinessProfileDetails = () => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { businessProfile } = useDashboard();
  const { showNotification } = useNotification();
  const [businessProfileImage, setBusinessProfileImage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const renderProfileImage = () => (businessProfileImage ? (
    <img className={classes.businessProfileImage} src={businessProfileImage} alt="business cover" />
  ) : (
    <ImagePlaceholder className={clsx(classes.businessProfileImage, classes.noImage)} />
  ));

  const fetchMissingImage = async (profile, controller) => {
    setIsLoading(true);
    try {
      const data = await handleProfileImages([profile], api, controller);
      setBusinessProfileImage(data[0].image);
    } catch (err) {
      handleApiError({ err, t, showNotification });
    } finally {
      setIsLoading(false);
    }
  };

  useLayoutEffect(() => {
    const controller = new AbortController();
    const profileHasImage = Boolean(businessProfile?.fileDate && businessProfile?.fileName);

    if (!businessProfile.image && profileHasImage) {
      fetchMissingImage(businessProfile, api, controller);
    } else if (businessProfile.image) {
      setBusinessProfileImage(businessProfile.image);
    }

    return () => controller.abort();
  }, [businessProfile, api]);

  if (isLoading) {
    return (
      <div className={classes.loaderWrapper}>
        <Loader />
      </div>
    );
  }
  return (
    <div className={classes.businessDetailsWrapper}>
      {renderProfileImage()}
      <div className={classes.rightSide}>
        <div className={classes.businessDetails}>
          <div className={classes.name}>{businessProfile.name}</div>
          <div className={classes.address}>{businessProfile.address}</div>
        </div>
      </div>
    </div>
  );
};

export default PurchaseFormBusinessProfileDetails;
