import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Checkbox from '../../../../../shared/components/Checkbox';
import Button from '../../../../../shared/components/Button';
import { handleApiError } from '../ChangePassword/utils';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { useModal } from '../../../../../shared/helpers/hooks';
import ConfirmDefaultInvoice from '../ConfirmDefaultInvoice';
import InvoiceModalForm from '../InvoiceModalForm';
import { ReactComponent as Bin } from '../../../../../shared/assets/bin.svg';
import { ReactComponent as Edit } from '../../../../../shared/assets/edit.svg';
import Loader from '../../../components/Loader';

const InvoiceDataItem = ({ invoiceData, onSuccess, itemsLength }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [isOpenMakeDeafultModal, setIsOpenMakeDeafultModal, toggleMakeDefaultInvoiceModal] = useModal();
  const [isOpenEditModal, setIsOpenEditModal, toggleEditModal] = useModal();
  const {
    name,
    lastName,
    email,
    secondEmail,
    country,
    address,
    city,
    postalCode,
    defaultInvoice,
    nip,
    companyName,
    phone = '',
    id,
  } = invoiceData;
  const isPersonal = !nip && !companyName;
  const handleDelete = async () => {
    setIsLoading(true);
    try {
      await api.post('/invoice/deleteUserInvoiceDetails', { id });
      onSuccess();
    } catch (err) {
      handleApiError({ err, showNotification, t });
    }
    setIsLoading(false);
  };

  return (
    <div className={clsx(classes.wrapper, defaultInvoice && classes.whiteBg)}>
      {isLoading && (
        <div className={classes.loaderWrapper}>
          <Loader />
        </div>
      )}
      {isOpenMakeDeafultModal && (
        <ConfirmDefaultInvoice onClose={toggleMakeDefaultInvoiceModal} data={invoiceData} onSuccess={onSuccess} />
      )}
      {isOpenEditModal && (
        <InvoiceModalForm
          onClose={toggleEditModal}
          onSuccess={onSuccess}
          editData={invoiceData}
          dataExist={itemsLength}
        />
      )}
      <div className={classes.row}>
        <div>
          {isPersonal ? (
            <div className={classes.invoiceTitle}>{`${name} ${lastName}`}</div>
          ) : (
            <div className={classes.invoiceTitle}>
              {`${companyName} `}
              <div>{`NIP ${nip}`}</div>
            </div>
          )}

          <div className={classes.address}>
            <div>{country}</div>
            <div>{`${postalCode} ${city}`}</div>
            <div>{address}</div>
          </div>
          <div className={classes.contactData}>
            <div>{email}</div>
            <div>{secondEmail}</div>
            <div>{phone}</div>
            <Checkbox
              checkboxStyle={clsx(classes.checkboxStyle, defaultInvoice && classes.defaultInvoiceCheckbox)}
              checkIconStyle={classes.checkIconStyle}
              className={clsx(classes.checkbox)}
              label={t(`invoices.${defaultInvoice ? 'defaultInvoice' : 'setAsDefaultInvoiceData'}`)}
              labelPosition="right"
              onClick={!defaultInvoice && toggleMakeDefaultInvoiceModal}
              value={defaultInvoice || isOpenMakeDeafultModal}
              disabled={defaultInvoice}
            />
          </div>
        </div>
        <div className={classes.actions}>
          <Button label={t('global.edit')} iconWidth={16} Icon={Edit} onClick={toggleEditModal} />
          <Button
            className={classes.deleteBtn}
            label={t('global.delete')}
            iconWidth={16}
            Icon={Bin}
            onClick={handleDelete}
          />
        </div>
      </div>
    </div>
  );
};

export default InvoiceDataItem;
