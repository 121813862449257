import React from 'react';
import Input from '../../../../../shared/components/Input';
import {
  capitalizeFirstLetter,
  capitalizeWordsAndPreserveDashesTransformer,
  companyNipTransformer,
  phoneNumberTransformer,
  postalCodeTransformer,
} from '../../../../../shared/components/Input/transformers';
import {
  alphabeticalValidator,
  companyNipValidator,
  emailValidator,
  makeRequired,
  phoneNumberValidator,
  postalCodeValidator,
  required as requiredValidator,
  streetAndNumberValidator,
  validateCityName,
} from '../../../../../shared/components/Input/validators';

const NAME_MAX_LENGTH = 30;
const MIN_LENGTH_2 = 2;
const MIN_LENGTH_3 = 3;

export const invoiceTypeEnum = {
  personal: 'personal',
  company: 'company',
};

const commonFields = (t) => [
  {
    key: 'address',
    required: true,
    label: t('form.address'),
    Component: Input,
    props: {
      transform: capitalizeFirstLetter,
      validator: makeRequired(streetAndNumberValidator, MIN_LENGTH_3),
      showErrorAfterBlur: true,
      autoCompleteName: 'address',
      maxLength: 40,
    },
  },
  {
    key: 'city',
    required: true,
    label: t('form.locality'),
    Component: Input,
    props: {
      transformer: capitalizeWordsAndPreserveDashesTransformer,
      validator: makeRequired(validateCityName, MIN_LENGTH_3),
      showErrorAfterBlur: true,
      autoCompleteName: 'city',
      maxLength: 40,
    },
  },
  {
    key: 'postalCode',
    required: true,
    label: t('form.postalCode'),
    Component: Input,
    props: {
      transformer: postalCodeTransformer,
      validator: makeRequired(postalCodeValidator),
      showErrorAfterBlur: true,
      autoCompleteName: 'postalCode',
    },
  },
  {
    key: 'country',
    required: true,
    label: t('form.country'),
    Component: Input,
    props: {
      transformer: capitalizeWordsAndPreserveDashesTransformer,
      validator: makeRequired(alphabeticalValidator, MIN_LENGTH_2),
      showErrorAfterBlur: true,
      maxLength: 20,
      autoCompleteName: 'country',
      readOnly: true,
    },
  },
  {
    key: 'phone',
    required: false,
    label: t('form.phoneOptional'),
    Component: Input,
    props: {
      transformer: phoneNumberTransformer,
      validator: phoneNumberValidator,
      showErrorAfterBlur: true,
      autoCompleteName: 'phone',
    },
  },
  {
    key: 'email',
    required: true,
    label: t('form.email'),
    Component: Input,
    props: {
      validator: makeRequired(emailValidator),
      showErrorAfterBlur: true,
      maxLength: 100,
    },
  },
];
export const buyerDataFormConfig = (t) => [
  {
    key: 'name',
    required: true,
    label: t('form.name'),
    Component: Input,
    props: {
      showErrorAfterBlur: true,
      transformer: capitalizeWordsAndPreserveDashesTransformer,
      validator: requiredValidator(MIN_LENGTH_3),
      maxLength: 30,
    },
  },
  {
    key: 'lastName',
    required: true,
    label: t('form.lastName'),
    Component: Input,
    props: {
      showErrorAfterBlur: true,
      transformer: capitalizeWordsAndPreserveDashesTransformer,
      validator: requiredValidator(MIN_LENGTH_3),
      maxLength: 30,
    },
  },
  ...commonFields(t),
];

export const companyInvoiceDataFormConfig = (t) => [
  {
    key: 'nip',
    required: true,
    label: t('form.nip'),
    Component: Input,
    props: {
      validator: makeRequired(companyNipValidator),
      showErrorAfterBlur: true,
      transformer: companyNipTransformer,
      autoCompleteName: 'nip',
    },
  },
  {
    key: 'companyName',
    required: true,
    label: t('form.companyName'),
    Component: Input,
    props: {
      validator: requiredValidator(),
      showErrorAfterBlur: true,
      autoCompleteName: 'companyName',
      maxLength: 100,
    },
  },

  ...commonFields(t),
];

export const renderInputComponent = ({
  field: {
    key,
    Component,
    label,
    props: { transformer, validator, showErrorAfterBlur, maxLength, minLength, autoCompleteName, readOnly },
  },
  form,
  onInputChange,
  onError,
}) => (
  <Component
    key={key}
    transformer={transformer}
    validator={validator}
    label={label}
    showErrorAfterBlur={showErrorAfterBlur}
    value={form[key]}
    onChange={onInputChange(key)}
    maxLength={maxLength}
    minLength={minLength}
    autoCompleteName={autoCompleteName}
    readOnly={readOnly}
    onError={onError(key)}
  />
);
