import React from 'react';
import { useTranslation } from 'react-i18next';
import Modal from '../../../../../shared/components/Modal';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';

const ConfirmDeleteModal = ({
  onClose,
  onConfirm,
  title = 'businessProfileEdit.deleteSelectedMediaConfirmation',
  subInfo = 'businessProfileEdit.deleteSelectedMediaSubInformation',
}) => {
  const { t } = useTranslation();

  return (
    <Modal contentStyle={classes.modalContent} onCancel={onClose} modalTitle={t(title)}>
      <p className={classes.text}>{t(subInfo)}</p>
      <div className={classes.actionWrapper}>
        <Button label={t('global.cancel')} onClick={onClose} className={classes.cancelButton} />
        <Button label={t('global.confirm')} onClick={onConfirm} className={classes.deleteButton} />
      </div>
    </Modal>
  );
};

export default ConfirmDeleteModal;
