import i18next from 'i18next';
import { paymentMethodEnum } from '../PaymentMethodItem/utils';

export const paymentSubInfoConfig = {
  singleMonth: 'settings.onePayment',
  fullYear: 'settings.onePayment',
  cyclicMonth: 'settings.cyclicPayment',
};

export const getSubInfoText = (paymentType) => {
  if (!paymentType) {
    return null;
  }
  const { recurring, oneTime_1, oneTime_12 } = paymentMethodEnum;
  if (paymentType === recurring) {
    return i18next.t('subscription.cyclicPaymentSummarySubInfo');
  }
  if (paymentType === oneTime_1) {
    return i18next.t('subscription.oneMonthSummarySubInfo');
  }
  if (paymentType === oneTime_12) {
    return i18next.t('subscription.wholeYearSummarySubInfo');
  }
  return null;
};
