import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import classes from './styles.module.css';
import Input from '../../../../../shared/components/Input';
import { plannedPostValidator } from '../../../../../shared/components/Input/validators';
import { formEnum } from '../ModalForm/utils/utils';

const PlannedPost = ({
  onPublicationDateChange,
  data = { type: null, publishDate: null, localPost: null },
  media = false,
  plannedPublishDate,
}) => {
  const { t } = useTranslation();
  const { publishDate, type, localPost } = data;
  const isFormWithDateInput = [formEnum.EVENT, formEnum.OFFER].includes(type);
  const [plannedPublicationDate, setPlannedPublicationDate] = useState({ date: '', time: '09:00' });
  const [plannedDateError, setPlannedDateError] = useState(false);

  const formatDate = (date) => dayjs(date, 'YYYY-MM-DD');

  const getEndDate = () => {
    const endDateArray = localPost?.event?.schedule?.endDate;
    return endDateArray ? Object.values(endDateArray).join('-') : null;
  };

  const isDateAfterOrSame = (date1, date2) => {
    const formattedDate1 = dayjs(date1);
    const formattedDate2 = formatDate(date2);
    return formattedDate1.isAfter(formattedDate2) || formattedDate1.isSame(formattedDate2);
  };

  const isPlannedAfterEnd = isFormWithDateInput && isDateAfterOrSame(plannedPublicationDate.date, getEndDate(localPost));
  const displayLocalPostError = isFormWithDateInput && isPlannedAfterEnd && !media;
  const displayUpdatePostError = !isFormWithDateInput && plannedDateError;

  const onDateChange = (name) => (value) => {
    setPlannedPublicationDate((prev) => ({ ...prev, [name]: value }));
  };

  const validateAndSetPublicationDate = () => {
    const fullDate = `${plannedPublicationDate.date} ${plannedPublicationDate.time}`;
    const dayjsFullDateIsValid = dayjs(fullDate).isValid();
    if (dayjsFullDateIsValid) {
      onPublicationDateChange(fullDate);
    } else {
      onPublicationDateChange(null);
    }
    setPlannedDateError(plannedPostValidator(publishDate));
  };

  const updatePlannedPublicationDate = () => {
    const plannedPublicationDateValue = publishDate || plannedPublishDate;
    if (plannedPublicationDateValue && dayjs(plannedPublicationDateValue).isValid()) {
      const correctDate = dayjs(plannedPublicationDateValue).format('YYYY-MM-DD');
      const correctTime = dayjs(plannedPublicationDateValue).format('HH:mm');
      setPlannedPublicationDate({ date: correctDate, time: correctTime });
    }
  };

  useEffect(validateAndSetPublicationDate, [plannedPublicationDate, publishDate]);
  useEffect(updatePlannedPublicationDate, [publishDate, media, plannedPublishDate]);

  return (
    <div>
      <div className={classes.plannedBox}>
        <Input
          className={classes.date}
          label={t('posts.publicationDateOptional')}
          type="date"
          onChange={onDateChange('date')}
          labelRised
          value={plannedPublicationDate.date}
        />
        {plannedPublicationDate.date && (
          <Input
            className={classes.time}
            label={t('posts.publicationTimeOptional')}
            type="time"
            onChange={onDateChange('time')}
            labelRised
            value={plannedPublicationDate.time}
          />
        )}
      </div>
      {Boolean(displayLocalPostError) && <div className={classes.error}>{t('posts.publicationDateAfterEndDate')}</div>}
      {Boolean(displayUpdatePostError || plannedDateError) && (
        <div className={classes.error}>{t('businessProfileEdit.postoponedMediaMustBeAfterToday')}</div>
      )}
    </div>
  );
};
export default PlannedPost;
