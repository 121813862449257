import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import Modal from '../../../../../shared/components/Modal';

const DeleteProfileModal = ({ onConfirm, onCancel, show, selectedBusinessProfiles }) => {
  const { t } = useTranslation();

  return (
    <Modal onCancel={onCancel} contentStyle={classes.content} modalTitle={t('settings.deleteProfile')}>
      <div className={classes.modalWrapper}>
        <div className={classes.modalContent}>
          {t('settings.deleteProfilesText', { number: selectedBusinessProfiles.length })}
        </div>
        <div className={classes.modalActions}>
          <Button onClick={onCancel} className={classes.cancelBtn} label={t('global.cancel')} />
          <Button onClick={onConfirm} className={classes.confirmBtn} label={t('settings.delete')} />
        </div>
      </div>
    </Modal>
  );
};
export default DeleteProfileModal;
