import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import AnswerWithTemplateModal from '../AnswerWithTemplateModal';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { StateEnum } from '../../../../../shared/helpers/state';
import Loader from '../../../components/Loader';
import OpinionItem from '../OpinionItem';
import { handleApiError } from '../../Settings/ChangePassword/utils';

const OpinionsItems = ({
  businessOpinions = [],
  fetchOpinions,
  businessProfile,
  selectAllowed,
  onSelectReviewHandler,
  selectedReviews,
  adminPreview,
  profileImage,
}) => {
  const { api } = useApi();
  const { showNotification } = useNotification();
  const { t } = useTranslation();
  const [activeOpinionId, setActiveOpinionId] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState('');
  const [replyEditMode, setReplyEditMode] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [state, setState] = useState(StateEnum.initializing);
  const [showModal, setShowModal] = useState(false);

  const textareaVisibilityHandler = (id) => {
    setActiveOpinionId(id);
  };
  const modalHandler = (id) => {
    textareaVisibilityHandler(id);

    setShowModal((prev) => !prev);
  };

  const deleteReplyHandler = async (opinion) => {
    setState(StateEnum.loading);
    try {
      const { data } = await api.post('/review/deleteReply', {
        businessProfileId: businessProfile.id,
        reviewId: `reviews/${opinion.id}`,
      });
      showNotification({
        label: t('global.success'),
        message: t('opinions.savedCorrectly'),
        payload: null,
        type: 'success',
      });
      await fetchOpinions();
    } catch (err) {
      handleApiError({ err, showNotification, t });
    }
    setState(StateEnum.success);
  };

  const fetchTemplates = async () => {
    const controller = new AbortController();
    setState(StateEnum.loading);
    try {
      const { data } = await api.post(
        '/review_reply_template/getTemplates',
        { orderBy: 'created_at', order: 'DESC' },
        { signal: controller.signal },
      );
      setTemplates(data.templates);
    } catch (err) {
      setState(StateEnum.error);
      handleApiError({ err, showNotification, t });
    } finally {
      setState(StateEnum.success);
    }

    return () => controller.abort();
  };

  useEffect(() => {
    if (showModal) {
      fetchTemplates();
    }
  }, [showModal]);

  const onTemplateSelect = (val) => {
    setSelectedTemplate(val);
    modalHandler(activeOpinionId);
  };

  const onReplyClick = (id) => {
    if (activeOpinionId === id) {
      setActiveOpinionId(null);
      return;
    }
    setSelectedTemplate('');
    setReplyEditMode(false);
    setActiveOpinionId(id);
  };

  const onEditClick = (opinion) => {
    setActiveOpinionId(opinion.id);
    setReplyEditMode(true);
    setSelectedTemplate(opinion.nativeReply);
  };

  return (
    <div className={classes.container}>
      {showModal && state === StateEnum.success && (
        <AnswerWithTemplateModal
          onClick={(val) => onTemplateSelect(val)}
          templates={templates}
          onClose={modalHandler}
        />
      )}
      {state === StateEnum.loading && (
        <div className={classes.overlay}>
          <Loader className={classes.loader} />
        </div>
      )}
      {businessOpinions.map((opinion) => (
        <OpinionItem
          key={opinion.id}
          opinion={opinion}
          deleteReplyHandler={deleteReplyHandler}
          onEditClick={onEditClick}
          selectedTemplate={selectedTemplate}
          replyEditMode={replyEditMode}
          activeOpinionId={activeOpinionId}
          fetchOpinions={fetchOpinions}
          modalHandler={modalHandler}
          onReplyClick={onReplyClick}
          textareaVisibilityHandler={textareaVisibilityHandler}
          businessProfile={businessProfile}
          selectAllowed={selectAllowed}
          onSelectReviewHandler={onSelectReviewHandler}
          selectedReviews={selectedReviews}
          adminPreview={adminPreview}
          profileImage={profileImage}
        />
      ))}
    </div>
  );
};

export default OpinionsItems;
