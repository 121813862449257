import React, { useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import Button from '../Button';
import SidebarUserMenu from '../SidebarUserMenu';
import Pill from '../Pill';
import { ReactComponent as BurgerMenu } from '../../assets/menu.svg';
import { ReactComponent as ChevronDown } from '../../assets/chevronDown.svg';
import { ReactComponent as SEOCard } from '../../assets/creditCard.svg';
import { ReactComponent as Notification } from '../../assets/bell.svg';
import { ReactComponent as User } from '../../assets/user.svg';
import { ReactComponent as Logo } from '../../assets/logoIcon.svg';
import { useAuth } from '../../helpers/auth';

const SidebarHeader = ({ businessProfile, onMobileMenuClick, showBusinessCards, showList }) => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const settingsView = pathname === '/dashboard/settings';

  const navigate = useNavigate();
  const { businessProfiles } = useAuth();
  const [showUserMenu, setShowUserMenu] = useState(false);
  const { address, image, name, subscription, id } = businessProfile;
  const isLogoDisplayed = !businessProfiles.length && settingsView;
  const navigateToCardList = () => {
    navigate('/dashboard/cards');
  };

  const toggleUserMenu = () => {
    setShowUserMenu((prev) => !prev);
  };

  return (
    <div className={classes.wrapper}>
      {showUserMenu && <SidebarUserMenu toggleUserMenu={toggleUserMenu} />}
      <div className={classes.rowContainer}>
        <div className={classes.leftSide}>
          {isLogoDisplayed && <Logo className={classes.logo} onClick={() => navigate('/dashboard')} />}
          <Button
            label={t('dashboard.opinieoCard')}
            Icon={SEOCard}
            className={classes.seoCardsBtn}
            onClick={navigateToCardList}
          />
        </div>
        <div className={classes.rightSide}>
          <div className={classes.notificationIconWrapper}>
            <Notification className={classes.icon} />
          </div>
          <div onClick={toggleUserMenu} className={classes.userIconWrapper}>
            <User className={classes.userIcon} />
          </div>
        </div>
      </div>
      {Boolean(businessProfiles.length) && (
        <div className={classes.header}>
          <BurgerMenu onClick={onMobileMenuClick} className={classes.menu} />
          <div className={classes.info}>
            {image && <img src={image} alt="business profile" className={classes.image} />}
            <div className={classes.businessDetails}>
              <Pill className={classes.subscriptionPill} label={t(`subscription.${subscription}Plan`)} />
              <div className={classes.name}>{name}</div>
              <div className={classes.address}>{address}</div>
            </div>
          </div>
          <ChevronDown
            onClick={showList}
            className={clsx(classes.chevronIconTopBar, showBusinessCards && classes.invertedIcon)}
          />
        </div>
      )}
    </div>
  );
};

export default SidebarHeader;
