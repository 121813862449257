import React from 'react';
import clsx from 'clsx';
import classes from './styles.module.css';
import Pill from '../Pill';

const HeaderWithPill = ({ className, pillLabel, title, total }) => (
  <div className={clsx(classes.wrapper, className)}>
    <div className={classes.headerTitle}>{title}</div>
    {Boolean(total) && <Pill className={classes.pill} border={false} label={pillLabel} />}
  </div>
);

export default HeaderWithPill;
