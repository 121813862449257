import React from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useDashboard } from '../../../helpers/dashboard';
import classes from './styles.module.css';
import { ReactComponent as LogoIcon } from '../../../../../shared/assets/logoIcon.svg';
import { ReactComponent as Check } from '../../../../../shared/assets/checkCircle.svg';
import Pill from '../../../../../shared/components/Pill';
import Button from '../../../../../shared/components/Button';
import { useApi } from '../../../../../shared/helpers/api';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import { useNotification } from '../../../../../shared/helpers/notification';

const SubscriptionDetails = () => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const { businessProfile } = useDashboard();
  const { subscription, subscriptionDate, id, subscriptionBuyDate } = businessProfile;

  const PLAN_TITLE = t('subscription.yourPlan');
  const ACTIVE_PLAN_STATUS = t('subscription.activePlanStatus');
  const EXPIRE_DATE = t('subscription.expireDate');
  const ACTIVATION_DATE = t('subscription.activationDate');
  const PRICE = '0 PLN';

  const desactivateTrialDEVONLY = async () => {
    try {
      await api.post('/subscription/deleteTrial', { businessProfileId: id });
    } catch (err) {
      handleApiError({ err, showNotification, t });
    }
  };

  return (
    <div className={classes.wrapper}>
      <div className={classes.headerWrapper}>
        <div className={classes.leftSideRow}>
          <LogoIcon className={classes.logoIcon} />
          <div>
            <div className={classes.planDetails}>
              <div className={classes.title}>{PLAN_TITLE}</div>
              <Pill className={classes.subscriptionPill} label={subscription} />
            </div>
            <div className={classes.statusWrapper}>
              <Check className={classes.checkIcon} />
              <span className={classes.planStatusInfo}>{ACTIVE_PLAN_STATUS}</span>
            </div>
          </div>
        </div>
        <div className={classes.rightSideRow}>
          <div className={classes.priceTag}>
            {PRICE}
            <span>{t('subscription.perMonthShort')}</span>
          </div>
        </div>
      </div>
      <div className={classes.dateWrapper}>
        {subscriptionBuyDate && (
          <div className={classes.date}>
            {ACTIVATION_DATE}
            <span>{dayjs(subscriptionBuyDate).format('DD/MM/YYYY')}</span>
          </div>
        )}
        <div className={classes.date}>
          {EXPIRE_DATE}
          <span>{dayjs(subscriptionDate).format('DD/MM/YYYY')}</span>
        </div>
      </div>
      {/* DEV ONLY _ TODO */}
      <div className={classes.desactivateTrialWrapper}>
        <p>UWAGA! Przycisk tymczasowy</p>
        <Button label="Wyłącz subskrypcje" onClick={desactivateTrialDEVONLY} />
      </div>
    </div>
  );
};

export default SubscriptionDetails;
