import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import AIHelper from '../AIHelper';
import Textarea from '../../../../../shared/components/Textarea/index';
import Select from '../../../../../shared/components/Select/index';
import Input from '../../../../../shared/components/Input';
import SubInformation from '../../../../../shared/components/SubInformation';
import { required, updateDescriptionValidator } from '../../../../../shared/components/Input/validators';
import { buttonActions, buttonActionsEnum } from '../ModalForm/utils/changeDataFieldHandles';
import { filterTranslations } from './utils';
import { GOOGLE_POST_URL_MAX_LENGTH } from '../../../helpers/constants';
import { POST_STATE_ENUM } from '../ModalForm/utils/utils';
import MediaAndPublication from '../MediaAndPublication';
import { AUTO_COMPLETE_TYPES } from '../../../../../shared/helpers/const';

const UpdateForm = ({
  handleForm,
  data,
  handleMedia,
  handlePublicationDate,
  editPost,
  aiHelperHandler,
  isAIHelperOpen,
  businessPhoneNumber,
}) => {
  const { localPost } = data;
  const { t } = useTranslation();
  const callToActionsList = businessPhoneNumber?.phone
    ? buttonActions
    : buttonActions.filter((el) => el.googleID !== buttonActionsEnum.CALL);

  const [selectedButton, setSelectedButton] = useState(callToActionsList[0]);

  const shouldRenderURLInput = selectedButton
    && ![buttonActionsEnum.ACTION_TYPE_UNSPECIFIED, buttonActionsEnum.CALL].includes(selectedButton.googleID)
    && !isAIHelperOpen;

  const isEditingPublishedPost = Boolean(editPost && editPost.state !== POST_STATE_ENUM.PLANNED);

  const handleSelectCallToAction = (value) => {
    const pickedValue = buttonActions.find((el) => el.id === value);
    setSelectedButton(pickedValue);
    handleForm('callToAction')(pickedValue?.googleID);
  };

  const showOnList = useCallback((el) => (el ? t(el.name) : ''), []);

  const filterTranslationsHandler = useCallback(({ list, value }) => filterTranslations(list, value, t), []);

  useEffect(() => {
    const doesCallToActionExist = Boolean(localPost?.callToAction);
    const selectedButtonFromData = buttonActions.find((el) => el.googleID === localPost?.callToAction?.actionType);
    setSelectedButton(doesCallToActionExist ? selectedButtonFromData : buttonActions[0]);
  }, [data]);

  return (
    <div className={classes.body}>
      <div className={classes.aiWrapper}>
        <div className={clsx(classes.aiComponent, isAIHelperOpen && classes.aiComponentStatic)}>
          <AIHelper
            onApprove={handleForm('summary')}
            onClick={aiHelperHandler}
            isOpen={isAIHelperOpen}
            formType={data.type}
          />
        </div>
        {!isAIHelperOpen && (
          <Textarea
            label={t('posts.addUpdateContent')}
            onChange={handleForm('summary')}
            maxLength={1500}
            className={classes.addDescription}
            value={localPost?.summary}
            validator={updateDescriptionValidator}
            showErrorAfterBlur
            type="text"
          />
        )}
      </div>
      {!isAIHelperOpen && (
        <Select
          showOnList={showOnList}
          onSelect={handleSelectCallToAction}
          value={selectedButton}
          list={callToActionsList}
          label={t('posts.addOptionalButton')}
          filterList={filterTranslationsHandler}
        />
      )}
      {shouldRenderURLInput && (
        <div className={classes.buttonDetailContainer}>
          <Input
            value={localPost?.callToAction?.url}
            validator={selectedButton.validator || required(null)}
            onChange={handleForm('url')}
            label={t(selectedButton?.detailLabel)}
            className={classes.buttonDetail}
            transformer={selectedButton.transformer}
            showErrorAfterBlur
            type={selectedButton.type}
            maxLength={GOOGLE_POST_URL_MAX_LENGTH}
            autoCompleteName={AUTO_COMPLETE_TYPES.URL}
          />
          <SubInformation label={t(selectedButton.subInformation)} />
        </div>
      )}
      <MediaAndPublication
        data={data}
        handleMedia={handleMedia}
        handlePublicationDate={handlePublicationDate}
        isEditingPublishedPost={isEditingPublishedPost}
        shouldRenderItems={!isAIHelperOpen}
      />
    </div>
  );
};

export default UpdateForm;
