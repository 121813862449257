import { useEffect, useState } from 'react';
import { use } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useApi } from './api';
import { useNotification } from './notification';
import { handleApiError } from '../../app/SEO/modules/Settings/ChangePassword/utils';
import { parseProfileStatus } from '../../app/SEO/components/DashboardLayout/utils';

export const useModal = (initialValue = false) => {
  const [showModal, setShowModal] = useState(initialValue);
  const modalHandler = () => setShowModal((prev) => !prev);

  return [showModal, setShowModal, modalHandler];
};

export const useWindowResize = () => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  const onResizeHandler = () => {
    setInnerWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', onResizeHandler);

    return () => {
      window.removeEventListener('resize', onResizeHandler);
    };
  }, []);

  return innerWidth;
};

export const useKeyDown = ({ key, callback, canInvoke = true }) => {
  useEffect(() => {
    const onKeyDownHandler = (e) => {
      const triggerCallback = key === e.key && canInvoke;
      if (triggerCallback) {
        callback();
      }
    };

    window.addEventListener('keydown', onKeyDownHandler);

    return () => {
      window.removeEventListener('keydown', onKeyDownHandler);
    };
  }, [key, callback, canInvoke]);
};

export const useFetchData = () => {
  const { api } = useApi();
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);

  const fetchData = async (url, body = null, config = null) => {
    setIsLoading(true);
    try {
      const { data: apiData } = await api.post(url, body, config);
      setData(apiData);
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  };

  return { fetchData, isLoading, data };
};

export const useCloseOnOutsideClick = (ref, callback) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, callback]);
};

export const useIsMobile = (minWidth) => {
  const innerWidth = useWindowResize();
  return innerWidth <= minWidth;
};

export const useGetVerificationStatus = (selectedBusinessProfile) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const [requestStatus, setRequestStatus] = useState({ loading: false, error: false });
  const [businessProfileVerificationStatus, setBusinessProfileVerificationStatus] = useState(null);

  const selectBPVerificationStatus = () => {
    if (!selectedBusinessProfile || !selectedBusinessProfile.id) {
      return null;
    }
    const statusInLocalStorage = localStorage.getItem(`status-${selectedBusinessProfile.id}`);
    if (statusInLocalStorage) {
      setBusinessProfileVerificationStatus(statusInLocalStorage);
      return statusInLocalStorage;
    }
    return null;
  };

  useEffect(() => {
    if (!selectedBusinessProfile || !selectedBusinessProfile.id) {
      setRequestStatus({ loading: false, error: true });
      return;
    }

    const status = selectBPVerificationStatus();
    const controller = new AbortController();

    const fetchStatus = async () => {
      setRequestStatus({ loading: true, error: false });
      try {
        const { data } = await api.post(
          '/business_profile/getVerificationStatus',
          {
            businessProfileId: selectedBusinessProfile.id,
          },
          {
            signal: controller.signal,
          },
        );
        const parsedStatus = parseProfileStatus(data, t);
        setBusinessProfileVerificationStatus(parsedStatus.id);
        localStorage.setItem(`status-${selectedBusinessProfile.id}`, parsedStatus.id);
        setRequestStatus({ loading: false, error: false });
      } catch (err) {
        if (err.name !== 'AbortError') {
          setRequestStatus({ loading: false, error: true });
          handleApiError({ err, showNotification, t });
        }
      }
    };

    if (!status) {
      fetchStatus();
    }
  }, [selectedBusinessProfile, api, showNotification, t]);

  return { requestStatus, businessProfileVerificationStatus };
};

export const useVerificationStatuses = (profiles, t, showNotification) => {
  const { api } = useApi();
  const [statuses, setStatuses] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const controller = new AbortController();

    const fetchStatus = async (item) => {
      try {
        const { data: verificationData } = await api.post(
          '/business_profile/getVerificationStatus',
          {
            businessProfileId: item.id,
          },
          { signal: controller.signal },
        );
        const parsedStatus = parseProfileStatus(verificationData, t);
        localStorage.setItem(`status-${item.id}`, parsedStatus.id);
        return parsedStatus.id;
      } catch (err) {
        if (err.name !== 'AbortError') {
          handleApiError({ err, showNotification, t });
        }
        return null;
      }
    };

    const fetchStatuses = async () => {
      setIsLoading(true);
      try {
        const statusesArray = await Promise.all(
          profiles.map(async (profile) => {
            const statusInStorage = localStorage.getItem(`status-${profile.id}`);
            return {
              id: profile.id,
              status: statusInStorage || (await fetchStatus(profile)),
            };
          }),
        );

        const reducedStatuses = statusesArray.reduce((acc, { id, status }) => {
          acc[id] = status;
          return acc;
        }, {});

        setStatuses(reducedStatuses);
      } finally {
        setIsLoading(false);
      }
    };

    fetchStatuses();
    return () => {
      controller.abort();
    };
  }, [profiles, api, t, showNotification]);

  return { statuses, statusLoading: isLoading };
};
