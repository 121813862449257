import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';

const ProgressiveImage = ({ lowQualitySrc, highQualitySrc, alt, crossOrigin }) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <div className={classes.progressiveImageContainer}>
      <img
        src={lowQualitySrc}
        alt={alt}
        className={`${classes.lowQuality} ${loaded ? classes.hidden : ''}`}
        crossOrigin={crossOrigin}
      />

      <img
        src={highQualitySrc}
        alt={alt}
        className={`${classes.highQuality} ${loaded ? classes.visible : classes.hidden}`}
        onLoad={() => setLoaded(true)}
        crossOrigin={crossOrigin}
      />
    </div>
  );
};

export default ProgressiveImage;
