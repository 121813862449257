import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import Input from '../../../../../shared/components/Input';
import Button from '../../../../../shared/components/Button';
import { emailValidator, makeRequired } from '../../../../../shared/components/Input/validators';
import { AUTO_COMPLETE_TYPES } from '../../../../../shared/helpers/const';

const requiredMail = makeRequired(emailValidator);

const RemindPasswordForm = ({ submitRestetPasswordForm, onMailChange, userMail }) => {
  const navigate = useNavigate();

  const { t } = useTranslation();
  const [error, setError] = useState();

  const goBackHandler = () => {
    navigate(-1);
  };

  const handleError = (err) => {
    setError(Boolean(err));
  };

  return (
    <>
      <div className={classes.title}>{t('global.restorePassword')}</div>
      <div className={classes.subTitle}>{t('adminControlPanel.remindPasswordSubtitle')}</div>
      <div className={classes.inputTitle}>{t('global.email')}</div>
      <Input
        admin
        validator={requiredMail}
        showErrorAfterBlur
        labelRised
        value={userMail}
        placeholder={t('global.passEmailShort')}
        onChange={onMailChange}
        onError={handleError}
        className={classes.input}
        autoCompleteName={AUTO_COMPLETE_TYPES.EMAIL}
      />
      <div className={classes.actions}>
        <Button
          onClick={submitRestetPasswordForm}
          readOnly={error}
          className={classes.recoverPasswordBtn}
          label={t('global.goAhead')}
        />
        <Button className={classes.goBackBtn} label={t('global.goBack')} onClick={goBackHandler} />
      </div>
    </>
  );
};

export default RemindPasswordForm;
