import { ERROR_LIST_ENUM } from '../../helpers/constants';
import { handleApiError } from '../../modules/Settings/ChangePassword/utils';

export const parseBusinessProfiles = ({ data }) => data?.map(({ storefrontAddress, title: name, name: id }) => {
  const address = storefrontAddress
    ? `${storefrontAddress.addressLines?.join(' ') || ''} ${storefrontAddress.postalCode || ''} ${
      storefrontAddress.locality || ''
    }`.trim()
    : null;

  return { name, id, address };
});

export const StateEnum = {
  success: 'success',
  error: 'error',
  loading: 'loading',
};

export const fetchBusinessProfiles = async (api, controller, token, accountId) => {
  try {
    const {
      data: { locations, pageToken },
    } = await api.post(
      '/business_profile/getBusinessProfiles',
      { accountName: accountId, pageSize: 100, pageToken: token },
      { signal: controller.signal },
    );
    const parsedBusinessProfiles = parseBusinessProfiles({ data: locations });
    return { pageToken, parsedBusinessProfiles };
  } catch (err) {
    if (err.message !== 'canceled') {
      return { error: err };
    }
    throw err;
  }
};
export const fetchAccounts = async (api, controller, setStatus = () => {}) => {
  try {
    setStatus(StateEnum.loading);
    const { data: locations } = await api.post('/google_auth/getAccounts', {}, { signal: controller.signal });
    return locations.map((el) => ({ ...el, id: el.name, name: el.accountName }));
  } catch (err) {
    if (err.message !== 'canceled') {
      setStatus(StateEnum.error);
    }
    throw err;
  } finally {
    setStatus(StateEnum.success);
  }
};

export const fetchAccountsAndProfiles = async (api, controller, setStatus = () => {}) => {
  try {
    setStatus(StateEnum.loading);
    const userLocations = await fetchAccounts(api, controller, setStatus);
    const defaultAccountId = userLocations[0].id;
    const { parsedBusinessProfiles, error, pageToken } = await fetchBusinessProfiles(
      api,
      controller,
      '',
      defaultAccountId,
    );
    setStatus(StateEnum.success);
    return { userLocations, defaultAccountId, parsedBusinessProfiles, pageToken, error };
  } catch (err) {
    if (err.message !== 'canceled') {
      setStatus(StateEnum.error);
    }
    throw err;
  } finally {
    setStatus(StateEnum.success);
  }
};

export const attachProfilesHandler = async ({ api, selectedBusinessProfiles, setStatus, showNotification, t }) => {
  try {
    setStatus(StateEnum.loading);
    await api.post('/business_profile/attachBusinessProfiles', { businessProfiles: selectedBusinessProfiles });
  } catch (err) {
    handleApiError({ err, showNotification, t });
  } finally {
    setStatus(StateEnum.success);
  }
};

export const handleChangeAccount = async (api, value, setStatus) => {
  try {
    setStatus(StateEnum.loading);
    const {
      data: { locations, pageToken },
    } = await api.post('/business_profile/getBusinessProfiles', { accountName: value, pageSize: 100 });
    const parsed = parseBusinessProfiles({ data: locations });
    return { parsed, pageToken };
  } catch (err) {
    return { error: err };
  } finally {
    setStatus(StateEnum.success);
  }
};

export const getCheckboxValue = ({ list, selectedBusinessProfiles, maxProfiles }) => {
  const importLimitReached = list.length > maxProfiles;
  const hasMaxBusinessProfiles = selectedBusinessProfiles.length === maxProfiles;
  const allBusinessProfilesSelected = selectedBusinessProfiles.length === list.length && Boolean(selectedBusinessProfiles.length);

  return importLimitReached ? hasMaxBusinessProfiles : allBusinessProfilesSelected;
};
