export const paymentMethodConfig = {
  mini: {
    one_time_1: {
      price: 49,
      priceTag: 'subscription.perMonth',
      priceTagShort: 'subscription.perMonthShort',
    },
    one_time_12: {
      price: 49 * 10,
      priceTag: 'subscription.perYear',
      subInfo: 'subscription.twoMonthsGratis',
      priceTagShort: 'subscription.perYearShort',
    },
    recurring: {
      price: 49,
      priceTag: 'subscription.perMonth',
      priceTagShort: 'subscription.perMonthShort',
    },
  },
  basic: {
    one_time_1: {
      price: 119,
      priceTag: 'subscription.perMonth',
      priceTagShort: 'subscription.perMonthShort',
    },
    one_time_12: {
      price: 119 * 10,
      priceTag: 'subscription.perYear',
      subInfo: 'subscription.twoMonthsGratis',
      priceTagShort: 'subscription.perYearShort',
    },
    recurring: {
      price: 119,
      priceTag: 'subscription.perMonth',
      priceTagShort: 'subscription.perMonthShort',
    },
  },
  pro: {
    one_time_1: {
      price: 159,
      priceTag: 'subscription.perMonth',
      priceTagShort: 'subscription.perMonthShort',
    },
    one_time_12: {
      price: 159 * 10,
      priceTag: 'subscription.perYear',
      subInfo: 'subscription.twoMonthsGratis',
      priceTagShort: 'subscription.perYearShort',
    },
    recurring: {
      price: 159,
      priceTag: 'subscription.perMonth',
      priceTagShort: 'subscription.perMonthShort',
    },
  },
};

export const paymentMethodEnum = {
  recurring: 'recurring',
  oneTimeForYear: 'one_time_12',
  oneTimeForMonth: 'one_time_1',
};
