import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Filters from '../../modules/ReportList/Filters';
import List from '../../../../shared/components/List';
import { config } from './utils';
import Pagination from '../../../../shared/components/Pagination';
import Loader from '../../components/Loader';
import { useNotification } from '../../../../shared/helpers/notification';
import { useApi } from '../../../../shared/helpers/api';
import { StateEnum } from '../../../../shared/helpers/state';
import Button from '../../../../shared/components/Button';
import RWDTable from '../../modules/ReportList/RWDTable';
import { parseAddressToStorefront } from '../../../../shared/helpers/parsers';
import HeaderWithPill from '../../../../shared/components/HeaderWithPill';
import { handleApiError } from '../../modules/Settings/ChangePassword/utils';
import { useDashboard } from '../../helpers/dashboard';
import { readOnlySubList } from '../../helpers/constants';

const ReportList = ({ className, adminPreview, businessProfileId, userId, onClick }) => {
  const controller = useRef(null);
  const [state, setState] = useState(StateEnum.initializing);
  const [total, setTotal] = useState(0);
  const [requestPayload, setRequestPayload] = useState({ filters: {} });
  const [data, setData] = useState([]);
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const { businessProfile } = useDashboard();
  const { subscription, availableReports } = businessProfile;
  const isReadOnlySubPlan = readOnlySubList.includes(subscription);
  const isGenerateReportDisplayed = !isReadOnlySubPlan && !adminPreview;

  const fetch = async (payload) => {
    try {
      const apiPath = adminPreview ? '/acp/client/getReportList' : '/report/getReportList';
      const adminPayload = { ...payload, filters: { ...payload.filters, businessProfileId }, userId };
      const bodyPayload = adminPreview ? adminPayload : payload;
      const { data: result } = await api.post(apiPath, bodyPayload, { signal: controller.current.signal });
      setData(result.list);
      setTotal(result.total);
      setState(StateEnum.success);
    } catch (err) {
      if (err.message !== 'canceled') {
        setState(StateEnum.error);
        handleApiError({ err, showNotification, t });
      }
    }
  };

  const handleSort = (key) => {
    setRequestPayload((prev) => {
      const order = prev.orderBy === key ? prev.order : 'DESC';
      return { ...prev, orderBy: key, order: order === 'ASC' ? 'DESC' : 'ASC' };
    });
  };

  const handlePage = (value) => {
    setRequestPayload((prev) => ({ ...prev, offset: (value - 1) * prev.limit }));
  };

  const handleCheck = (id) => {
    setData((prev) => {
      const result = prev.map((el) => {
        if (el.id === id) {
          return { ...el, checked: !el.checked };
        }
        return el;
      });
      return result;
    });
  };

  const generateReport = async ({ id }) => {
    const { data: result } = await api.post(
      '/report/generateReportPdf',
      { reportId: id, businessProfileId: businessProfile.id },
      { signal: controller.signal },
    );
    const { date, fileName } = result;
    return { date, fileName };
  };

  const getReportFileAndDownload = async ({ id, date, fileName }) => {
    const { data: pdf } = await api.get(`/report/getReportPdf/${date}/${fileName}`, {
      responseType: 'arraybuffer',
      responseEncoding: 'binary',
    });
    const url = window.URL.createObjectURL(new Blob([pdf], { type: 'application/pdf' }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `Raport_${id}`);
    document.body.appendChild(link);
    link.click();
  };

  const handleDownload = async (id) => {
    try {
      setState(StateEnum.loading);
      const { date, fileName } = await generateReport({ id });
      await getReportFileAndDownload({ id, date, fileName });
      setState(StateEnum.success);
    } catch (err) {
      setState(StateEnum.error);
      handleApiError({ err, showNotification, t });
    }
  };

  const handleAction = (key, value, row) => {
    if (key === 'checked') {
      handleCheck(row.id);
    } else if (key === 'download') {
      handleDownload(row.id);
    }
  };

  const handleClick = useCallback(
    (row) => {
      const { area, distance, net, keyword, address } = row;

      const params = {
        area: area / 1000,
        distance: distance / 1000,
        net,
        reportId: row.id,
        businessProfileId: businessProfile.id || businessProfileId,
        keyword,
      };
      if (address) {
        params.storefrontAddress = parseAddressToStorefront(address);
      }

      const search = new URLSearchParams();
      Object.entries(params).forEach(([key, value]) => {
        if (key === 'storefrontAddress' && value && typeof value === 'object' && !Array.isArray(value)) {
          Object.entries(value).forEach(([subKey, subValue]) => {
            search.set(subKey, subValue);
          });
        } else {
          search.set(key, value);
        }
      });
      if (adminPreview) {
        onClick(search.toString());
      } else {
        navigate({
          pathname: '/report',
          search: search.toString(),
        });
      }
    },
    [navigate, businessProfile.id, businessProfileId, adminPreview, onClick],
  );

  const handleFilters = (filters) => {
    setRequestPayload((prev) => ({ ...prev, offset: 0, filters }));
  };
  const onLimitChange = (value) => {
    setRequestPayload((prev) => ({ ...prev, limit: value, offset: 0 }));
  };
  const navigateToGenerateReport = () => {
    navigate({
      pathname: '/set_filters',
      search: `?businessProfileId=${businessProfile.id}`,
    });
  };

  const page = (() => {
    const { offset, limit } = requestPayload;
    return offset === 0 ? 1 : Math.round(offset / limit) + 1;
  })();

  useEffect(() => {
    controller.current = new AbortController();

    const currentPayload = {
      limit: 10,
      offset: 0,
      filters: { businessProfileId: businessProfile.id },
      orderBy: 'created_at',
      order: 'DESC',
    };
    setRequestPayload(currentPayload);
    fetch(currentPayload);

    return () => {
      controller.current.abort();
    };
  }, []);

  useEffect(() => {
    if (state !== StateEnum.initializing) {
      setState(StateEnum.loading);
      fetch(requestPayload);
    }
  }, [requestPayload]);

  useEffect(() => {
    if (!availableReports && !isReadOnlySubPlan && state === StateEnum.success && !adminPreview) {
      showNotification({ message: t('setFilters.noReportsAvailable'), type: 'warning' });
    }
  }, [availableReports, isReadOnlySubPlan, state, adminPreview]);

  return (
    <div className={clsx(classes.wrapper, className, adminPreview && classes.adminPreview)}>
      <div className={classes.header}>
        <HeaderWithPill
          className={classes.header}
          pillLabel={t('reportList.headerPill', { count: total })}
          title={t('reportList.header')}
        />
        {isGenerateReportDisplayed && (
          <Button
            onClick={navigateToGenerateReport}
            className={classes.newReportBtn}
            label={t('reportList.generateReport')}
            readOnly={availableReports === 0}
          />
        )}
      </div>
      {(availableReports || isReadOnlySubPlan) ? (
        <div className={classes.subHeader}>{t('reportList.subHeader')}</div>
      ) : (
        <div className={clsx(classes.subHeader, classes.outOfReports)}>{t('reportList.outOfReports')}</div>
      )}
      <div className={classes.row}>
        <Filters adminPreview={adminPreview} onChange={handleFilters} filters={requestPayload.filters} />
      </div>
      {[StateEnum.initializing, StateEnum.loading].includes(state) && (
        <div className={classes.overlay}>
          <Loader />
        </div>
      )}
      <div className={classes.listWrapper}>
        <List
          className={classes.list}
          config={config}
          data={data}
          onClick={handleClick}
          onSort={handleSort}
          sortKey={requestPayload.orderBy}
          sortOrder={requestPayload.order}
          onAction={handleAction}
        />
      </div>
      <div className={classes.RWDTable}>
        <RWDTable data={data} config={config} onClick={handleClick} />
      </div>
      <Pagination
        className={classes.pagination}
        total={total}
        limit={requestPayload.limit}
        page={page}
        onPageChange={handlePage}
        onLimitChange={onLimitChange}
      />
    </div>
  );
};

export default ReportList;
