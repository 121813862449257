import React, { useEffect, useMemo, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Input from '../../../../../shared/components/Input';
import { phoneNumberValidator, makeRequired } from '../../../../../shared/components/Input/validators';
import { phoneNumberTransformer } from '../../../../../shared/components/Input/transformers';
import Tooltip from '../../../../../shared/components/Tooltip';
import ActionBox from '../ActionBox';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { StateEnum } from '../../../../../shared/helpers/state';
import { ReactComponent as PlusIcon } from '../../../../../shared/assets/plus.svg';
import { ReactComponent as BinIcon } from '../../../../../shared/assets/bin.svg';
import { handleApiError } from '../../Settings/ChangePassword/utils';

const requiredPhoneNumberValidator = makeRequired(phoneNumberValidator);

const PhoneNumberForm = ({
  className,
  form,
  setForm,
  onError,
  businessProfileId,
  setState,
  readOnlyAction,
  isEdit,
  editHandler,
  adminPreview,
  isReadOnlySubPlan
}) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();

  const isRenderedBtn = !isReadOnlySubPlan && !adminPreview;
  const [phoneNumbers, setPhoneNumbers] = useState({ primaryPhone: '', additionalPhones: [] });

  const handleSave = async () => {
    const parsedPhoneNumbers = {
      ...phoneNumbers,
      additionalPhones: phoneNumbers.additionalPhones.map((el) => el.value),
    };
    try {
      setState(StateEnum.loading);
      await api.post('/business_profile/updateBusinessProfilePhoneNumbers', {
        businessProfileId,
        phoneNumbers: parsedPhoneNumbers,
      });
      setForm((prev) => ({ ...prev, phoneNumbers }));
      showNotification({ label: t('global.success'), message: t('global.saveWithSuccess'), type: 'success' });
    } catch (err) {
      handleApiError({ err, t, showNotification });
    }
    setState(StateEnum.success);
    editHandler();
  };

  const handleCancel = () => {
    editHandler();
  };

  const handlePrimaryPhoneNumber = (value) => {
    setPhoneNumbers((prev) => ({ ...prev, primaryPhone: value }));
  };

  const handleError = (value) => {
    onError(value);
  };

  const handleAdditionalPhoneNumber = (index) => (value) => {
    setPhoneNumbers((prev) => ({
      ...prev,
      additionalPhones: prev.additionalPhones.map((el, id) => {
        if (id === index) {
          return { ...el, value };
        }
        return el;
      }),
    }));
  };

  const handleDelete = (index) => () => {
    setPhoneNumbers((prev) => ({
      ...prev,
      additionalPhones: prev.additionalPhones.filter((el, id) => id !== index),
    }));
  };

  const handleAdd = () => {
    setPhoneNumbers((prev) => ({
      ...prev,
      additionalPhones: [...prev.additionalPhones, { key: Math.random(), value: null }],
    }));
  };

  const tooltipText = useMemo(
    () => (isEdit ? t('businessProfileEdit.addNewPhoneNumberEnabled') : t('businessProfileEdit.addNewPhoneNumberDisabled')),
    [isEdit],
  );

  useEffect(() => {
    if (!isEdit) {
      const { primaryPhone, additionalPhones } = form.phoneNumbers;
      setPhoneNumbers({
        primaryPhone,
        additionalPhones: additionalPhones.map((number) => ({ key: Math.random(), value: number.value })),
      });
    }
  }, [isEdit, form.phoneNumbers]);

  return (
    <div className={clsx(classes.wrapper, className)}>
      <div className={classes.row}>
        <Input
          label={t('businessProfileEdit.phoneNumber')}
          transformer={phoneNumberTransformer}
          validator={requiredPhoneNumberValidator}
          onBlur={handlePrimaryPhoneNumber}
          readOnly={!isEdit}
          onError={handleError}
          value={phoneNumbers.primaryPhone || ''}
          autoComplete="off"
        />
        {isRenderedBtn && (
          <Tooltip tooltipStyle={classes.tooltipStyle} text={tooltipText}>
            <button
              type="button"
              disabled={!isEdit || phoneNumbers.additionalPhones?.length >= 2}
              onClick={handleAdd}
              className={clsx(classes.action, !isEdit && classes.disabled)}
              aria-label="Add"
            >
              <PlusIcon className={classes.plusIcon} />
            </button>
          </Tooltip>
        )}
      </div>
      {phoneNumbers.additionalPhones?.map((el, index) => (
        <div key={el.value} className={classes.row}>
          <Input
            label={t('businessProfileEdit.additionalPhoneNumber')}
            transformer={phoneNumberTransformer}
            validator={requiredPhoneNumberValidator}
            onBlur={handleAdditionalPhoneNumber(index)}
            readOnly={!isEdit}
            showErrorAfterBlur
            onError={handleError}
            value={el.value || ''}
          />
          {isRenderedBtn && (
            <button
              type="button"
              disabled={!isEdit}
              onClick={handleDelete(index)}
              className={classes.action}
              aria-label="Delete"
            >
              <BinIcon className={classes.binIcon} />
            </button>
          )}
        </div>
      ))}
      {isEdit && <ActionBox handleCancel={handleCancel} handleSave={handleSave} readOnlyAction={readOnlyAction} />}
    </div>
  );
};

export default PhoneNumberForm;
