import clsx from 'clsx';
import React, { useId } from 'react';
import classes from './styles.module.css';
import { getPosition } from './utils';

const Radio = ({ label, value, labelPosition, onClick, className, isEdit, primary }) => {
  const id = useId();

  const handleClick = () => onClick();

  return (
    <label
      htmlFor={id}
      className={clsx(classes.wrapper, isEdit && classes.userClick, className, primary && classes.primaryCheckbox)}
      style={{ flexFlow: getPosition(labelPosition) }}
    >
      <span className={classes.label}>{label}</span>
      <input className={classes.input} id={id} type="radio" checked={value} onChange={handleClick} />
      <div className={clsx(classes.checkbox, primary && classes.primaryCheckbox)} />
    </label>
  );
};

export default Radio;
