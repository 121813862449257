import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import classes from './styles.module.css';
import Pill from '../../../../../shared/components/Pill';
import { useDashboard } from '../../../helpers/dashboard';
import { postStateConfig } from '../../../views/AddPost/utils';
import { ReactComponent as DeleteIcon } from '../../../../../shared/assets/trash.svg';
import { ReactComponent as EditIcon } from '../../../../../shared/assets/editIcon.svg';
import { readOnlySubList } from '../../../helpers/constants';
import { POST_STATE_ENUM } from '../ModalForm/utils/utils';

const ItemHeader = ({ post, onDeleteIconClickHandler, onEdit, adminPreview }) => {
  const { t } = useTranslation();
  const { businessProfile } = useDashboard();
  const { state, postID } = post;
  const { color, label } = useMemo(() => postStateConfig[state], [state]);
  const { name, subscription } = businessProfile;

  const handleDeleteClick = () => onDeleteIconClickHandler(postID);
  const handleEditClick = () => onEdit(post);
  const isReadOnlySubPlan = readOnlySubList.includes(subscription);
  const isActionButtonRender = !isReadOnlySubPlan && !adminPreview;

  const displayedDate = dayjs(post.state === POST_STATE_ENUM.PLANNED ? post.publishDate : post.createTime).format(
    'DD.MM.YYYY',
  );

  return (
    <div className={classes.cardHeader}>
      <div>
        <div className={classes.cardTitle}>{name}</div>
        <div className={classes.rowContainer}>
          <div className={classes.cardDate}>{displayedDate}</div>
          <Pill color={color} label={t(label)} />
        </div>
      </div>
      {isActionButtonRender && (
        <div className={classes.cardHeaderActions}>
          <DeleteIcon onClick={handleDeleteClick} className={classes.actionIcon} />
          <EditIcon onClick={handleEditClick} className={classes.actionIcon} />
        </div>
      )}
    </div>
  );
};

export default ItemHeader;
