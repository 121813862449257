import dayjs from 'dayjs';
import { METRIC_TYPE_ENUM } from '../../../helpers/enums';
import { DEFAULT_DATE_FORMAT } from '../../../helpers/constants';

const COLOR = '#226EF9';

export const selectChartOptions = [
  {
    id: 1,
    name: 'dashboard.viewsChartTitle',
    type: METRIC_TYPE_ENUM.default,
  },
  { id: 2, name: 'dashboard.desktopSearch', type: METRIC_TYPE_ENUM.desktopImpressionsSearch },
  { id: 3, name: 'dashboard.desktopMap', type: METRIC_TYPE_ENUM.desktopImpressionsMaps },
  { id: 4, name: 'dashboard.mobileSearchMaps', type: METRIC_TYPE_ENUM.mobileImpressionsMaps },
  { id: 5, name: 'dashboard.mobileSearch', type: METRIC_TYPE_ENUM.mobileImpressionsSearch },
  { id: 6, name: 'dashboard.googleBookings', type: METRIC_TYPE_ENUM.bookings },
  { id: 7, name: 'dashboard.googleDirectionRequests', type: METRIC_TYPE_ENUM.directionRequests },
];

export const prepareChartLabels = (data) => data.map((item) => dayjs(item.date).format('DD.MM'));
export const prepareChartValues = (data) => data.map((item) => item.value);

export const prepareChartData = (dataLabels, dataValues, eventsData = {}) => ({
  labels: dataLabels,
  datasets: [
    { ...eventsData },
    {
      data: dataValues,
      borderColor: COLOR,
      borderWidth: 3,
      borderCapStyle: 'round',
      fill: {
        target: 'origin',
        above: 'rgba(34, 110, 249, 0.2)',
      },
    },
  ],
});

const commonOptions = {
  responsive: true,
  maintainAspectRatio: false,
};
const MAX_EVENTS_DISPLAYED = 8;
const formatLabel = (context, t) => {
  const labelValue = context.dataset.data[context.dataIndex];
  const isEvent = context.datasetIndex === 0;
  if (isEvent) {
    const valueKey = Object.keys(context.dataset.data[context.dataIndex].values);
    const eventDescription = valueKey.map((key) => {
      let keyData = context.dataset.data[context.dataIndex].values[key];
      if (keyData.length > MAX_EVENTS_DISPLAYED) {
        const remaining = keyData.length - MAX_EVENTS_DISPLAYED;
        keyData = keyData.slice(0, MAX_EVENTS_DISPLAYED);
        keyData.push({ value: t('dashboard.remaingEvents', { event: remaining }), shortDate: key });
      }
      return keyData.map(({ value }) => value).join('\n');
    });
    const description = eventDescription.join('\n').split('\n');
    return description;
  }
  return `${t('dashboard.viewsNumber')}: ${labelValue}`;
};

const filterZeroValues = (context) => context.raw !== 0;

const formatTicks = (value) => (value % 1 !== 0 ? null : value);

const getLineChartOptions = (t) => ({
  ...commonOptions,
  scales: {
    y: {
      beginAtZero: true,
      grid: {
        color: '#F2F4F7',
      },
      ticks: {
        callback: formatTicks,
      },
    },
    x: {
      label: {
        display: false,
      },
      grid: {
        display: false,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      filter: filterZeroValues,
      callbacks: {
        label: (context) => formatLabel(context, t),
      },
    },
  },
  elements: {
    line: {
      tension: 0.4,
    },
    point: {
      backgroundColor: COLOR,
      radius: (context) => (context.raw === 0 ? 0 : 4),
      hoverRadius: 6,
      hoverBorderWidth: 2,
      pointStyle: 'circle',
      hitRadius: 10,
    },
  },
  onHover: (event, chartElements) => {
    const target = event.native ? event.native.target : event.target;
    target.style.cursor = chartElements.length > 0 ? 'pointer' : 'default';
  },
});
const pieChartOption = {
  ...commonOptions,
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
    },
    tooltip: {
      callbacks: {
        label: (context) => {
          const label = context.label || '';
          const value = context.raw;
          return `${label}: ${value}`;
        },
      },
    },
  },
};

export const chartOptions = (type, t) => (type !== 'COMPARISON' ? getLineChartOptions(t) : pieChartOption);

const labelTranslations = {
  [METRIC_TYPE_ENUM.desktopImpressionsSearch]: 'dashboard.desktopSearch',
  [METRIC_TYPE_ENUM.desktopImpressionsMaps]: 'dashboard.desktopMap',
  [METRIC_TYPE_ENUM.mobileImpressionsMaps]: 'dashboard.mobileSearchMaps',
  [METRIC_TYPE_ENUM.mobileImpressionsSearch]: 'dashboard.mobileSearch',
};

export const preparePieChartComparisonData = (performance, t) => {
  if (!performance) return null;

  const dataByMetricTypeWithTotalValues = performance.viewsAndInteractions
    .filter((data) => Object.keys(labelTranslations).includes(data.metricType))
    .reduce((acc, item) => {
      if (!acc[item.metricType]) {
        acc[item.metricType] = [];
      }
      acc[item.metricType].push(item);
      return acc;
    }, {});
  const totalValuesByMetricType = Object.keys(dataByMetricTypeWithTotalValues).reduce((acc, key) => {
    acc[key] = dataByMetricTypeWithTotalValues[key].reduce((total, item) => total + item.value, 0);
    return acc;
  }, {});

  const translatedLabels = Object.keys(totalValuesByMetricType).map((key) => t(labelTranslations[key] || ''));

  const pieChartData = {
    labels: translatedLabels,
    datasets: [
      {
        data: Object.values(totalValuesByMetricType),
        backgroundColor: [COLOR, '#FE8130', '#FFCF0F', '#44C13C'],
        borderWidth: 2,
        borderColor: '#f2f2',
        hoverBorderColor: '#2121',
        hoverOffset: 24,
        cutout: '50%',
        rotation: 40,
        radius: '90%',
        borderAlign: 'inner',
      },
    ],
  };
  return pieChartData;
};

export const generateDates = (startDate, endDateParam) => {
  const dates = [];
  let currentDate = dayjs(startDate);
  const endDate = dayjs(endDateParam);

  while (currentDate.isBefore(endDate) || currentDate.isSame(endDate)) {
    dates.push(currentDate.format('YYYY-MM-DD'));
    currentDate = currentDate.add(1, 'day');
  }

  return dates;
};

export const filterChartDataByType = (data, type) => data.filter((item) => item.metricType === type);

export const sortChartDataByDate = (data) => data.sort((a, b) => dayjs(a.date).unix() - dayjs(b.date).unix());

export const getStartDate = (selectedPeriod, sortedData) => selectedPeriod?.dateFrom || sortedData[0]?.date;

export const getEndDate = (selectedPeriod, sortedData) => selectedPeriod?.dateTo || sortedData[sortedData.length - 1]?.date;

export const fillChartData = (allDates, sortedData, dateFormat) => allDates.map((date) => {
  const dataPoint = sortedData.find((item) => dayjs(item.date).format(dateFormat) === date);
  return dataPoint || { date, value: 0 };
});

export const getEventsChartData = ({ userChangeHistory }) => ({
  data: userChangeHistory,
  pointRadius: 5,
  showLine: false,
  pointBackgroundColor: '#fe8130',
  parsing: {
    xAxisKey: 'shortDate',
    yAxisKey: 'valueAsNumber',
  },
});

export const getFilledChartData = ({ selectedPeriod, selectedDataType, interactions }) => {
  const specificChartData = filterChartDataByType(interactions, selectedDataType);
  const sortedChartData = sortChartDataByDate(specificChartData);

  const startDate = getStartDate(selectedPeriod, sortedChartData);
  const endDate = getEndDate(selectedPeriod, sortedChartData);
  const allDates = generateDates(startDate, endDate);

  return fillChartData(allDates, sortedChartData, DEFAULT_DATE_FORMAT);
};
