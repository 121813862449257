import React, { useEffect, useLayoutEffect, useState } from 'react';
import classes from './styles.module.css';
import SettingsHeader from '../../modules/Settings/SettingsHeader';
import { PATH_ENUM, TAB_COMPONENTS } from './utils';
import { useAuth } from '../../../../shared/helpers/auth';

const Settings = () => {
  const { businessProfiles } = useAuth();
  const [activeTab, setActiveTab] = useState(PATH_ENUM.PROFILE);
  const savedTab = localStorage.getItem('activeTab');
  const onTabClickHandler = (tab) => {
    setActiveTab(tab);
  };

  useLayoutEffect(() => {
    setActiveTab(savedTab ?? PATH_ENUM.PROFILE);

    return () => {
      localStorage.setItem('activeTab', PATH_ENUM.PROFILE);
    };
  }, []);

  useEffect(() => {
    if (!businessProfiles.length && activeTab === PATH_ENUM.MY_BUSINESS_CARDS) {
      setActiveTab(PATH_ENUM.PROFILE);
    }
  }, [activeTab, businessProfiles]);

  return (
    <div className={classes.container}>
      <SettingsHeader onTabSelect={onTabClickHandler} activeTab={activeTab} />
      <div>{TAB_COMPONENTS[activeTab]}</div>
    </div>
  );
};
export default Settings;
