import React, { useState, useCallback } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { useApi } from '../../../../../shared/helpers/api';
import { useDashboard } from '../../../helpers/dashboard';
import { useNotification } from '../../../../../shared/helpers/notification';
import DeleteModal from '../DeleteModal';
import PostItem from '../PostItem';
import RWDDeleteBox from '../RWDDeleteBox';
import { getPostID, sortList } from '../../../views/AddPost/utils';
import SelectAlternative from '../../../../../shared/components/Select/SelectAlternative';
import { SMALL_MOBILE_WIDTH } from '../../../helpers/constants';
import PostsEmptyState from '../PostsEmptyState';
import SubInformation from '../../../../../shared/components/SubInformation';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import { useIsMobile } from '../../../../../shared/helpers/hooks';

const PostList = ({
  onEdit,
  posts,
  onSortChange,
  fetchPosts,
  selectedTab,
  createPost,
  adminPreview,
  loadingHandler,
  businessPhoneNumber,
}) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const { businessProfile } = useDashboard();

  const [selectedSort, setSelectedSort] = useState(sortList[0].id);
  const [postToDelete, setPostToDelete] = useState(null);
  const [modalDelete, setModalDelete] = useState(false);
  const shouldShowSortBtn = Boolean(posts.length && selectedTab !== 'planned');
  const postsLengthLimitReached = posts.length >= 99;
  const isMobile = useIsMobile(SMALL_MOBILE_WIDTH);

  const modalHandler = useCallback((post) => {
    if (post) {
      const postId = post.id || getPostID(post.name);
      setPostToDelete(postId);
    }
    setModalDelete((prevState) => !prevState);
  }, []);

  const handleSortSelect = useCallback(
    (val) => {
      const { sort, id } = sortList.find((option) => option.id === val);
      onSortChange(sort);
      setSelectedSort(id);
      setPostToDelete(null);
    },
    [onSortChange],
  );

  const deleteItemHandler = useCallback(async () => {
    const controller = new AbortController();
    try {
      loadingHandler(true);
      const payload = { businessProfileId: businessProfile.id, localPostId: postToDelete };
      const apiPath = typeof postToDelete === 'number' ? '/local_post/deletePostponedLocalPost' : '/local_post/deleteLocalPost';
      await api.post(apiPath, payload, { signal: controller.signal });
      await fetchPosts();
      showNotification({ label: t('global.success'), message: t('posts.deletePostSuccess'), type: 'success' });
    } catch (err) {
      handleApiError({ err, t, showNotification });
    } finally {
      loadingHandler(false);
      modalHandler();
    }

    return () => {
      controller.abort();
    };
  }, [api, businessProfile.id, fetchPosts, modalHandler, postToDelete, showNotification, t]);

  return (
    <div className={classes.container}>
      {modalDelete && isMobile && <RWDDeleteBox deleteItemHandler={deleteItemHandler} modalHandler={modalHandler} />}
      {modalDelete && <DeleteModal deleteItemHandler={deleteItemHandler} modalHandler={modalHandler} />}
      <div className={clsx(classes.sortContainer, postsLengthLimitReached && classes.spaceBetween)}>
        {postsLengthLimitReached && (
          <SubInformation className={classes.informationBox} label={t('posts.postsReached')} />
        )}
        {shouldShowSortBtn && (
          <SelectAlternative
            onSelect={handleSortSelect}
            list={sortList}
            value={selectedSort}
            showOnList={(val) => t(val.name)}
            className={classes.sortSelect}
          />
        )}
      </div>
      {posts.length ? (
        <div className={classes.body}>
          {posts.map((post) => (
            <PostItem
              key={post.postID}
              onDeleteIconClickHandler={() => modalHandler(post)}
              onEdit={onEdit}
              post={post}
              adminPreview={adminPreview}
              businessPhoneNumber={businessPhoneNumber}
            />
          ))}
        </div>
      ) : (
        <PostsEmptyState tab={selectedTab} createPost={createPost} adminPreview={adminPreview} />
      )}
    </div>
  );
};

export default PostList;
