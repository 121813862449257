import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CreateCardModalHeading from '../CreateCardModalHeading';
import classes from './styles.module.css';
import Input from '../../../../../shared/components/Input';
import Button from '../../../../../shared/components/Button';
import LinkFormField from '../LinkFormField';
import { required, urlValidator } from '../../../../../shared/components/Input/validators';
import { formFieldEnum } from '../CreateSeoCardModal/utils';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { ReactComponent as AddBtnIcon } from '../../../../../shared/assets/addCircle.svg';

const MIN_CARD_CODE_LENGTH = 4;

const CreateCardDataForm = ({ onSave }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const [linkForm, setLinkForm] = useState([{ linkName: '', linkCode: '', link: '', id: 0 }]);
  const [cardData, setCardData] = useState({ cardName: '', cardCode: '' });
  const [readOnlyAddLinkBtn, setReadOnlyAddLinkBtn] = useState(false);
  const [cardCodeExists, setCardCodeExists] = useState(false);
  const [saveCardBtnReadOnly, setSaveCardBtnReadOnly] = useState(true);
  const [linkIdExists, setLinkIdExists] = useState(false);
  const [error, setError] = useState(false);
  const linkCount = Number(linkForm.length);
  const { cardName, cardCode } = cardData;
  const { cardName: cardNameKey, cardCode: cardCodeKey } = formFieldEnum;

  const addLinkHandler = () => {
    setLinkForm((prev) => [...prev, { redirectName: '', baseUrl: '', redirectUrl: '', id: prev.length }]);
  };

  const cardDataChangeHandler = (key) => (value) => {
    setCardData((prev) => ({ ...prev, [key]: value }));
  };

  const linkInputChangeHandler = (index) => (key) => (value) => {
    setLinkForm((prev) => {
      const newLinkForm = [...prev];
      newLinkForm[index][key] = value;
      return newLinkForm;
    });
  };

  const deleteLinkHandler = (index) => {
    setLinkForm((prev) => {
      const newLinkForm = [...prev];
      newLinkForm.splice(index, 1);
      return newLinkForm;
    });
  };

  const handleSaveData = () => {
    const parsedLinkForm = linkForm.map(({ linkName, linkCode, link }) => Object.fromEntries(Object.entries({ linkName, linkCode, link }).filter(([_, v]) => v)),);
    onSave({ cardData, linkForm: parsedLinkForm });
  };

  const checkUniqueIdHandler = async (cardCodeValue) => {
    const {
      data: { exists },
    } = await api.post('/acp/card/cardCodeExists', { cardCode: cardCodeValue });
    setCardCodeExists(exists);
  };

  const onErrorLinkInputHandler = (err) => {
    setError(Boolean(err));
  };
  useEffect(() => {
    const someLinkCodeIsEmpty = linkForm.some(({ linkCode }) => !linkCode || linkCode.length < 4);
    const someLinkCodeIsSame = linkForm.some(
      (link, index) => linkForm.findIndex((l) => l.linkCode === link.linkCode) !== index,
    );
    const linkFormReachedLimit = linkForm.length >= 5;
    const linkIsValid = linkForm.some(({ link }) => !urlValidator(link, true))
      || linkForm.some(({ linkCode }) => !required(linkCode.length >= 4));
    const isCardDataEmpty = !cardData.cardCode || someLinkCodeIsEmpty;

    const isButtonDisabled = isCardDataEmpty
      || someLinkCodeIsEmpty
      || cardCodeExists
      || linkIdExists
      || someLinkCodeIsSame
      || error
      || !linkIsValid;

    setSaveCardBtnReadOnly(isButtonDisabled);
    setReadOnlyAddLinkBtn(isButtonDisabled || linkFormReachedLimit || error);

    if (someLinkCodeIsSame) {
      showNotification({ message: t('adminControlPanel.linkIdExists'), type: 'error' });
    }
  }, [cardData, linkForm, cardCodeExists, linkIdExists, error]);

  useEffect(() => {
    if (cardCodeExists) {
      showNotification({ message: t('adminControlPanel.cardCodeExist'), type: 'error' });
    }
  }, [cardCodeExists]);

  return (
    <div className={classes.wrapper}>
      <div className={classes.headingWrapper}>
        <CreateCardModalHeading
          stepText={t('adminControlPanel.seoCardModalSecondStep')}
          title={t('adminControlPanel.configureCard')}
          titleSubInfo={t('adminControlPanel.seoCardModalSecondStepSubtitle')}
        />
      </div>
      <div className={classes.form}>
        <div className={classes.cardBasicDataForm}>
          <div className={classes.label}>
            {t('adminControlPanel.cardTitle')}
            <Input
              admin
              labelRised
              onChange={cardDataChangeHandler(cardNameKey)}
              placeholder={t('adminControlPanel.passCardName')}
              showErrorAfterBlur
              value={cardName}
              maxLength={50}
            />
          </div>
          <div className={classes.label}>
            {t('adminControlPanel.cardId')}
            *
            <Input
              admin
              labelRised
              onChange={cardDataChangeHandler(cardCodeKey)}
              placeholder={t('adminControlPanel.passCardId')}
              showErrorAfterBlur
              validator={required(MIN_CARD_CODE_LENGTH)}
              value={cardCode}
              onBlur={checkUniqueIdHandler}
              onFocus={() => setCardCodeExists(false)}
            />
            {cardCodeExists && <p className={classes.error}>{t('adminControlPanel.cardCodeExist')}</p>}
          </div>
        </div>
        <div className={classes.cardLinkDetailsForm}>
          <div className={classes.detailsFromHeading}>
            <div className={classes.detailTitle}>{t('adminControlPanel.redirectionLinks')}</div>
            <div className={classes.detailNumber}>{t('adminControlPanel.linkNumber', { value: linkCount })}</div>
          </div>
        </div>
        <div className={classes.linkFormWrapper}>
          {linkForm.map((link, index) => (
            <LinkFormField
              index={index}
              key={link.id}
              linkForm={linkForm}
              onInputChange={linkInputChangeHandler(index)}
              onLinkDelete={() => deleteLinkHandler(index)}
              onLinkIdExists={(v) => setLinkIdExists(v)}
              linkIdExist={linkIdExists}
              onErrorLinkInputHandler={onErrorLinkInputHandler}
            />
          ))}
        </div>
        <Button
          Icon={AddBtnIcon}
          className={classes.addLinkBtn}
          label={t('adminControlPanel.addNext')}
          onClick={addLinkHandler}
          readOnly={readOnlyAddLinkBtn}
        />
      </div>
      <Button
        className={classes.saveCardBtn}
        label={t('adminControlPanel.saveCard')}
        onClick={handleSaveData}
        readOnly={saveCardBtnReadOnly}
      />
    </div>
  );
};

export default CreateCardDataForm;
