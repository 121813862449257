import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import classes from './styles.module.css';
import { useApi } from '../../../../shared/helpers/api';
import { useAuth } from '../../../../shared/helpers/auth';
import { useNotification } from '../../../../shared/helpers/notification';
import { useKeyDown } from '../../../../shared/helpers/hooks';
import Input from '../../../../shared/components/Input';
import Button from '../../../../shared/components/Button';
import Checkbox from '../../../../shared/components/Checkbox';
import Loader from '../../components/Loader';
import { handleApiError } from '../../modules/Settings/ChangePassword/utils';
import {
  StepEnum,
  isSignUpButtonDisabled,
  getConfirmationPasswordErrorMessage,
  getErrorMessage,
  passwordErrorsConfig,
  passwordInputValid,
} from './utils';
import { ReactComponent as CheckIcon } from '../../../../shared/assets/check.svg';
import { ReactComponent as InfoIcon } from '../../../../shared/assets/circleInfo.svg';

const SetPassword = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { setAuth } = useAuth();
  const { api, setTokens } = useApi();
  const { showNotification } = useNotification();

  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({});
  const [resetHash] = useState(location.state?.resetHash || '');
  const [errors, setErrors] = useState({});
  const [passwordConfirmationBlurred, setPasswordConfirmationBlurred] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const { password, passwordConfirmation } = form;
  const title = resetHash.length ? t('setPassword.resetPassword') : t('setPassword.title');
  const confirmBtnLabel = resetHash.length ? t('setPassword.resetPassword') : t('setPassword.signUp');

  const goBackHandler = () => navigate(-1);

  const errMessage = getErrorMessage({ password, passwordConfirmation, passwordConfirmationBlurred });
  const errConfirmationPasswordMessage = getConfirmationPasswordErrorMessage({
    password,
    passwordConfirmation,
    passwordConfirmationBlurred,
  });

  const { isPasswordConfirmationValid, isPasswordValid } = passwordInputValid(errMessage, form, password);
  const readOnlySingUpButton = isSignUpButtonDisabled({ errMessage, errConfirmationPasswordMessage });

  const passwordHelperMessage = t(errMessage.message) === t(passwordErrorsConfig.passwordIsOk)
    ? t(errConfirmationPasswordMessage.message)
    : t(errMessage.message);

  const confirmationPasswordBlurHandler = (val) => {
    setPasswordConfirmationBlurred(val);
  };

  const handleError = (name) => (value) => {
    setErrors((prev) => ({ ...prev, [name]: value }));
  };

  const checkIfPassworsdMatch = ({ pass, passConfirm }) => {
    setErrors((prev) => ({
      ...prev,
      passwordsMatch: pass !== passConfirm ? passwordErrorsConfig.passwordsAreDifferent : false,
    }));
  };

  const handleForm = (name) => (value) => {
    setForm((prev) => {
      if (name === StepEnum.password) {
        checkIfPassworsdMatch({ password: value, passwordConfirmation: prev.passwordConfirmation });
      } else {
        checkIfPassworsdMatch({ password: prev.password, passwordConfirmation: value });
      }
      return { ...prev, [name]: value };
    });
  };

  const handleSignUpSuccess = (data) => {
    const { session, token, refreshToken } = data;
    setTokens({ token, refreshToken });
    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', refreshToken);
    setAuth({
      id: session.id,
      login: session.login,
      businessProfiles: session.businessProfiles,
      isAuth: true,
    });
    navigate('/pick_business_profiles');
  };

  const handlePasswordChangeSuccess = () => {
    showNotification({ label: 'Success', message: t('setPassword.passwordChanged'), type: 'success' });
    navigate('/login');
  };

  const handleSetPassword = async () => {
    const result = Object.fromEntries(new URLSearchParams(location.search).entries());
    const isResetPassword = Boolean(resetHash.length);
    const apiPath = isResetPassword ? 'auth/changePassword' : 'auth/signUp';
    const apiPayload = isResetPassword
      ? { newPassword: form.password, codeHash: resetHash }
      : { code: result.code, password: form.password };

    try {
      setIsLoading(true);
      const { data } = await api.post(apiPath, apiPayload);
      if (apiPath === 'auth/signUp') {
        handleSignUpSuccess(data);
      } else {
        handlePasswordChangeSuccess();
      }
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  };

  useKeyDown({ callback: handleSetPassword, canInvoke: !readOnlySingUpButton, key: 'Enter' });

  return (
    <div className={classes.wrapper}>
      {isLoading && (
        <div data-testid="loader" className={classes.loading}>
          <Loader />
        </div>
      )}
      <div className={classes.form}>
        <div className={classes.text}>{title}</div>
        <Input
          testId="password"
          className={classes.input}
          value={form.password}
          label={t('setPassword.password')}
          onChange={handleForm('password')}
          onError={handleError('password')}
          showErrorAfterBlur
          type={showPassword ? 'text' : 'password'}
        />
        <CheckIcon className={clsx(classes.checkIcon, isPasswordValid && classes.showCheck)} />
        <Input
          testId="confirmPassword"
          className={classes.input}
          label={t('setPassword.confirmPassword')}
          value={form.passwordConfirmation}
          onChange={handleForm('passwordConfirmation')}
          onBlur={() => confirmationPasswordBlurHandler(true)}
          onFocus={() => confirmationPasswordBlurHandler(false)}
          type="password"
          error={errMessage.errorPasswordConfirmation}
        />

        <CheckIcon className={clsx(classes.checkIcon, isPasswordConfirmationValid && classes.showCheck)} />
        <div className={clsx(classes.passwordHelper)}>
          <InfoIcon className={clsx(classes.infoIcon)} width={16} height={16} />
          {passwordHelperMessage}
        </div>
        <Checkbox
          checkboxStyle={classes.checkboxStyle}
          onClick={() => setShowPassword((prev) => !prev)}
          labelPosition="right"
          className={classes.showPassword}
          label={t('setPassword.showPassword')}
          checkIconStyle={classes.checkIconStyle}
        />

        <div className={classes.buttons}>
          <Button
            className={classes.button}
            label={confirmBtnLabel}
            readOnly={readOnlySingUpButton}
            onClick={handleSetPassword}
          />
          {!resetHash && (
            <Button className={classes.backButton} label={t('setPassword.back')} onClick={goBackHandler} />
          )}
        </div>
      </div>
    </div>
  );
};

export default SetPassword;
