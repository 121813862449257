import React, { useCallback, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Input from '../../../../../shared/components/Input';
import {
  makeRequired,
  postalCodeValidator,
  streetAndNumberValidator,
  validateCityName,
} from '../../../../../shared/components/Input/validators';
import {
  postalCodeTransformer,
  stringCharactersTransformer,
} from '../../../../../shared/components/Input/transformers';
import { StateEnum } from '../../../../../shared/helpers/state';
import { debounce } from '../../../../../shared/helpers/debounce';
import { useNotification } from '../../../../../shared/helpers/notification';
import { useApi } from '../../../../../shared/helpers/api';
import ActionBox from '../ActionBox';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import { renderMapStateComponent } from './utils';

const requiredPostalCode = makeRequired(postalCodeValidator);
const requiredCityNameValidator = makeRequired(validateCityName);
const requiredAddressValidator = makeRequired(streetAndNumberValidator);
const LocationForm = ({
  className,
  form,
  setForm,
  onError,
  businessProfileId,
  setState,
  readOnlyAction,
  isEdit,
  editHandler,
  adminPreview,
}) => {
  const { api } = useApi();
  const { t } = useTranslation();
  const { showNotification } = useNotification();

  const [mapState, setMapState] = useState(StateEnum.initializing);
  const [mapPreview, setMapPreview] = useState(null);
  const [location, setLocation] = useState({ street: '', postalCode: '', locality: '' });
  const [errors, setErrors] = useState({ street: true, postalCode: true, locality: true });

  const fetchMap = async ({ fileDate, fileName }, controller) => {
    if (adminPreview) return;
    try {
      const { data } = await api.get(`/map/getPreviewMap/${fileDate}/${fileName}`, {
        signal: controller ? controller.signal : undefined,
        responseType: 'arraybuffer',
        responseEncoding: 'binary',
      });
      setMapPreview(window.URL.createObjectURL(new Blob([data], { type: 'image/png' })));
      setMapState(StateEnum.success);
    } catch (err) {
      if (err.message !== 'canceled') {
        setMapState(StateEnum.error);
        handleApiError({ err, showNotification, t });
      }
    }
  };

  const generateMap = useCallback(
    debounce(async ({ postalCode, street, locality }) => {
      try {
        const payload = {
          businessProfileId,
          administrativeArea: locality,
          postalCode,
          addressLines: [street],
        };
        const { data } = await api.post('/map/generateMapByAddress', payload);
        setLocation((prev) => ({ ...prev, name: data.name, date: data.date }));
        await fetchMap({ fileDate: data.date, fileName: data.name });
      } catch (err) {
        handleApiError({ err, showNotification, t });
      }
    }, 1000),
    [],
  );

  useEffect(() => {
    const controller = new AbortController();
    if (location.date && location.name) {
      fetchMap({ fileDate: location.date, fileName: location.name }, controller);
    }

    return () => {
      controller.abort();
    };
  }, []);

  const handleError = (name) => (value) => {
    setErrors((prev) => ({ ...prev, [name]: value }));
  };

  const handleLocation = (name) => (value) => {
    if (location[name] === value) {
      return;
    }
    setLocation((prev) => {
      const current = { ...prev, [name]: value };
      if (!Object.values(errors).some(Boolean) && isEdit) {
        setMapState(StateEnum.loading);
        generateMap(current);
      }
      return current;
    });
  };

  const handleSave = async () => {
    try {
      setState(StateEnum.loading);
      const parsedStorefrontAddress = {
        locality: location.locality,
        postalCode: location.postalCode,
        addressLines: [location.street],
      };
      await api.post('/business_profile/updateBusinessProfileAddress', {
        businessProfileId,
        storefrontAddress: parsedStorefrontAddress,
      });
    } catch (err) {
      handleApiError({ err, showNotification, t });
    }
    setState(StateEnum.success);
    setForm((prev) => ({ ...prev, storefrontAddress: { ...location, addressLines: [location.street] } }));
    editHandler();
  };

  const handleCancel = () => editHandler();

  useEffect(() => {
    onError(Object.values(errors).some(Boolean));
  }, [errors]);

  useEffect(() => {
    const controller = new AbortController();
    const { map } = form;
    if (map) {
      fetchMap({ fileDate: map.date, fileName: map.name }, controller);
    }
    return () => {
      controller.abort();
    };
  }, [form.map]);

  useEffect(() => {
    if (!isEdit) {
      setLocation({ ...form.storefrontAddress, street: form.storefrontAddress?.addressLines?.join(' ') });
    }
  }, [isEdit, form.storefrontAddress]);

  return (
    <div className={clsx(classes.wrapper, className)}>
      <div className={classes.body}>
        <div className={classes.inputs}>
          <Input
            label={t('businessProfileEdit.country')}
            onError={handleError('country')}
            onChange={handleLocation('country')}
            readOnly
            value="Polska"
          />
          <Input
            label={t('businessProfileEdit.streetAndNumber')}
            validator={requiredAddressValidator}
            onError={handleError('street')}
            onChange={handleLocation('street')}
            readOnly={!isEdit}
            value={location.street}
            autoComplete="off"
          />
          <Input
            label={t('businessProfileEdit.postalCode')}
            validator={requiredPostalCode}
            onError={handleError('postalCode')}
            onChange={handleLocation('postalCode')}
            transformer={postalCodeTransformer}
            readOnly={!isEdit}
            value={location.postalCode}
            autoComplete="off"
          />
          <Input
            label={t('businessProfileEdit.city')}
            validator={requiredCityNameValidator}
            onError={handleError('locality')}
            onChange={handleLocation('locality')}
            readOnly={!isEdit}
            transformer={stringCharactersTransformer}
            value={location.locality}
            autoComplete="off"
          />
        </div>
        {!adminPreview && renderMapStateComponent({ mapState, mapPreview, t })}
      </div>
      {isEdit && <ActionBox handleCancel={handleCancel} handleSave={handleSave} readOnlyAction={readOnlyAction} />}
    </div>
  );
};

export default LocationForm;
