import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import FormContentWrapper from '../FormContentWrapper';
import FormPreview from '../FormPreview';
import { useNotification } from '../../../../../shared/helpers/notification';
import { useDashboard } from '../../../helpers/dashboard';
import { useApi } from '../../../../../shared/helpers/api';
import { handleChangeFormType, prepareApiData, removeEmptyKeys, validateFormFieldsType } from './utils';
import { handleApiError } from '../../Settings/ChangePassword/utils';

const PostForm = ({
  filteredOptions,
  formType,
  setData,
  data,
  modalHandler,
  buttonLabel,
  editPost,
  showPreview,
  fetchPosts,
  togglePreview,
  handleMedia,
  loadingHandler,
  changePublicationDateHandler,
  businessPhoneNumber
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { api } = useApi();
  const { businessProfile } = useDashboard();
  const { showNotification } = useNotification();
  const [apiData, setApiData] = useState({ apiPath: '', payload: {} });
  const [readOnly, setReadOnly] = useState(true);
  const [isAIHelperOpen, setIsAIHelperOpen] = useState(false);

  const handleForm = (name) => (value) => {
    handleChangeFormType(formType, name, value, setData);
  };

  const aiHelperHandler = () => {
    setIsAIHelperOpen((prev) => !prev);
  };
  const submitDataHandler = async () => {
    const controller = new AbortController();
    try {
      loadingHandler(true);
      const { apiPath, payload } = apiData;
      const parsedPayload = removeEmptyKeys(payload);
      await api.post(apiPath, parsedPayload, {
        signal: controller.signal,
      });
      await fetchPosts();
      const notificationMessage = editPost ? t('posts.postsHasBeenChanged') : t('posts.postHasBeenAdded');
      showNotification({
        message: notificationMessage,
        type: 'success',
      });
    } catch (err) {
      handleApiError({ err, showNotification, t });
    } finally {
      loadingHandler(false);
    }
    modalHandler();
    navigate('/dashboard/post');
    return () => {
      controller.abort();
    };
  };

  useEffect(() => {
    const fetchApiData = async () => {
      const { apiPath, payload } = await prepareApiData(data, editPost, businessProfile);
      setApiData({ apiPath, payload });
    };

    fetchApiData();
  }, [editPost, data]);

  useEffect(() => {
    if (data.type) {
      setReadOnly(validateFormFieldsType(data));
    }
  }, [data, editPost]);

  return filteredOptions.map(({ Form, id }) => (
    <FormContentWrapper
      key={id}
      submitDataHandler={submitDataHandler}
      buttonLabel={buttonLabel}
      showPreview={showPreview}
      showPreviewHandler={togglePreview}
      readOnlyAction={readOnly}
      isAIHelperOpen={isAIHelperOpen}
    >
      {showPreview ? (
        <FormPreview subInfo={t('posts.previewSubInfo')} data={data} businessPhoneNumber={businessPhoneNumber} />
      ) : (
        <Form
          data={data}
          handleForm={handleForm}
          handlePublicationDate={changePublicationDateHandler}
          handleMedia={handleMedia}
          onSubmit={submitDataHandler}
          editPost={editPost}
          aiHelperHandler={aiHelperHandler}
          isAIHelperOpen={isAIHelperOpen}
          businessPhoneNumber={businessPhoneNumber}
        />
      )}
    </FormContentWrapper>
  ));
};
export default PostForm;
