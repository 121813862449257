import dayjs from 'dayjs';

export const MEDIA_ENUM = {
  PROFILE: 'PROFILE',
  ADDITIONAL: 'ADDITIONAL',
  COVER: 'COVER',
  VIDEO: 'VIDEO',
};

export const mediaTypes = (t) => [
  {
    id: 'PROFILE',
    label: t('businessProfileEdit.profileImage'),
  },
  {
    id: 'ADDITIONAL',
    label: t('businessProfileEdit.additionalImages'),
  },
  {
    id: 'COVER',
    label: t('businessProfileEdit.coverImage'),
  },
  {
    id: 'VIDEO',
    label: t('businessProfileEdit.video'),
  },
];

export const getUniqueArray = (prev, newItems, selector = (item) => item.id) => [...prev, ...newItems].reduce((acc, current) => {
  const x = acc.find((item) => selector(item) === selector(current));
  if (!x) {
    return acc.concat([current]);
  }
  return acc;
}, []);

export const checkAspectRatioForCoverImage = async (coverImage) => {
  if (coverImage) {
    const { width, height } = await new Promise((resolve) => {
      const image = new Image();
      image.src = coverImage.mediaSource;
      image.onload = () => resolve({ width: image.naturalWidth, height: image.naturalHeight });
    });

    return width / height === 16 / 9;
  }
  return true;
};

export const getMediaTypeList = (t, image) => {
  const allMediaTypes = mediaTypes(t);
  const imageTypes = allMediaTypes.filter((el) => el.id !== MEDIA_ENUM.VIDEO);
  return image.extension === 'mp4' ? allMediaTypes : imageTypes;
};

export const setMediaAndMediaTypes = (setMedia, setSelectedMediaTypes, index, value) => {
  setSelectedMediaTypes((prev) => ({ ...prev, [index]: value }));
  setMedia((prev) => prev.map((el, i) => (i === index ? { ...el, type: value } : el)));
};

export const updateMediaTypes = (prev, index, value) => ({ ...prev, [index]: value });

export const updateMedia = (prev, index, value) => prev.map((el, i) => (i === index ? { ...el, type: value } : el));

export const handleCoverImageSelection = (value, index, selectedMediaTypes, setSelectedMediaTypes, setMedia) => {
  const previousProfileIndex = Object.values(selectedMediaTypes).findIndex((type) => type === MEDIA_ENUM.COVER);
  if (previousProfileIndex !== -1) {
    setSelectedMediaTypes((prev) => updateMediaTypes(prev, index, value));
    setMedia((prev) => updateMedia(prev, index, value));
  } else {
    setSelectedMediaTypes((prev) => updateMediaTypes(prev, index, value));
    setMedia((prev) => updateMedia(prev, index, value));
  }
};

export const parseBackDate = (date) => {
  const reformattedDateString = date.replace(/(\d{2})\.(\d{2})\.(\d{2})/, '20$3-$2-$1');
  const newDate = new Date(reformattedDateString);
  return dayjs(newDate).format('YYYY-MM-DD HH:mm');
};

const getImageDimensions = (src) => new Promise((resolve) => {
  const img = new Image();
  img.crossOrigin = 'Anonymous';
  img.onload = () => {
    resolve({ img, width: img.width, height: img.height });
  };
  img.src = src;
});

const calculateNewDimensions = (originalWidth, originalHeight, aspectRatio, maxWidth, maxHeight) => {
  let width = originalWidth;
  let height = originalHeight;

  if (width / height > aspectRatio) {
    width = height * aspectRatio;
  } else {
    height = width / aspectRatio;
  }

  if (width > maxWidth) {
    height *= maxWidth / width;
    width = maxWidth;
  }
  if (height > maxHeight) {
    width *= maxHeight / height;
    height = maxHeight;
  }

  return { width: Math.round(width), height: Math.round(height) };
};

export const adjustAspectRatioTo16x9 = async (mediaItem) => {
  const aspectRatio = 16 / 9;
  const maxWidth = 2120;
  const maxHeight = 1192;

  const { img, width: originalWidth, height: originalHeight } = await getImageDimensions(mediaItem.mediaSource);

  const { width, height } = calculateNewDimensions(originalWidth, originalHeight, aspectRatio, maxWidth, maxHeight);

  const canvas = document.createElement('canvas');
  canvas.width = width;
  canvas.height = height;

  const ctx = canvas.getContext('2d');

  const cropWidth = originalWidth;
  const cropHeight = originalWidth / aspectRatio;
  const cropX = 0;
  const cropY = (originalHeight - cropHeight) / 2;

  ctx.drawImage(img, cropX, cropY, cropWidth, cropHeight, 0, 0, width, height);

  const base64Image = canvas.toDataURL('image/jpeg', 1);

  return {
    ...mediaItem,
    width,
    height,
    mediaSource: base64Image,
  };
};
