import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import classes from './styles.module.css';
import Input from '../../../../shared/components/Input';
import Button from '../../../../shared/components/Button';
import { useApi } from '../../../../shared/helpers/api';
import { useAuth } from '../../../../shared/helpers/auth';
import { useAdmin } from '../../helpers/admin';
import { useNotification } from '../../../../shared/helpers/notification';
import { useKeyDown } from '../../../../shared/helpers/hooks';
import { emailValidator, makeRequired, required } from '../../../../shared/components/Input/validators';
import { handleApiError } from '../../../SEO/modules/Settings/ChangePassword/utils';
import AdminLoader from '../../components/AdminLoader';
import { ReactComponent as ShowPassword } from '../../../../shared/assets/eyeIcon.svg';
import { ERROR_ENUM } from '../../helpers/enums';
import { AUTO_COMPLETE_TYPES } from '../../../../shared/helpers/const';

const requiredMail = makeRequired(emailValidator);

const AdminLogin = () => {
  const navigate = useNavigate();
  const { showNotification } = useNotification();
  const { t } = useTranslation();
  const { api, setTokens } = useApi();
  const { setAuth } = useAuth();
  const { setAdmin } = useAdmin();
  const [isLoading, setIsLoading] = useState(false);
  const [form, setForm] = useState({ login: '', password: '' });
  const [inputErrors, setInputErrors] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [formValid, setFormValid] = useState(false);

  const remindPasswordHandler = () => {
    navigate('/admin/auth/remindPassword');
  };
  const onChangeHandler = (name) => (value) => {
    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const loginHandler = async () => {
    setIsLoading(true);
    try {
      const { data } = await api.post('/acp/auth/login', form);
      const { token, refreshToken, session } = data;
      localStorage.setItem('adminToken', token);
      localStorage.setItem('refreshAdminToken', refreshToken);
      setTokens({ adminToken: token, refreshAdminToken: refreshToken });
      setAuth({ isAdmin: true, id: session.id, login: session.login });
      setAdmin({
        id: session.id,
        login: session.login,
        name: session.name,
        lastName: session.lastName,
        role: session.roleType,
      });
      navigate('/admin');
    } catch (error) {
      setForm({ login: '', password: '' });
      if (error.code === ERROR_ENUM.badRequest) {
        showNotification({ message: t('errors.wrongLogin'), type: 'error' });
        return;
      }
      handleApiError({ err: error, showNotification, t });
    } finally {
      setIsLoading(false);
    }
  };

  const errorHandler = (error) => {
    setInputErrors(!error);
  };

  useKeyDown({ key: 'Enter', callback: loginHandler, canInvoke: formValid });

  useEffect(() => {
    setFormValid(inputErrors && form.login && form.password);
  }, [form.login, form.password, inputErrors]);

  return (
    <div className={classes.wrapper}>
      {isLoading ? (
        <div className={classes.loaderWrapper}>
          <AdminLoader />
        </div>
      ) : (
        <>
          <div className={classes.loginText}>{t('global.signIn')}</div>
          <form className={classes.form}>
            <div>
              <div className={classes.inputTitle}>{t('global.email')}</div>
              <Input
                admin
                validator={requiredMail}
                showErrorAfterBlur
                labelRised
                placeholder={t('global.email')}
                value={form.login}
                onChange={onChangeHandler('login')}
                onError={errorHandler}
                className={classes.input}
                autoCompleteName={AUTO_COMPLETE_TYPES.EMAIL}
              />
            </div>

            <div className={classes.passwordInputWrapper}>
              <div className={classes.inputTitle}>{t('global.password')}</div>
              <Input
                admin
                showErrorAfterBlur
                placeholder={t('global.password')}
                validator={required(null)}
                labelRised
                value={form.password}
                onChange={onChangeHandler('password')}
                type={showPassword ? 'text' : 'password'}
                className={classes.input}
                onError={errorHandler}
              />
              {Boolean(form.password.length) && (
                <ShowPassword
                  onClick={() => setShowPassword((prev) => !prev)}
                  className={clsx(classes.showPasswordIcon, inputErrors && classes.transformIcon)}
                />
              )}
            </div>
          </form>
          <Button
            onClick={remindPasswordHandler}
            className={classes.recoverPasswordBtn}
            label={t('global.restorePassword')}
          />
          <Button
            onClick={loginHandler}
            readOnly={!formValid}
            className={classes.signInBtn}
            label={t('global.signIn')}
          />
        </>
      )}
    </div>
  );
};

export default AdminLogin;
