import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import classes from './styles.module.css';
import { CREATE_CARD_PATH } from '../BusinessCardsSettings/utils';
import Button from '../../../../../shared/components/Button';
import { ReactComponent as AddCircle } from '../../../../../shared/assets/addCircle.svg';
import { useIsMobile } from '../../../../../shared/helpers/hooks';
import { SMALL_MOBILE_WIDTH } from '../../../helpers/constants';

const NoBusinessProfiles = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const createNewBusinessCardHandler = () => {
    navigate(CREATE_CARD_PATH);
  };

  const isSmallMobile = useIsMobile(SMALL_MOBILE_WIDTH);
  const label = isSmallMobile ? t('settings.add') : t('settings.addNew');
  return (
    <div className={classes.wrapper}>
      <div className={classes.title}>{t('settings.noBusinessProfile')}</div>
      <Button
        className={classes.addNewBtn}
        label={label}
        Icon={!isSmallMobile && AddCircle}
        onClick={createNewBusinessCardHandler}
      />
      ;
    </div>
  );
};
export default NoBusinessProfiles;
