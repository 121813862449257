import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import Textarea from '../../../../../shared/components/Textarea/index';
import Input from '../../../../../shared/components/Input';
import ExpandableSection from '../../../../../shared/components/ExpandableSection';
import { makeRequired, required, urlValidator } from '../../../../../shared/components/Input/validators';
import { urlTransformer } from '../../../../../shared/components/Input/transformers';
import { parseDateForInput } from './utils';
import { POST_STATE_ENUM } from '../ModalForm/utils/utils';
import AIHelper from '../AIHelper';
import MediaAndPublication from '../MediaAndPublication';
import { validateEventFormSchedule } from '../EventForm/utils';
import { AUTO_COMPLETE_TYPES } from '../../../../../shared/helpers/const';

const OfferForm = ({
  handleForm,
  data,
  handleMedia,
  media,
  handlePublicationDate,
  editPost,
  aiHelperHandler,
  isAIHelperOpen,
}) => {
  const { t } = useTranslation();
  const [scheduleError, setScheduleError] = useState(false);
  const { localPost: post } = data;
  const { startDate, endDate, isOneDayOffer } = useMemo(() => parseDateForInput(post), [post]);
  const { PLANNED } = POST_STATE_ENUM;
  const isEditingPublishedPost = Boolean(editPost && editPost.state !== PLANNED);

  const scheduleHandler = () => {
    if (!post?.event?.schedule) return;

    if (startDate === endDate) {
      handleForm('time')(isOneDayOffer);
    }
  };

  const scheduleErrorHandler = () => {
    if (!data.localPost?.event?.schedule) return;
    const eventSchedule = data.localPost.event.schedule;
    const validSchedule = validateEventFormSchedule(eventSchedule);
    setScheduleError(validSchedule);
  };

  useEffect(() => {
    scheduleErrorHandler();
  }, [startDate, endDate]);

  useEffect(() => {
    scheduleHandler();
  }, [startDate, endDate]);
  return (
    <div className={classes.body}>
      {!isAIHelperOpen && (
        <Input
          value={post?.event?.title}
          validator={makeRequired(required(null))}
          onChange={handleForm('title')}
          label={t('posts.offerTitle')}
          showErrorAfterBlur
          className={classes.offerName}
          maxLength={58}
          autoComplete="off"
        />
      )}
      <div className={classes.aiHelperContainer}>
        <div className={clsx(classes.aiHelperWrapper, isAIHelperOpen && classes.aiHelperContainerStatic)}>
          <AIHelper
            onApprove={handleForm('summary')}
            onClick={aiHelperHandler}
            isOpen={isAIHelperOpen}
            formType={data.type}
          />
        </div>
        {!isAIHelperOpen && (
          <Textarea
            onChange={handleForm('summary')}
            className={classes.offerDescription}
            label={t('posts.offerDetailsOptional')}
            value={post?.summary}
            maxLength={1500}
          />
        )}
      </div>
      {!isAIHelperOpen && (
        <>
          <div className={classes.rowContainer}>
            <Input
              onChange={handleForm('startDate')}
              className={classes.dateInput}
              label={t('posts.startDate')}
              type="date"
              validator={makeRequired(required(null))}
              showErrorAfterBlur
              value={startDate}
            />
            <Input
              onChange={handleForm('endDate')}
              className={classes.dateInput}
              label={t('posts.endDate')}
              type="date"
              validator={makeRequired(required(null))}
              showErrorAfterBlur
              value={endDate}
            />
          </div>
          {scheduleError && <div className={classes.error}>{t(scheduleError)}</div>}
          <MediaAndPublication
            data={data}
            handleMedia={handleMedia}
            handlePublicationDate={handlePublicationDate}
            isEditingPublishedPost={isEditingPublishedPost}
            media={media}
            shouldRenderItems={!isAIHelperOpen}
          />
          <ExpandableSection wrapperStyle={classes.wrapperStyle} sectionName={t('posts.addMoreDetails')}>
            <div className={classes.expandableSectionContainer}>
              <Input
                onChange={handleForm('couponCode')}
                label={t('posts.addCouponCode')}
                className={classes.noMargin}
                value={post?.offer?.couponCode}
                maxLength={58}
                autoComplete="off"
              />
              <Input
                onChange={handleForm('redeemOnlineUrl')}
                className={classes.noMargin}
                label={t('posts.addOfferLinkOptional')}
                value={post?.offer?.redeemOnlineUrl}
                validator={urlValidator}
                showErrorAfterBlur
                transformer={urlTransformer}
                maxLength={75}
                autoCompleteName={AUTO_COMPLETE_TYPES.URL}
              />
              <Textarea
                onChange={handleForm('termsConditions')}
                className={classes.offerDescription}
                label={t('posts.termsOfferOptional')}
                value={post?.offer?.termsConditions}
                maxLength={5000}
              />
            </div>
          </ExpandableSection>
        </>
      )}
    </div>
  );
};

export default OfferForm;
