import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './style.module.css';
import Select from '../../../../../shared/components/Select';
import { useNotification } from '../../../../../shared/helpers/notification';
import { parseProfileStatus, PROFILE_STATE_ENUM } from '../../../components/DashboardLayout/utils';
import { useApi } from '../../../../../shared/helpers/api';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import { getLocalStorageProfileStatus } from '../../../../../shared/helpers/helpers';
import { PROFILE_STATUS_ENUM } from '../../../helpers/enums';
import Loader from '../../../components/Loader';

const SelectBusiness = ({ businessProfiles, handleChange, businessProfile }) => {
  const { t } = useTranslation();
  const { showNotification } = useNotification();
  const { id } = businessProfile;
  const { api } = useApi();
  const { verified } = PROFILE_STATUS_ENUM;

  const [businessProfileStatus, setBusinessProfileStatus] = useState(getLocalStorageProfileStatus(id) || null);
  const [isLoading, setIsLoading] = useState(false);
  const showNotVerifiedNotification = !isLoading && businessProfile?.id && businessProfileStatus && businessProfileStatus !== verified;

  const filterListFn = ({ list, value }) => {
    if (!value) return list;
    return list.filter((el) => el.name.toLowerCase().includes(value.toLowerCase()));
  };

  useEffect(() => {
    const controller = new AbortController();

    const fetchProfileStatus = async () => {
      setIsLoading(true);
      try {
        const { data } = await api.post(
          '/business_profile/getVerificationStatus',
          {
            businessProfileId: id,
          },
          { signal: controller.signal },
        );
        const parsedStatus = parseProfileStatus(data, t);
        setBusinessProfileStatus(parsedStatus.id);
        localStorage.setItem(`status-${id}`, parsedStatus.id);
      } catch (err) {
        if (err.message !== 'canceled') {
          handleApiError({ err, showNotification, t });
        }
      } finally {
        setIsLoading(false);
      }
    };

    if (id && !businessProfileStatus && !businessProfile.externalBusinessProfile) {
      fetchProfileStatus();
    }

    return () => controller.abort();
  }, [businessProfileStatus, id]);

  useEffect(() => {
    setBusinessProfileStatus(getLocalStorageProfileStatus(id));
  }, [id]);

  useEffect(() => {
    if (!businessProfileStatus) return;
    if (businessProfileStatus?.toLocaleLowerCase() !== verified) {
      showNotification({
        message: t('setFilters.profileNotVerified', {
          status: t(`settings.${businessProfileStatus}`)?.toLocaleLowerCase(),
        }),
        type: 'warning',
      });
    }
  }, [businessProfileStatus]);

  return (
    <div className={classes.wrapper}>
      {isLoading && (
        <div className={classes.loaderWrapper}>
          <Loader />
        </div>
      )}
      <div className={classes.sectionName}>{t('setFilters.selectCompany')}</div>
      <Select
        className={classes.inputSelect}
        inputClassName={classes.inputSelection}
        label={`${t('setFilters.yourBusinessCards', { number: businessProfiles.length })}`}
        list={businessProfiles}
        onSelect={handleChange('businessProfileId')}
        value={id}
        iconStyle={classes.iconStyle}
        filterList={filterListFn}
      />
      {showNotVerifiedNotification && (
        <div className={classes.profileNotVerified}>
          {t('setFilters.profileNotVerified', { status: t(`settings.${businessProfileStatus}`).toLocaleLowerCase() })}
        </div>
      )}
      <div className={classes.horizontalLine} />
    </div>
  );
};

export default SelectBusiness;
