import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import classes from './styles.module.css';
import Modal from '../../../../../shared/components/Modal';
import AnswerTemplateItem from '../../AnswerTemplates/AnswerTemplateItem';
import OpinionsEmptyState from '../../../components/EmptyState';

const AnswerWithTemplateModal = ({ onClose, templates, onClick }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const navigateToTemplates = () => navigate('/dashboard/opinions/answers');
  const noTemplates = templates?.length === 0;
  const isLongContent = (content) => content.length > 100;

  return (
    <Modal
      contentStyle={classes.answerWithTemplateModal}
      onCancel={onClose}
      modalTitle={t('opinions.answerWithTemplate')}
      rwdBackTitle="modalMobile.goBackToOpinions"
    >
      <div className={classes.answerWithTemplateModalContent}>
        {!noTemplates ? (
          <div className={classes.templatesWrapper}>
            {templates.map((template) => (
              <AnswerTemplateItem
                onClick={onClick}
                key={template.id}
                answer={template}
                className={clsx(classes.template, isLongContent(template.content) && classes.longTemplate)}
              />
            ))}
          </div>
        ) : (
          <OpinionsEmptyState
            title={t('opinions.noTemplatesYetPlural')}
            subTitle={t('opinions.noTemplatesYetModal')}
            buttonLabel={t('opinions.goToTemplates')}
            buttonOnClick={navigateToTemplates}
          />
        )}
      </div>
    </Modal>
  );
};
export default AnswerWithTemplateModal;
