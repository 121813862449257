import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './style.module.css';
import KeywordsInput from '../../KeywordsManagement/KeywordsInput';
import { useNotification } from '../../../../../shared/helpers/notification';
import { PROFILE_STATUS_ENUM } from '../../../helpers/enums';
import { useApi } from '../../../../../shared/helpers/api';
import { getLocalStorageProfileStatus } from '../../../../../shared/helpers/helpers';
import { handleApiError } from '../../Settings/ChangePassword/utils';
import { parseProfileStatus } from '../../../components/DashboardLayout/utils';
import Loader from '../../../components/Loader';
import PillWithIcon from '../../../../../shared/components/Pill/PillAlternative';
import Radio from '../../../../../shared/components/Radio';
import { ReactComponent as QuestionMark } from '../../../../../shared/assets/questionMark.svg';
import Tooltip from '../../../../../shared/components/Tooltip';

const KEYWORD_MAX_LENGTH = 50;
const SelectBusinessKeyWord = ({ businessProfile, onKeywordChange, selectedKeyword, fetchKeywordsAllowed }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [businessKeywords, setBusinessKeywords] = useState([]);
  const { externalBusinessProfile, id } = businessProfile;
  const { verified } = PROFILE_STATUS_ENUM;
  const profileStatus = getLocalStorageProfileStatus(id);

  useEffect(() => {
    const shouldFetchStatus = profileStatus && id && externalBusinessProfile;
    const fetchProfileStatus = async () => {
      try {
        const { data } = await api.post('/business_profile/getVerificationStatus', {
          businessProfileId: id,
        });
        const parsedStatus = parseProfileStatus(data, t);
        localStorage.setItem(`status-${id}`, parsedStatus.id);
      } catch (err) {
        handleApiError({ err, showNotification, t });
      }
    };

    if (shouldFetchStatus) {
      fetchProfileStatus();
    }
  }, [profileStatus, businessProfile]);

  useEffect(() => {
    const fetchBusinessKeywords = async () => {
      setIsLoading(true);
      try {
        const {
          data: { keywords },
        } = await api.post('/performance/getKeywords', { businessProfileId: businessProfile.id });
        setBusinessKeywords(keywords);
      } catch (err) {
        handleApiError({ err, showNotification, t });
      }
      setIsLoading(false);
    };

    if (businessProfile.id && profileStatus === verified && fetchKeywordsAllowed) {
      fetchBusinessKeywords();
    }
  }, [businessProfile, profileStatus, fetchKeywordsAllowed]);

  return (
    <>
      {isLoading && (
        <div className={classes.loaderWrapper}>
          <Loader />
        </div>
      )}
      <div className={classes.wrapper}>
        <div className={classes.sectionName}>{t('setFilters.addNewKeywords')}</div>
        <div className={classes.additionalInformation}>
          {t('setFilters.youCanGenerateReport')}
          {' '}
          <span className={classes.highlightedText}>
            {t('setFilters.forOne')}
            {' '}
          </span>
          {' '}
          {t('setFilters.keyword')}
        </div>
        <div>
          <KeywordsInput
            inputLabel={t('setFilters.keywords')}
            className={classes.input}
            buttonClassName={classes.keywordButton}
            inputClassName={classes.keywordInput}
            buttonLabel={t('setFilters.addKeyWord')}
            maxLength={KEYWORD_MAX_LENGTH}
            onChange={onKeywordChange}
            selectedKeyword={selectedKeyword}
            readOnly={!fetchKeywordsAllowed}
          />
        </div>

        {profileStatus === verified && Boolean(businessKeywords.length) && (
          <>
            <div className={classes.sugggestedKeyword}>
              {t('setFilters.suggestedKeywords')}
              {' '}
              <Tooltip
                className={classes.toolWrapper}
                tooltipStyle={classes.categoryTooltipStyle}
                text={t('setFilters.suggestedKeywordsTooltip')}
              >
                <QuestionMark className={classes.questionMarkInfo} />
              </Tooltip>
            </div>
            <div className={classes.keywordsWrapper}>
              {Boolean(businessKeywords.length)
                && businessKeywords.map(({ searchKeyword }) => (
                  <PillWithIcon
                    key={searchKeyword}
                    className={clsx(classes.pill, searchKeyword === selectedKeyword && classes.selectedPill)}
                    onClick={() => onKeywordChange(searchKeyword)}
                  >
                    <Radio value={selectedKeyword === searchKeyword} />
                    <div className={classes.displayName}>{searchKeyword}</div>
                  </PillWithIcon>
                ))}
            </div>
          </>
        )}
        <div className={classes.horizontalLine} />
      </div>
    </>
  );
};

export default SelectBusinessKeyWord;
