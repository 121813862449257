import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Button from '../../../../../shared/components/Button';
import { autoCompleDataDisplayHandler, formFields, renderInputComponent } from './utils';
import CreateCardModalHeading from '../CreateCardModalHeading';
import AsyncSelect from '../../../../../shared/components/AsyncSelect';
import { useApi } from '../../../../../shared/helpers/api';
import { debounce } from '../../../../../shared/helpers/debounce';
import { handleApiError } from '../../../../SEO/modules/Settings/ChangePassword/utils';
import { useNotification } from '../../../../../shared/helpers/notification';
import Select from '../../../../../shared/components/Select';
import AdminLoader from '../../../components/AdminLoader';
import { emailValidator, makeRequired } from '../../../../../shared/components/Input/validators';
import { useKeyDown } from '../../../../../shared/helpers/hooks';

const CreateCardClientDataForm = ({ form, onInputChange, onSave }) => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [emailError, setEmailError] = useState('');
  const [readOnlyBtn, setReadOnlyBtn] = useState(true);
  const translatedFields = formFields(t);
  const [emailValue, setEmailValue] = useState('');
  const [savedUserData, setSavedUserData] = useState([]);

  const onUserDataSelect = (userData) => {
    Object.keys(userData).forEach((key) => {
      onInputChange(key)(userData[key]);
    });
  };

  const onSelectEmailHander = (value) => {
    setEmailValue(value);
    onInputChange('email')(value);
  };

  const resetForm = () => {
    setSavedUserData([]);
  };

  const handleGetEmailList = useCallback(
    debounce(async (payload) => {
      if (!payload.value || payload.value.length <= 3) {
        resetForm();
        return [];
      }
      if (payload.value !== form.email) {
        resetForm();
      }
      onInputChange('email')(payload.value);
      try {
        const {
          data: { emails },
        } = await api.post('/acp/card/autocompleteEmail', {
          email: payload.value,
        });

        return emails;
      } catch (err) {
        handleApiError({ err, showNotification, t });
      }
      return [];
    }, 500),
    [],
  );
  const handleGetDataByEmail = useCallback(
    debounce(async (payload) => {
      if (!payload.value || payload.value.length <= 5) {
        resetForm();
        return [];
      }
      try {
        const { data } = await api.post('/acp/card/getUserDetailsByEmail', { email: payload.value });
        setSavedUserData(data);
        if (data.length) {
          onUserDataSelect(data[0]);
        } else {
          setEmailValue(payload.value);
        }
        return data[0] || payload.value;
      } catch (err) {
        handleApiError({ err, t, showNotification });
      }
      return [];
    }, 500),
    [],
  );
  useEffect(() => {
    const { email } = form;
    const emailInvalid = makeRequired(emailValidator)(email);
    setEmailError(emailInvalid);
    const errors = translatedFields.map((field) => Boolean(field.props.validator(form[field.key])));
    setReadOnlyBtn(errors.some((error) => Boolean(error)) || emailInvalid || !form.email);
  }, [form]);

  return (
    <>
      <div className={classes.wrapper}>
        <div className={classes.bodyWrapper}>
          <CreateCardModalHeading
            stepText={t('adminControlPanel.seoCardModalFirstStep')}
            title={t('adminControlPanel.seoCardModalFirstStepTitle')}
            titleSubInfo={t('adminControlPanel.seoCardModalFirstStepSubtitle')}
          />
          {isLoading ? (
            <div className={classes.loaderWrapper}>
              <AdminLoader />
            </div>
          ) : (
            <div className={classes.form}>
              <div className={classes.label}>
                {t('adminControlPanel.email')}
                *
                <AsyncSelect
                  withChevron
                  placeholder={t('adminControlPanel.searchOrAddUser')}
                  labelRised
                  admin
                  onListRead={handleGetEmailList}
                  onElementGet={handleGetDataByEmail}
                  showOnList={(v) => v?.email || v}
                  onSelect={onSelectEmailHander}
                  value={emailValue}
                  error={emailError}
                />
                {form.email && makeRequired(emailValidator)(form.email) && (
                  <span className={classes.error}>{t(emailError)}</span>
                )}
              </div>
              {Boolean(savedUserData.length) && (
                <div className={classes.label}>
                  {t('adminControlPanel.invoiceData')}
                  <Select
                    admin
                    list={savedUserData}
                    value={savedUserData[0]}
                    showOnList={autoCompleDataDisplayHandler}
                    onSelect={onUserDataSelect}
                  />
                </div>
              )}
              {translatedFields.map((field) => renderInputComponent({ field, form, onInputChange }))}
            </div>
          )}
        </div>
      </div>
      <Button
        className={classes.saveBtn}
        label={t('adminControlPanel.saveAndContinue')}
        onClick={() => onSave(1)}
        readOnly={readOnlyBtn}
      />
    </>
  );
};

export default CreateCardClientDataForm;
