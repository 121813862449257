import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import classes from './styles.module.css';
import Input from '../../../../../shared/components/Input';
import Button from '../../../../../shared/components/Button';
import Checkbox from '../../../../../shared/components/Checkbox';
import Loader from '../../../components/Loader';
import { isButtonDisabled, formInputEnum, handleApiError } from './utils';
import {
  getConfirmationPasswordErrorMessage,
  getErrorMessage,
  passwordErrorsConfig,
} from '../../../views/SetPassword/utils';
import SubInformation from '../../../../../shared/components/SubInformation';
import { useKeyDown } from '../../../../../shared/helpers/hooks';

const ChangePassword = () => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { showNotification } = useNotification();
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({ currentPassword: '', newPassword: '', repeatPassword: '' });
  const [showPassword, setShowPassword] = useState(false);
  const [passwordConfirmationBlurred, setPasswordConfirmationBlurred] = useState(false);

  const { currentPassword, newPassword, repeatPassword } = formInputEnum;

  const errConfirmationPasswordMessage = getConfirmationPasswordErrorMessage({
    password: formData.newPassword,
    passwordConfirmation: formData.repeatPassword,
    passwordConfirmationBlurred,
  });

  const errMessage = getErrorMessage({
    password: formData.newPassword,
    passwordConfirmation: formData.repeatPassword,
    passwordConfirmationBlurred,
  });

  const readOnlyBtn = isButtonDisabled(errMessage, errConfirmationPasswordMessage, formData, passwordErrorsConfig);

  const onChangeHandler = (name) => (value) => {
    setFormData({ ...formData, [name]: value });
  };

  const changePasswordHandler = async () => {
    const controller = new AbortController();
    try {
      const { currentPassword: oldPassword, newPassword: password } = formData;
      setIsLoading(true);
      await api.post('/auth/changeUserPassword', { newPassword: password, oldPassword }, { signal: controller.signal });
      showNotification({ type: 'success', message: t('settings.passwordChanged') });
      setFormData({ currentPassword: '', newPassword: '', repeatPassword: '' });
    } catch (err) {
      handleApiError({ err, t, showNotification });
    }
    setIsLoading(false);
  };

  useKeyDown({ key: 'Enter', callback: changePasswordHandler, canInvoke: !readOnlyBtn });

  const confirmationPasswordBlurHandler = (val) => {
    setPasswordConfirmationBlurred(val);
  };

  const passwordHepler = t(errMessage.message) === t(passwordErrorsConfig.passwordIsOk)
    ? t(errConfirmationPasswordMessage.message)
    : t(errMessage.message);

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <div className={classes.title}>{t('settings.changePassword')}</div>
      </div>
      {isLoading && (
        <div className={classes.loader}>
          <Loader />
        </div>
      )}
      <form className={classes.form}>
        <Input
          type="password"
          showErrorAfterBlur
          label={t('settings.currentPassword')}
          onChange={onChangeHandler(currentPassword)}
          value={formData.currentPassword}
        />
        <Input
          type={showPassword ? 'text' : 'password'}
          label={t('settings.newPasword')}
          onChange={onChangeHandler(newPassword)}
          showErrorAfterBlur
          value={formData.newPassword}
        />
        <Input
          onBlur={() => confirmationPasswordBlurHandler(true)}
          onFocus={() => confirmationPasswordBlurHandler(false)}
          type={showPassword ? 'text' : 'password'}
          label={t('settings.repeatPassword')}
          onChange={onChangeHandler(repeatPassword)}
          error={errMessage.errorPasswordConfirmation}
          showErrorAfterBlur
          value={formData.repeatPassword}
        />
        <SubInformation label={passwordHepler} className={classes.passwordHelper} />
        <Checkbox
          checkboxStyle={classes.checkboxStyle}
          onClick={() => setShowPassword((prev) => !prev)}
          labelPosition="right"
          className={classes.showPassword}
          label={t('setPassword.showPassword')}
        />
        <Button
          className={classes.saveBtn}
          label={t('global.save')}
          onClick={changePasswordHandler}
          readOnly={readOnlyBtn}
        />
      </form>
    </div>
  );
};
export default ChangePassword;
