import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import clsx from 'clsx';
import classes from './styles.module.css';
import { ReactComponent as NotVerfied } from '../../../../../shared/assets/notVerified.svg';
import { statusConfig, SUSPENDED_PROFILE_ACTION_URL } from '../../Dashboard/BusinessStatus/utils';
import { PROFILE_STATUS_ENUM } from '../../../helpers/enums';
import Button from '../../../../../shared/components/Button';
import { useDashboard } from '../../../helpers/dashboard';

const ProfileNotVerified = ({ status, adminPreview }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const {
    businessProfile: { placeReferenceId },
  } = useDashboard();
  const translatedStatus = t(`subscription.${status}`);
  const { duplicate, suspended, notVerified } = PROFILE_STATUS_ENUM;
  const { subInfo, Icon } = statusConfig(t, adminPreview)[status];
  const shouldRenderLinkForm = [duplicate, suspended].includes(status) && !adminPreview;
  const shouldRenderVerifyButton = status === notVerified && !adminPreview;
  const navigateVerify = () => {
    navigate('/dashboard/verification');
  };
  const linkForm = status === suspended ? SUSPENDED_PROFILE_ACTION_URL : `https://business.google.com/arc/p/${placeReferenceId}`;
  return (
    <div className={classes.wrapper}>
      <div>
        <div className={clsx(classes.iconWrapper, classes[status])}>
          <Icon className={classes.icon} />
        </div>
      </div>
      <div className={classes.statusInfo}>{`Twoja wizytówka jest ${translatedStatus}`}</div>
      <p className={classes.statusSubInfo}>{subInfo}</p>

      {shouldRenderLinkForm && (
        <a
          target="_blank"
          referrerPolicy="no-referrer"
          href={linkForm}
          className={classes.suspendedForm}
          rel="noreferrer"
        >
          {t('dashboard.suspendedForm')}
        </a>
      )}
      {shouldRenderVerifyButton && (
        <Button onClick={navigateVerify} label={t('businessProfileCreate.verify')} className={classes.verifyButton} />
      )}
    </div>
  );
};

export default ProfileNotVerified;
