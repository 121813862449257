import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import { useApi } from '../../../../../shared/helpers/api';
import { useNotification } from '../../../../../shared/helpers/notification';
import { useDashboard } from '../../../helpers/dashboard';
import Loader from '../../../components/Loader';
import Button from '../../../../../shared/components/Button';
import DisconnectAccountModal from '../DisconnectAccountModal';
import { useModal } from '../../../../../shared/helpers/hooks';
import { handleApiError } from '../ChangePassword/utils';
import { ReactComponent as GoogleIcon } from '../../../../../shared/assets/googleIconColor.svg';
import { useAuth } from '../../../../../shared/helpers/auth';

const MyAccount = () => {
  const { t } = useTranslation();
  const { api } = useApi();
  const { login } = useAuth();
  const {
    businessProfile: { id },
  } = useDashboard();
  const { showNotification } = useNotification();
  const [showModal, setShowModal, modalHandler] = useModal();
  const [isLoading, setIsLoading] = useState(false);
  const [userDetails, setUserDetails] = useState({ accountName: '', pictureUrl: '', email: login });
  const { accountName, email, pictureUrl } = userDetails;
  const userDetailsFromLocalStorage = JSON.parse(sessionStorage.getItem('userDetails'));

  useEffect(() => {
    const controller = new AbortController();

    const fetchUserDetails = async () => {
      setIsLoading(true);
      try {
        const { data } = await api.post('/auth/accountInfo', { businessProfileId: id }, { signal: controller.signal });
        sessionStorage.setItem('userDetails', JSON.stringify(data));
        setUserDetails(data);
      } catch (err) {
        handleApiError({ err, t, showNotification });
      } finally {
        setIsLoading(false);
      }
    };
    if (!userDetailsFromLocalStorage && id) {
      fetchUserDetails();
    } else if (userDetailsFromLocalStorage) {
      setUserDetails(userDetailsFromLocalStorage);
    }
    return () => controller.abort();
  }, []);

  if (isLoading) {
    return (
      <div className={classes.loaderWrapper}>
        <Loader />
      </div>
    );
  }
  return (
    <div className={classes.container}>
      {showModal && <DisconnectAccountModal onCancel={modalHandler} />}
      <div className={classes.header}>
        <div className={classes.title}>{t('settings.connectedAccount')}</div>
      </div>
      <div className={classes.body}>
        <div className={classes.accountDetails}>
          {Boolean(pictureUrl) && (
            <div>
              <img src={pictureUrl} className={classes.userPicture} alt="Business profile" />
              <GoogleIcon className={classes.googleIcon} />
            </div>
          )}
          <div className={classes.textWrapper}>
            <div className={classes.accountName}>{accountName || t('global.noData')}</div>
            <div className={classes.mail}>{email}</div>
          </div>
        </div>
        <Button onClick={modalHandler} className={classes.disconnectBtn} label={t('settings.disconnectAccount')} />
      </div>
    </div>
  );
};
export default MyAccount;
