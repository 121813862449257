import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Stars from '../../../../../shared/components/Stars';
import Checkbox from '../../../../../shared/components/Checkbox';

const ReviewerBox = ({ opinion, selectAllowed, onSelectReviewHandler = () => {}, selectedReviews = [] }) => {
  const { t } = useTranslation();

  const opinionWithNoReply = !opinion.nativeReply?.length;
  const shouldRenderCheckBox = selectAllowed && opinionWithNoReply;

  return (
    <div className={classes.reviewerInfo}>
      <div className={classes.row}>
        <img className={classes.avatar} src={opinion.reviewer.profilePhotoUrl} alt="avatar" />
        <div className={classes.displayNameWrapper}>
          <div className={classes.row}>
            {opinion.reviewer.displayName}
            {opinion.isNew && <div className={classes.newLabel}>{t('opinions.new')}</div>}
            {shouldRenderCheckBox && (
              <div className={classes.verifiedLabel}>
                <Checkbox
                  checkboxStyle={classes.checkboxStyle}
                  onClick={() => onSelectReviewHandler(opinion.id)}
                  value={selectedReviews.includes(opinion.id)}
                />
              </div>
            )}
          </div>
          <div className={classes.opinionDetails}>
            <Stars rating={opinion.starRatingAsNumber} />
            {t('opinions.added', { date: opinion.createDate })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewerBox;
