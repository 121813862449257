import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import BusinessProfileModalHeader from '../BusinessProfileModalHeader';
import BusinessProfileModalTabNavigation from '../BusinessProfileModalTabNavigation';
import { useApi } from '../../../../../shared/helpers/api';
import { parseProfileStatus } from '../../../../SEO/components/DashboardLayout/utils';
import { getTabComponent } from './utils';
import BusinessProfileModalError from './BusinessProfileModalError';

const BusinessProfileModal = ({ onClose, businessProfile, profileImage, address, userId, businessProfileId }) => {
  const { t } = useTranslation();
  const modalRef = useRef(null);
  const { title } = businessProfile;
  const [activeTab, setActiveTab] = useState('dashboard');
  const [businessVerificationStatus, setBusinessVerificationStatus] = useState({});
  const [isGoogleError, setIsGoogleError] = useState(false);
  const [reportParams, setReportParams] = useState({});
  const { api } = useApi();

  const handleClickReport = (report) => {
    setReportParams(report);
    setActiveTab('report');
  };

  const props = { businessProfileId, userId, reportParams, businessProfile, handleClickReport, profileImage };
  const ActiveComponent = getTabComponent(activeTab, props);

  const onTabChange = (tab) => {
    setActiveTab(tab);
  };

  const onBackdropClick = (e) => {
    if (!modalRef.current.contains(e.target)) {
      onClose(e);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const statusInStorage = localStorage.getItem(`status-${businessProfileId}`);
    const fetchBusinessProfileStatus = async () => {
      try {
        const { data } = await api.post('/acp/client/getVerificationStatus', { userId, businessProfileId });
        const parsedStatus = parseProfileStatus(data, t);
        setBusinessVerificationStatus(parsedStatus);
        localStorage.setItem(`status-${businessProfileId}`, parsedStatus.id);
      } catch (error) {
        if (error.response.data.code === 'GOOGLE_TOKEN_UNAUTHORIZED') {
          setIsGoogleError(true);
        }
      }
    };

    if (!statusInStorage) {
      fetchBusinessProfileStatus();
    }

    return () => {
      controller.abort();
    };
  }, [userId, businessProfileId]);

  return (
    <div onClick={isGoogleError ? null : onBackdropClick} className={classes.backdrop}>
      {isGoogleError ? <BusinessProfileModalError onClose={onClose} /> : (
        <div ref={modalRef} className={classes.modal}>
          <BusinessProfileModalHeader address={address} profileImage={profileImage} title={title} onClose={onClose} />
          <BusinessProfileModalTabNavigation onTabClick={onTabChange} activeTab={activeTab} />
          {ActiveComponent}
        </div>
      )}
    </div>
  );
};

export default BusinessProfileModal;
