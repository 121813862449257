import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import classes from './styles.module.css';
import { priceModelsConfig } from './utils';
import PriceModel from '../PriceModel';
import PlanComparison from '../PriceModelsFeatureMatrix';
import { generateKey } from '../../../../shared/helpers/helpers';
import { useWindowResize } from '../../../../shared/helpers/hooks';
import { MIN_TABLET_WIDTH } from '../../../SEO/helpers/constants';
import MobilePlanComparison from '../MobilePlanComparison';

const Pricing = ({ preview, purchaseMode, onPlanSelect }) => {
  const { t } = useTranslation();
  const innerWidth = useWindowResize();
  const isSmallMobile = innerWidth <= MIN_TABLET_WIDTH;

  const renderPriceModel = (model, idx) => (
    <PriceModel
      key={generateKey(model, idx)}
      model={model}
      purchaseMode={purchaseMode}
      preview={preview}
      onPlanSelect={onPlanSelect}
    />
  );

  return (
    <section id="pricing" className={clsx(classes.wrapper, preview && classes.previewWrapper)}>
      {!preview && (
        <div className={classes.heading}>
          <h2 className={classes.title}>{t('landingPage.pricingSection.title')}</h2>
          <p className={classes.subTitle}>{t('landingPage.pricingSection.description')}</p>
        </div>
      )}
      <div className={clsx(classes.priceModelsWrapper, preview && classes.previewWrapper)}>
        {priceModelsConfig(t, preview).map(renderPriceModel)}
      </div>
      <div className={clsx(classes.plansMatrix, preview && classes.plansPreviewMobile)}>{isSmallMobile ? <MobilePlanComparison preview={preview} /> : <PlanComparison />}</div>
    </section>
  );
};

export default Pricing;
