import React from 'react';
import { useTranslation } from 'react-i18next';
import classes from './styles.module.css';
import Pill from '../../../../../shared/components/Pill';
import { ReactComponent as CircleInfo } from '../../../../../shared/assets/circleInfo.svg';

const BuyerDataDetails = ({ buyerData }) => {
  const { t } = useTranslation();

  const { name, lastName, nip, companyName, country, postalCode, city, address, email, phone, defaultInvoice } = buyerData;
  const isDataProvided = Object.values(buyerData).some((value) => value);
  return (
    <>
      {defaultInvoice && <Pill label={t('invoices.defaultAddress')} className={classes.pill} />}
      <div className={classes.dataWrapper}>
        <div className={classes.personalData}>
          <div>{`${name || ''} ${lastName || ''}`}</div>
          {companyName && <div>{companyName}</div>}
          {nip && <div>{`${nip || ''}`}</div>}
        </div>
        <div className={classes.address}>
          <div>{`${country}`}</div>
          <div>{`${postalCode} ${city}`}</div>
          <div>{`${address}`}</div>
        </div>
        <div className={classes.contactData}>
          <div>{`${email}`}</div>
          <div>{`${phone}`}</div>
        </div>
        {isDataProvided && (
          <div className={classes.aboutInvoice}>
            <CircleInfo className={classes.infoIcon} />
            {!nip && !companyName ? (
              <p>{t('invoices.nameInvoiceWillBeSend')}</p>
            ) : (
              <p>{t('invoices.companyInvoiceWillBeSend')}</p>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default BuyerDataDetails;
